import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './Breadcrumbs.scss';

const MAX_LENGTH = 16;

const Breadcrumbs = ({ steps, relative, className }: any) => {
  return (
    <div className={`${styles.container} ${className}`} style={relative ? { margin: 0, width: '100%' } : {}}>
      <Link to="/" className={styles.home}><span className="icon-home white"><span className="path1" /><span className="path2" /></span></Link>
      <div className={styles['items-container']}>
        {
          [...steps].reverse().map((s, i) => {
            const TagName = s.url ? Link : 'div';
            return <TagName to={s.url} style={{ zIndex: 100 + i }} className={`hide-for-medium-only hide-for-small-only ${styles.item} ${i === 0 ? styles.first : ''} ${i === steps.length - 1 ? styles.last : ''}`} key={`breadcrumb-${i}`}>{s.title.length > MAX_LENGTH ? `${s.title.substring(0, MAX_LENGTH)}...` : s.title}</TagName>;
          })
        }
        {
          [...steps].filter((it) => { return !!it.url; }).length > 1
            ? <Link to={[...steps].filter((it) => { return !!it.url; }).reverse()[1].url} className={styles.back}><span className="icon-arrow-back white" /></Link>
            : null
        }
      </div>
    </div>
  );
};

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  steps: PropTypes.array,
  relative: PropTypes.bool
};

Breadcrumbs.defaultProps = {
  className: '',
  steps: [],
  relative: false
};

export default Breadcrumbs;
