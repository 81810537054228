import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

type Props = {}
type State = {}

class UnauthorizedPage extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    push: PropTypes.func
  }

  render() {
    const { lang } = this.context;

    return (
      <div className="vertical flex-1 center page">
        <h3>{lang('global', 'unauthorized_message').s}</h3>
        <div className="spacer" />
        <Link to="/" className="primary text-upcase">{lang('global', 'back-home').s}</Link>
      </div>
    );
  }
}

export default UnauthorizedPage;
