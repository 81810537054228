import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './Login.scss';

type Props = {
  onLogin: ({ email?: string, password?: string }) => {},
  authenticating?: boolean,
  error: ?string
}

type State = {
  email: string,
  password: string,
}

class Login extends Component<Props, State> {
  static defaultProps = {
    authenticating: false
  }

  static contextTypes = {
    lang: PropTypes.func
  }

  state = { email: '', password: '' }

  onChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onLogin = (event: SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (this.props.onLogin && this.state.email.length >= 3 && this.state.password.length >= 3) {
      this.props.onLogin({ email: this.state.email, password: this.state.password });
    }
    return false;
  }

  render() {
    const { lang } = this.context;
    const { authenticating, error } = this.props;
    const { email, password } = this.state;

    return (
      <div className={styles.container}>
        <img className={styles.logo} src={require('../../images/logo-login.png')} alt="logo" />
        <div className="grid-x" style={{ width: '100%', justifyContent: 'center' }}>
          <form className={`${styles.form} small-10 medium-6 large-4 cell`} onSubmit={this.onLogin}>
            <label htmlFor="email">
              <div>{lang('session', 'email').toString()}</div>
              <input type="text" name="email" autoCapitalize="off" className="small-12 cell" onChange={this.onChange} value={email} />
            </label>
            <label htmlFor="email">
              <div>{lang('session', 'password').toString()}</div>
              <input type="password" name="password" className="small-12 cell" onChange={this.onChange} value={password} />
            </label>
            <label htmlFor="sumbit">
              <input type="submit" className={styles.button} value={lang('session', 'login').toString()} disabled={authenticating} />
            </label>
            { error ? <div className={styles.error}>{error}</div> : null }
          </form>
        </div>
      </div>
    );
  }
}

export default Login;
