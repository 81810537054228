import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Permissions } from './state/authorization';

import requireAuth from './components/RequireAuth';
import HomePage from './containers/HomePage';
import CustomersPage from './containers/CustomersPage';
import WarehousesPage from './containers/WarehousesPage';
import LoginPage from './containers/LoginPage';
import NotFoundPage from './components/NotFoundPage';
import SectionsPage from './containers/SectionsPage';
import UsersPage from './containers/UsersPage';
import DoctorPage from './containers/DoctorPage';
import StatsPage from './containers/StatsPage';
import DownloadsPage from './containers/DownloadsPage';

export default (
  <Switch>
    <Route exact path="/" component={requireAuth(HomePage, { permissions: [Permissions.home.homeIndex] })} />

    <Route exact path="/customers" component={requireAuth(CustomersPage, { permissions: [Permissions.customers.customersIndex] })} />
    <Route exact path="/customers/new" component={requireAuth(CustomersPage, { permissions: [Permissions.customers.customersCreate] })} />
    <Route exact path="/customers/:customerId" component={requireAuth(CustomersPage, { permissions: [Permissions.customers.customersIndex] })} />
    <Route exact path="/customers/:customerId/edit" component={requireAuth(CustomersPage, { permissions: [Permissions.customers.customersCreate] })} />

    <Route exact path="/customers/:customerId/users" component={requireAuth(UsersPage, { permissions: [Permissions.users.usersIndex] })} />
    <Route exact path="/customers/:customerId/users/new" component={requireAuth(UsersPage, { permissions: [Permissions.users.usersIndex] })} />
    <Route exact path="/customers/:customerId/users/:userId" component={requireAuth(UsersPage, { permissions: [Permissions.users.usersDetail] })} />

    <Route exact path="/customers/:customerId/warehouses/new" component={requireAuth(WarehousesPage, { permissions: [Permissions.warehouses.warehousesCreate] })} />

    <Route exact path="/warehouses/:warehouseId" component={requireAuth(WarehousesPage, { permissions: [Permissions.home.homeIndex] })} />
    <Route exact path="/warehouses/:warehouseId/edit" component={requireAuth(WarehousesPage, { permissions: [Permissions.warehouses.warehousesCreate] })} />
    <Route exact path="/warehouses/:warehouseId/sections/new" component={requireAuth(WarehousesPage, { permissions: [Permissions.warehouses.warehousesCreate] })} />
    <Route exact path="/warehouses/:warehouseId/sections/:sectionId" component={requireAuth(SectionsPage, { permissions: [Permissions.home.homeIndex] })} />

    <Route exact path="/warehouses/:warehouseId/sections/:sectionId/bollards/new" component={requireAuth(SectionsPage, { permissions: [Permissions.products.productsCreate] })} />
    <Route exact path="/warehouses/:warehouseId/sections/:sectionId/bollards/:bollardId/edit" component={requireAuth(SectionsPage, { permissions: [Permissions.products.productsCreate] })} />

    <Route exact path="/doctor" component={requireAuth(DoctorPage, { permissions: [Permissions.superadmin.can_all] })} />
    <Route exact path="/stats" component={requireAuth(StatsPage, { permissions: [Permissions.superadmin.can_all] })} />
    <Route exact path="/downloads" component={requireAuth(DownloadsPage, { permissions: [Permissions.home.download] })} />

    <Route path="/login" component={LoginPage} />
    <Route component={NotFoundPage} />
  </Switch>
);
