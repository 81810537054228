import initialState from '../initial-state';
import types from '../action-types';

export default function appReducer(state: typeof initialState.app = initialState.app, action: any) {
  switch (action.type) {
    case types.UPDATE_BREADCRUMBS:
      return Object.assign({}, state, {
        breadcrumbs: action.breadcrumbs
      });
    case types.CLEAR_BREADCRUMBS:
      return Object.assign({}, state, {
        breadcrumbs: initialState.app.breadcrumbs
      });
    default:
      return state;
  }
}
