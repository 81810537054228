import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Permissions } from '../../state/authorization';
import Spinner from '../common/spinner/Spinner';

import styles from './Warehouses.scss';

type Props = {
  alertTypes: Array<string>,
  customerId: string,
  warehouses: any,
  refetchQueries: any
}

type State = {
  showNotificationsFor: ?string;
  values: any
}

const updateAlertSettings = gql`
  mutation updateNotifications($warehouseId: ID!, $settings: JSON!) {
    updateNotifications(warehouseId: $warehouseId, settings: $settings) {
      notification { id }
    }
  }
`;

class Warehouses extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    can: PropTypes.func
  }

  state = {
    showNotificationsFor: null,
    values: {}
  }

  onNotificationToggle = (w: any, e: any) => {
    e.stopPropagation();
    this.setState({ showNotificationsFor: w.id, values: w.notifications ? w.notifications.settings : {} });
  }

  onToggleSetting = (type: string, state: boolean) => {
    this.setState(prevState => ({ ...prevState, values: { ...prevState.values, [type]: state } }));
  }

  onNotificationSave = (action: Function) => {
    const settings = {};
    this.props.alertTypes.forEach((t) => {
      settings[t] = this.state.values[t] || false;
    });
    action({ variables: { warehouseId: this.state.showNotificationsFor, settings } });
  }

  onSaveDone = () => {
    this.setState({ showNotificationsFor: null, values: {} });
  }

  render() {
    const { lang, can } = this.context;
    const { showNotificationsFor, values } = this.state;
    const { customerId, alertTypes, refetchQueries } = this.props;
    const warehouses = this.props.warehouses.results;

    return (
      <div className="grid-x">
        {
          warehouses.map((w, index) => {
            if (showNotificationsFor && showNotificationsFor === w.id) {
              return (
                <div key={`notification-${w.id}`} className="small-12 large-3 cell" style={{ position: 'relative' }}>
                  <Mutation mutation={updateAlertSettings} onCompleted={this.onSaveDone} refetchQueries={refetchQueries}>
                    {(action, { loading }) => {
                      return (
                        <div className={styles.bell} onClick={this.onNotificationSave.bind(null, action)}>
                          { loading ? <Spinner size={10} /> : <i className="icon-check" /> }
                        </div>
                      );
                    }}
                  </Mutation>
                  <div className={`card ${styles.container} ${styles['notifications-container']} ${index === 0 ? styles.first : ''} ${index === warehouses.length - 1 ? styles.last : ''}`}>
                    <form className="grid-x">
                      {
                        alertTypes.map((t) => {
                          const isOn = values[t];
                          return (
                            <label htmlFor="lastName" className="cell small-12" key={t}>
                              <div className="checkbox-field" onClick={this.onToggleSetting.bind(null, t, !isOn)}><i className={`icon-checkbox-${isOn ? 'on' : 'off'}`} />{ lang('user', t).s }</div>
                            </label>
                          );
                        })
                      }
                    </form>
                  </div>
                </div>
              );
            }
            return (
              <div key={w.id} className="small-12 large-3 cell" style={{ position: 'relative' }}>
                <div className={styles.bell} onClick={this.onNotificationToggle.bind(null, w)}>
                  <span className="icon-notifications"><span className="path1" /><span className="path2" /></span>
                </div>
                <Link to={`/warehouses/${w.id}`} key={w.id} className={`card ${styles.container} ${index === 0 ? styles.first : ''} ${index === warehouses.length - 1 ? styles.last : ''}`}>
                  <div className="circled-icon circled-icon__outline circled-icon__warehouse"><span className="icon-business white"><span className="path1" /><span className="path2" /></span></div>
                  <div className={styles['name-containter']}>
                    <span className="table-title">{w.name}</span>
                    <span className="table-subtitle">{`${w.address}, ${w.city}`}</span>
                  </div>
                  <div className={`${styles.details} show-for-large`}>
                    <div style={{ flex: '3' }}>
                      <div style={{ display: 'flex' }}>
                        <span style={{ flex: '1' }} className="table-detail table-detail__collector single-row"><span className="icon-collector"><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /></span>{w.devicesCount}</span>
                        <span style={{ flex: '1' }} className="table-detail table-detail__gate single-row"><span className="icon-gate"><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /></span>{w.gatesCount}</span>
                        <span style={{ flex: '1' }} className="table-detail table-detail__forklift single-row"><span className="icon-forklift"><span className="path1" /><span className="path2" /><span className="path3" /></span>{w.forkliftsCount}</span>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <span style={{ flex: '1' }} className="table-detail table-detail__ok single-row"><span className="icon-bollard"><span className="path1" /><span className="path2" /></span>{w.sensorsCount}</span>
                        <span style={{ flex: '1' }} className="table-detail table-detail__in-maintenance single-row"><span className="icon-build"><span className="path1" /><span className="path2" /></span>{w.maintenanceSensorsCount}</span>
                        <span style={{ flex: '1' }} className="table-detail table-detail__damaged single-row"><span className="icon-error"><span className="path1" /><span className="path2" /></span>{w.damagedSensorsCount}</span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })
        }
        {can(Permissions.warehouses.warehousesCreate) ? (
          <div className="small-12 large-3 cell">
            <Link to={`/customers/${customerId}/warehouses/new`} className={`card ${styles.container} ${styles.dark}`}>
              <div className={styles['add-text-containter']}>
                <span className="icon-add white" style={{ marginBottom: '2rem', fontSize: '2rem' }} />
                <span className="table-title">{ lang('warehouses', 'create').toString() }</span>
              </div>
            </Link>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Warehouses;
