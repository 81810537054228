import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';

import styles from './Devices.scss';

type Props = {
  devices: any,
  states: any,
  onEdit: Function,
  onHoverItem: Function,
  onDeviceSelect: Function
}

class Devices extends Component<Props> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    can: PropTypes.func
  }

  render() {
    const { lang } = this.context;
    return (
      <div style={{ width: '100%' }}>
        {
          this.props.devices.map((device) => {
            let connected = false;
            if (this.props.states) {
              const meta = this.props.states.filter(d => d.id === device.id)[0];
              connected = meta ? meta.connected : false;
            }

            return (
              <div style={{ display: 'flex' }} key={device.id} onMouseEnter={this.props.onHoverItem.bind(null, device.id)} onMouseLeave={this.props.onHoverItem.bind(null, '')} onClick={this.props.onDeviceSelect.bind(null, device, true)}>
                <div style={{ flex: '1 1 100%' }} className="table-row table-row__clickable">
                  <div style={{ position: 'relative' }} className="circled-icon circled-icon__outline circled-icon__collector">
                    <span className="icon-collector" />
                    <div className={`${styles.blip} ${connected ? '' : styles['blip-offline']}`} />
                  </div>
                  <div className={styles['device-name-containter']}>
                    <div className="table-title table-title table-title__list">
                      {device.serial}
                      <span className="icon-edit" onClick={(e) => { this.props.onEdit(device); e.preventDefault(); e.stopPropagation(); }}><span className="path1" /><span className="path2" /></span>
                    </div>
                    <div className="table-subtitle">
                      {`${device.sensorsCount} ${device.sensorsCount === 1 ? lang('devices', 'bollard').s : lang('devices', 'bollard-count').s}`}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  }
}

export default Devices;
