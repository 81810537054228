import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';


import Spinner from '../components/common/spinner/Spinner';
import Users from '../components/users/Users';
import User from '../components/users/User';

import initialState from '../state/initial-state';
import queries from '../state/queries';

type Props = {
  session: typeof initialState.session,
  match: any
};
type State = {
  search: string;
};

class UsersPage extends Component<Props, State> {
  static contextTypes = {
    dispatch: PropTypes.func,
    push: PropTypes.func,
    can: PropTypes.func.isRequired
  }

  state = { search: '' }

  onEditComplete = () => {
    const { dispatch, push } = this.context;
    const { customerId } = this.props.match.params;
    dispatch(push(`/customers/${customerId}/users`));
  }

  render() {
    const { session, match } = this.props;
    const { userId, customerId } = match.params;
    const showList = match.path === '/customers/:customerId/users';

    return (
      <Query query={queries.users.metadata} variables={{ customerId, search: this.state.search }} fetchPolicy="network-only">
        {({ loading, error, data }) => {
          if (loading) return <Spinner absolute />;
          if (error) return `Error!: ${error.message}`;
          if (!data) return null;

          return showList
            ? (
              <Users customerId={customerId} />
            )
            : <User userId={userId} roles={data.roles} onCompleted={this.onEditComplete} session={session} customerId={data.customer.id} customerName={data.customer.name} />;
        }}
      </Query>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

// flowlint-line-ignore
export default connect(mapStateToProps, (dispatch: Dispatch) => ({ dispatch }))(UsersPage);
