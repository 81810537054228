import React, { Component } from 'react';
import { Query } from 'react-apollo';
import moment from 'moment';
import PropTypes from 'prop-types';

import Spinner from '../common/spinner/Spinner';
import queries from '../../state/queries';
import styles from './EventList.scss';

type Props = {
  locationId?: ?string
}

type State = {}

class EventList extends Component<Props, State> {
  static defaultProps = {
    locationId: null
  }

  static contextTypes = {
    lang: PropTypes.func
  }

  render() {
    const { lang } = this.context;
    return (
      <Query query={queries.sections.events} variables={{ locationId: this.props.locationId }} skip={!this.props.locationId} fetchPolicy="network-only">
        {({ loading, error, data }) => {
          if (loading && this.props.locationId) return <Spinner />;
          if (error || !data) return <div>No data available</div>;

          if (data.location.events.length === 0) {
            return <div className={`small-12 cell ${styles.event}`}>No Events</div>;
          }

          return data.location.events.map((e) => {
            return (
              <div className={`small-12 cell ${styles.event}`} key={e.id}>
                <div className={styles.container}>
                  <span className={styles.date}>{moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
                  <span className={styles.kind}>{lang('events', e.kind).s}</span>
                  { e.user ? <span className={styles.user}>{`${e.user.firstName} ${e.user.lastName}`}</span> : <span className={styles.user}>Superadmin</span> }
                </div>
                <div className={styles.bumps}>
                  <span className={styles.count}>{e.bumpCount}</span>
                  <figcaption>hits</figcaption>
                </div>
              </div>
            );
          });
        }}
      </Query>
    );
  }
}

export default EventList;
