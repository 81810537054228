import gql from 'graphql-tag';

export const customerFields = `
  id
  kind
  name
  address
  postalCode
  city
  town
  country
`;

export const warehouseFields = `
  id
  name
  address
  postalCode
  city
  town
  country
  file
  width
  height
  settings
  forkliftsCount
  devicesCount
  gatesCount
  bollardsCount
  damagedSensorsCount
  maintenanceSensorsCount
  sensorsCount
  customer {
    id
    name
  }
`;

export const sectionFields = `
  id
  name
  file
  width
  height
  x
  y
  scaleStartX
  scaleStartY
  scaleEndX
  scaleEndY
  scale
  gatesCount
  normalSensorsCount
  damagedSensorsCount
  maintenanceSensorsCount
  sensors {
    id
  }
  gates {
    id
  }
  gates {
    id
  }
  ames {
    id
  }
`;

export const deviceFields = `
  id
  serial
  x
  y
  hostname
  uid
  sensorsCount
  warehouse {
    id
  }
`;

export const forkliftFields = `
  id
  serial
  moteUid
  name
  x
  y
  warehouse {
    id
  }
`;

export const locationFields = `
  id
  x
  y
  totalBumps
`;

export const bollardFields = `
  id
  height
  diameter
`;

export const gateFields = `
  id
  length
`;

export const ameFields = `
  id
  areaRadius
`;

export const sensorFields = `
  id
  serial
  tilt
  battery
  moteUid
  txInterval
  temperature
  firmwareVersion
  bumpCount
  paused
  dataUpdatedAt
  device {
    id
    serial
  }
`;

export const userFields = `
  id
  email
  firstName
  lastName
  role
  clients {
    id
    name
  }
  customer {
    id
    name
  }
  warehouses { id }
`;

export const locationData = `
  ${locationFields}
  bollard {
    ${bollardFields}
  }
  sensor {
    ${sensorFields}
  }
  gate {
    ${gateFields}
  }
  ame {
    ${ameFields}
  }
`;

export default {

  // Meta

  stats: {
    index: gql`
      query stats {
        stats {
          customersCount
          warehousesCount
          bollardsCount
          sensorsCount
          connectedSensorsCount
          idleSensorsCount
          devicesCount
        }
      }
    `
  },

  firmwares: {
    index: gql`
      query firmwares {
        firmwares: firmwares(order: createdAt_DESC) {
          id,
          version
          notes
          target
          createdAt
          file
        }
      }
    `
  },

  // Customers

  customers: {
    list: gql`
      query customers($search: String) {
        customers(search: $search) {
          results {
            id
            name
            address
            city
          }
        }
      }
    `,
    listWithWarehouseData: gql`
      query customers($page: Int!, $order: CustomersOrder!) {
        customers(page: $page, order: $order) {
          results {
            ${customerFields}
            warehousesCount
            sensorsCount
            gatesCount
            forkliftsCount
            bollardsCount
            damagedSensorsCount
            maintenanceSensorsCount
          }
          totalPages
        }
      }
    `,
    detail: gql`
      query customer($customerId: String!) {
        customer(id: $customerId) {
          ${customerFields}
        }
      }
    `,
    detailWithWarehouses: gql`
      query customer($page: Int!, $order: WarehousesOrder!, $customerId: String!) {
        alertTypes
        customer(id: $customerId) {
          ${customerFields}
          sensorsCount
          gatesCount
          forkliftsCount
          bollardsCount
          damagedSensorsCount
          maintenanceSensorsCount
        }
        warehouses(page: $page, order: $order, customerId: $customerId) {
          results {
            ${warehouseFields}
            notifications {
              id
              settings
            }
            sensorsCount
            gatesCount
            forkliftsCount
            bollardsCount
            damagedSensorsCount
            maintenanceSensorsCount
            customer {
              id
            }
          }
        }
      }
    `
  },

  // Users

  users: {
    list: gql`
      query users($page: Int!, $order: UsersOrder!, $customerId: String!) {
        customer(id: $customerId) {
          id
          name
        }
        users(page: $page, order: $order, customerId: $customerId) {
          ${userFields}
        }
      }
    `,
    detail: gql`
      query user($id: String!, $customerId: String!) {
        customer(id: $customerId) {
          id
          name
        }
        user(id: $id) {
          ${userFields}
        }
      }
    `,
    metadata: gql`
      query metadata($customerId: String!) {
        roles,
        customer(id: $customerId) {
          id
          name
        }
      }
    `
  },

  // Sensors

  sensors: {
    moteSearch: gql`
    query moteSearch($mote: String!) {
      sensors(moteUid: $mote) {
        id
        serial,
        customer {
          id
          name
        }
        warehouse {
          id
          name
        }
        section {
          id
          name
        }
      }
    }
  `
  },

  // Warehouse

  warehouses: {
    list: gql`
      query warehouses($page: Int!, $order: WarehousesOrder!, $customerId: String!) {
        warehouses(page: $page, order: $order, customerId: $customerId) {
          results {
            ${warehouseFields}
            customer {
              id
            }
          }
        }
      }
    `,
    listFromCustomers: gql`
      query warehouses($order: WarehousesOrder!, $customerIds: [String!]!) {
        warehouses(order: $order, customerIds: $customerIds) {
          results {
            ${warehouseFields}
            customer {
              id
            }
          }
        }
      }
    `,
    detail: gql`
      query warehouse($warehouseId: String!) {
        productSettings { code kind settings }
        warehouse(id: $warehouseId) {
          customer {
            ${customerFields}
          }
          ${warehouseFields}
          sections {
            ${sectionFields}
            warehouse {
              id
              customer {
                id
              }
            }
          }
          devices {
            ${deviceFields}
            warehouse {
              id
              customer {
                id
              }
            }
          }
          forklifts {
            ${forkliftFields}
            warehouse {
              id
              customer {
                id
              }
            }
          }
        }
      }
    `
  },

  devices: {
    configs: gql`
      query deviceConfigs($id: String!) {
        deviceConfigs(id: $id)
      }
    `,
    configArchive: gql`
    query deviceConfigArchive($id: String!) {
      deviceConfigArchive(id: $id)
    }
  `,
    deviceConnectionStates: gql`
      query($ids: [String!]!) {
        deviceConnectionStates(ids: $ids) {
          id, connected
        }
      }
    `
  },

  sections: {
    list: gql`
      query warehouseSection($page: Int!, $order: WarehousesOrder!, $warehouseId: String!) {
        warehouseSection(page: $page, order: $order, warehouseId: warehouseId) {
          results {
            ${sectionFields}
            warehouse {
              id
              customer {
                id
              }
            }
          }
        }
      }
    `,
    detail: gql`
      query warehouseSection($sectionId: String!, $warehouseId: String!) {
        warehouseSection(id: $sectionId) {
          ${sectionFields}
          locations {
            ${locationData}
          }
        }
        warehouse(id: $warehouseId) {
          id
          name
          settings
          devices {
            id
            serial
          }
          forklifts {
            id
            serial
            name
          }
          customer {
            id
            name
          }
        }
      }
    `,
    events: gql`
      query location($locationId: String!) {
        location(id: $locationId) {
          id
          events {
            id
            createdAt
            bumpCount
            user {
              id
              firstName
              lastName
            }
          }
        }
      }
    `
  }
};
