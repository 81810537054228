import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';

import Warehouse from '../components/warehouses/Warehouse';
import { updateBreadcrumbs } from '../state/app/actions';

import queries from '../state/queries';
import { Permissions } from '../state/authorization';

type Props = {
  match: any
};
type State = {};

class DevicesPage extends Component<Props, State> {
  static contextTypes = {
    dispatch: PropTypes.func,
    push: PropTypes.func,
    lang: PropTypes.func,
    can: PropTypes.func
  }

  onEditComplete = (path: any) => {
    const { dispatch, push } = this.context;

    dispatch(push(path));
  }

  onQueryComplete = (data: any) => {
    const { dispatch, lang, can } = this.context;
    const { match } = this.props;

    const { warehouseId, customerId } = match.params;
    const editing = match.path === '/customers/:customerId/warehouses/:warehouseId/edit';

    if (data) {
      if (!data.warehouse) {
        // New warehouse
        dispatch(updateBreadcrumbs([{ title: data.customer.name, url: `/customers/${customerId}` }, { title: lang('warehouses', 'create').s, url: `/customers/${customerId}/warehouses/new` }]));
      } else if (data.warehouse && !editing) {
        // View warehouse
        dispatch(updateBreadcrumbs([{ title: data.warehouse.customer.name, url: can(Permissions.warehouses.warehousesCreate) ? `/customers/${data.warehouse.customer.id}` : '/' }, { title: data.warehouse.name, url: `/warehouses/${warehouseId}` }]));
      } else if (data.warehouse) {
        // Edit warehouse
        dispatch(updateBreadcrumbs([{ title: data.warehouse.name, url: `/warehouses/${warehouseId}` }, { title: lang('warehouses', 'edit').s, url: `/warehouses/${warehouseId}/edit` }]));
      }
    }
  }

  render() {
    const { match } = this.props;

    const { warehouseId, customerId } = match.params;
    const editing = match.path === '/warehouses/:warehouseId/edit';

    const query = warehouseId ? queries.warehouses.detail : queries.customers.detail;

    return (
      <Query query={query} variables={warehouseId ? { warehouseId } : { customerId }} fetchPolicy="network-only" onCompleted={this.onQueryComplete}>
        {result => (
          // flowlint-line-ignore
          <Warehouse
            warehouseId={warehouseId}
            customerId={customerId}
            result={result}
            editing={editing}
            onCompleted={this.onEditComplete}
          />
        )}
      </Query>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

// flowlint-line-ignore
export default connect(mapStateToProps, (dispatch: Dispatch) => ({ dispatch }))(DevicesPage);
