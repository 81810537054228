import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Permissions } from '../../state/authorization';

import styles from './Sections.scss';

type Props = {
  sections: any,
  onEdit: Function,
  onHoverItem: Function
}

class Sections extends Component<Props> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    can: PropTypes.func
  }

  render() {
    const { lang, can } = this.context;

    return (
      <div style={{ width: '100%' }}>
        {
          this.props.sections.map((s) => {
            return (
              <div style={{ display: 'flex' }} key={s.id} onMouseEnter={this.props.onHoverItem.bind(null, s.id)} onMouseLeave={this.props.onHoverItem.bind(null, '')}>
                <Link to={`/warehouses/${s.warehouse.id}/sections/${s.id}`} style={{ flex: '1 1 100%' }} className="table-row">
                  <div className={styles['section-name-containter']}>
                    <div className="table-title table-title table-title__list">
                      {s.name}
                      {can(Permissions.warehouses.warehousesCreate) ? <span className="icon-edit" onClick={(e) => { this.props.onEdit(s); e.preventDefault(); }}><span className="path1" /><span className="path2" /></span> : null}
                    </div>
                    <div className="table-subtitle">
                      {`${s.sensors.length} ${s.sensors.length === 1 ? lang('sections', 'bollard').s : lang('sections', 'bollard-count').s}`}
                    </div>
                  </div>
                  <div className={styles['status-container']}>
                    <div className="table_header__ok"><span className="icon-bollard" style={{ marginRight: '.5rem' }}><span className="path1" /><span className="path2" /></span>{ s.normalSensorsCount || 0 }</div>
                    <div className="table_header__in-maintenance"><span className="icon-build" style={{ marginRight: '.5rem' }}><span className="path1" /><span className="path2" /></span>{ s.maintenanceSensorsCount || 0 }</div>
                    <div className="table_header__damaged"><span className="icon-error" style={{ marginRight: '.5rem' }}><span className="path1" /><span className="path2" /></span>{ s.damagedSensorsCount || 0 }</div>
                  </div>
                </Link>
              </div>
            );
          })
        }
      </div>
    );
  }
}

export default Sections;
