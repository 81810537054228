import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import prettyBytes from 'pretty-bytes';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';
import ReactFlagsSelect from 'react-flags-select';
import omitDeep from 'omit-deep-lodash';
import 'react-flags-select/css/react-flags-select.css';
import Spinner from '../common/spinner/Spinner';
import MutationError from '../common/errors/MutationError';

import { warehouseFields } from '../../state/queries';

import styles from './Warehouse.scss';

type Props = {
  warehouse?: any,
  customerId: string,
  onCompleted: Function,
  onDeleted: Function
}

type State = {
  values: any,
  errors: any
}

const createWarehouse = gql`
  mutation createWarehouse($name: String!, $address: String, $postalCode: String, $city: String, $town: String, $country: String, $customer: CustomerRelationAttributes!, $file: Upload!) {
    createWarehouse(attributes: { name: $name, address: $address, postalCode: $postalCode, city: $city, town: $town, country: $country, customer: $customer, file: $file }) {
      warehouse {
        ${warehouseFields}
      }
    }
  }
`;

const updateWarehouse = gql`
  mutation updateWarehouse($id: ID!, $name: String!, $address: String, $postalCode: String, $city: String, $town: String, $country: String, $customer: CustomerRelationAttributes!, $file: Upload) {
    updateWarehouse(id: $id, attributes: { name: $name, address: $address, postalCode: $postalCode, city: $city, town: $town, country: $country, customer: $customer, file: $file }) {
      warehouse {
        ${warehouseFields}
      }
    }
  }
`;

const warehouseDeleteMutation = gql`
  mutation deleteWarehouse($id: ID!) {
    deleteWarehouse(id: $id) { id }
  }
`;

class WarehouseForm extends Component<Props, State> {
  static defaultProps = {
    warehouse: null
  }

  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    push: PropTypes.func
  }

  state = {
    values: { file: undefined, name: '', country: 'IT' },
    errors: {}
  }

  componentWillMount() {
    if (this.props.warehouse) {
      this.setState({ values: { ...this.props.warehouse, customer: { id: this.props.warehouse.customer.id } } });
    } else {
      this.setState(prevState => ({ values: { ...prevState.values, customer: { id: this.props.customerId } } }));
    }
  }

  onTextChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState(prevState => ({ values: { ...prevState.values, [field]: value } }));
  }

  onCountryChange = (field: string, country: string) => {
    this.setState(prevState => ({ values: { ...prevState.values, [field]: country } }));
  }

  onClick = (action: Function, e: SyntheticMouseEvent<*> | SyntheticTouchEvent<*>) => {
    e.preventDefault();

    const { file } = this.state.values;
    const variables = omitDeep({ ...this.state.values }, '__typename');

    const errors = this.validate(variables);
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    if (typeof file !== 'string') {
      variables.file = file; // NOTE: the omit deep changes the `file` type, so we save it and restore it here
    } else {
      delete variables.file;
    }

    action({ variables });
  }

  onWarehouseDelete = (action: Function) => {
    confirmAlert({
      title: this.context.lang('warehouse', 'delete-confirm-title').toString(),
      message: this.context.lang('warehouse', 'delete-confirm-lite').toString(),
      buttons: [
        {
          label: this.context.lang('global', 'yes').toString(),
          onClick: () => {
            confirmAlert({
              title: this.context.lang('warehouse', 'delete-confirm-title').toString(),
              message: this.context.lang('warehouse', 'delete-confirm').toString(),
              buttons: [
                {
                  label: this.context.lang('global', 'yes').toString(),
                  onClick: () => action({ variables: { id: (this.props.warehouse || {}).id } })
                },
                { label: this.context.lang('global', 'no').toString() }
              ]
            });
          }
        },
        { label: this.context.lang('global', 'no').toString() }
      ]
    });
  }

  onDrop = (acceptedFiles: Array<any>) => {
    this.setState(prevState => ({ values: { ...prevState.values, file: acceptedFiles[0] } }));
  }

  onSelect = (field: string, option: { label: string, value: string }) => {
    this.setState(prevState => ({ values: { ...prevState.values, [field]: option.value } }));
  }

  clearFile = () => {
    this.setState(prevState => ({ values: { ...prevState.values, file: undefined } }));
  }

  validate = (values: any) => {
    const errors = {};
    ['name', 'address', 'postalCode', 'city', 'town', 'country'].forEach((f) => {
      if (!values[f] || values[f].trim().length === 0) {
        errors[f] = 'empty';
      }
    });
    return errors;
  }

  render() {
    const { values, errors } = this.state;
    const { lang } = this.context;

    return (
      <div className="table-dark grid-x centered-form">
        <div className="small-12 medium-8 medium-offset-2 large-6 large-offset-3 xlarge-4 xlarge-offset-4 cell">
          <form className="grid-x">
            <div className="form-title cell small-12">
              { lang('warehouses', this.props.warehouse ? 'edit' : 'create').s }
            </div>
            <label htmlFor="name" className="cell small-12 medium-6">
              <div>{ lang('warehouses', 'name').s }</div>
              <input className={errors.name ? 'error' : ''} type="text" name="name" value={values.name || ''} onChange={this.onTextChange.bind(null, 'name')} />
            </label>
            <label htmlFor="address" className="cell small-12 medium-6">
              <div>{ lang('warehouses', 'address').s }</div>
              <input className={errors.address ? 'error' : ''} type="text" name="address" value={values.address || ''} onChange={this.onTextChange.bind(null, 'address')} />
            </label>
            <label htmlFor="postalCode" className="cell small-12 medium-6">
              <div>{ lang('warehouses', 'postalCode').s }</div>
              <input className={errors.postalCode ? 'error' : ''} type="text" name="postalCode" value={values.postalCode || ''} onChange={this.onTextChange.bind(null, 'postalCode')} />
            </label>
            <label htmlFor="city" className="cell small-12 medium-6">
              <div>{ lang('warehouses', 'city').s }</div>
              <input className={errors.city ? 'error' : ''} type="text" name="city" value={values.city || ''} onChange={this.onTextChange.bind(null, 'city')} />
            </label>
            <label htmlFor="town" className="cell small-12 medium-6">
              <div>{ lang('warehouses', 'town').s }</div>
              <input className={errors.town ? 'error' : ''} type="text" name="town" value={values.town || ''} onChange={this.onTextChange.bind(null, 'town')} />
            </label>
            <label htmlFor="country" className="cell small-12 medium-6">
              <div>{ lang('warehouses', 'country').s }</div>
              <ReactFlagsSelect searchable placeholder="Select Country" defaultCountry={values.country} onSelect={this.onCountryChange.bind(null, 'country')} />
            </label>
            <label htmlFor="file" className="cell small-12">
              <div>{ lang('warehouses', 'file').s }</div>
              <Dropzone onDrop={this.onDrop} accept="image/jpeg, image/png" disabled={values.file !== undefined} multiple={false} activeClassName="active-dropzone" className="dropzone">
                {
                  values.file ? (
                    <div className={styles['thumbs-container']}>
                      <img src={values.file.preview || values.file} alt={values.file.name || values.file.split('/').reverse()[0]} className={styles.thumbs} />
                      <div className={styles['file-name-container']}>
                        <span className={styles['file-name']}>{values.file.name || values.file.split('/').reverse()[0]}</span>
                        { values.file.size ? <span className={styles['file-size']}>{prettyBytes(values.file.size)}</span> : null }
                        <span className={styles['remove-button']} onClick={this.clearFile}><span className="icon-close" />{lang('global', 'change').toString()}</span>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.message}>
                      <span className="icon-map white"><span className="path1" /><span className="path2" /></span>
                      { lang('warehouses', 'add-file').toString() }
                    </div>
                  )
                }
              </Dropzone>
            </label>
            <Mutation mutation={this.props.warehouse ? updateWarehouse : createWarehouse} onCompleted={this.props.onCompleted}>
              {(action, { loading, error }) => {
                return (
                  <div className="grid-x small-12 cell centered" style={{ marginTop: '2rem' }}>
                    {loading ? <Spinner /> : <input className="outline-button" type="submit" onClick={this.onClick.bind(null, action)} value={lang('global', values.id ? 'update' : 'create').toString()} />}
                    <MutationError graphQLError={error} errors={this.state.errors} lang={lang} langKey="warehouse" dark />
                  </div>
                );
              }}
            </Mutation>
            { this.props.warehouse
              ? (
                <div className="grid-x small-12 cell centered" style={{ marginTop: '.5rem' }}>
                  <Mutation mutation={warehouseDeleteMutation} onCompleted={this.props.onDeleted}>
                    {(deleteAction, result) => {
                      return result.loading ? <Spinner /> : <div className="small-12 cell" style={{ display: 'flex', justifyContent: 'center' }}><div className="action-button__subtle" onClick={this.onWarehouseDelete.bind(null, deleteAction)}>{lang('warehouses', 'delete').s}</div></div>;
                    }}
                  </Mutation>
                </div>
              ) : null
            }
          </form>
        </div>
        <div className="icon-button__form show-for-large" onClick={this.props.onCompleted}>
          <span className="icon-close white" />
        </div>
      </div>
    );
  }
}

export default WarehouseForm;
