import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import initialState from '../state/initial-state';

import UnauthorizedPage from './UnauthorizedPage';

type Props = {
  session: typeof initialState.session,
  app: typeof initialState.app,
}

type State = {
  authorized: boolean,
}

type Params = {
  permissions: string | Array<string>;
}

export default function (ComposedComponent: any, params : ?Params) {
  class Authentication extends Component<Props, State> {
    static contextTypes = {
      dispatch: PropTypes.func,
      push: PropTypes.func,
      lang: PropTypes.func.isRequired,
      actions: PropTypes.object.isRequired,
      can: PropTypes.func.isRequired,
    }

    constructor(props: any) {
      super(props);
      this.state = { authorized: true };
    }

    componentWillMount() {
      const { can } = this.context;
      const { session } = this.props;
      const { permissions } = params || {};
      if (!session.authenticated) {
        // do nothing
      } else if (!can(permissions)) {
        this.setState({ authorized: false });
      }
    }

    render() {
      const { session } = this.props;
      const { authorized } = this.state;

      if (!session.authenticated) {
        return <Redirect to={{ pathname: '/login' }} />;
      }
      if (session.authenticated && authorized) {
        return [<ComposedComponent key="component" {...params} {...this.props} />];
      }
      if (session.authenticated && !authorized) {
        return <UnauthorizedPage />;
      }

      return null;
    }
  }

  function mapStateToProps(state) {
    return { session: state.session };
  }

  // flowlint-line-ignore
  return connect(mapStateToProps, (dispatch: Dispatch) => ({ dispatch }))(Authentication);
}
