import React from 'react';
import PropTypes from 'prop-types';
import styles from './Spinner.scss';

const Spinner = ({ darkBackground, size, absolute, fancy }: any) => {
  let view = (
    <div className={`${styles.spinner} ${darkBackground ? styles.white : ''} ${size <= 24 ? styles.small : ''}`} style={{ width: `${size}px`, height: `${size}px` }} />
  );

  if (absolute) {
    view = (
      <div
        className={`${styles.spinner} ${darkBackground ? styles.white : ''} ${size <= 24 ? styles.small : ''} ${styles['spinner--absolute']}`}
        style={{
          width: `${size}px`, height: `${size}px`, marginLeft: `-${size / 2}px`, marginTop: `-${size / 2}px`
        }}
      />
    );
  }

  if (fancy) {
    view = (
      <div
        className={`${styles['lds-ring']} ${darkBackground ? styles.white : ''} ${size <= 24 ? styles.small : ''} ${absolute ? styles.absolute : ''}`}
        style={{
          width: `${size}px`, height: `${size}px`, marginLeft: `-${absolute ? size / 2 : 0}px`, marginTop: `-${absolute ? size / 2 : 0}px`
        }}
      >
        <div style={{ width: `${size}px`, height: `${size}px` }} />
        <div style={{ width: `${size}px`, height: `${size}px` }} />
        <div style={{ width: `${size}px`, height: `${size}px` }} />
        <div style={{ width: `${size}px`, height: `${size}px` }} />
      </div>
    );
  }

  return view;
};

Spinner.propTypes = {
  darkBackground: PropTypes.bool,
  size: PropTypes.number,
  absolute: PropTypes.bool,
  fancy: PropTypes.bool
};

Spinner.defaultProps = {
  darkBackground: false,
  size: 32,
  absolute: false,
  fancy: false
};

export default Spinner;
