import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { apiMiddleware } from 'redux-api-middleware';
import SecureLS from 'secure-ls';

import authMiddleware from '../middlewares/auth';
import rootReducer from './reducers';

export default function configureStore(initialState: any, routerMiddleware: any, history: any) {
  const middewares = [
    authMiddleware,
    apiMiddleware,
    routerMiddleware,
    thunkMiddleware,
  ];

  let state = Object.assign({}, initialState);
  const ls = new SecureLS({ encodingType: 'aes' });
  const storage = ls.get('session') || '{}';
  state = Object.assign(state, {}, { session: JSON.parse(storage) });

  return createStore(rootReducer(history), state, compose(applyMiddleware(...middewares)));
}
