import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';

import styles from './SensorDetail.scss';
import Spinner from '../common/spinner/Spinner';
import MutationError from '../common/errors/MutationError';
import { Permissions } from '../../state/authorization';
import EventList from './EventList';

const pauseSensor = gql`
  mutation pauseSensor($id: ID!) {
    pauseSensor(id: $id) { sensor { id, paused} }
  }
`;

const unpauseSensor = gql`
  mutation unpauseSensor($id: ID!) {
    unpauseSensor(id: $id) { sensor { id, paused } }
  }
`;

const startProvisioning = gql`
  mutation startSensorProvisioning($id: ID!) {
    startSensorProvisioning(id: $id) { sensor { id } }
  }
`;

const resetProvisioning = gql`
  mutation resetSensorProvisioning($id: ID!) {
    resetSensorProvisioning(id: $id) { sensor { id } }
  }
`;

const setSensorOffset = gql`
  mutation setSensorOffset($id: ID!) {
    setSensorOffset(id: $id) { sensor { id } }
  }
`;

const resetSensorBumpCount = gql`
  mutation resetSensorBumpCount($id: ID!) {
    resetSensorBumpCount(id: $id) { sensor { id } }
  }
`;

const replaceSensor = gql`
  mutation replaceSensor($id: ID!) {
    replaceSensor(id: $id) {
      location {
        id
        bollard { id }
        sensor {
          id
          device { id }
        }
      }
    }
  }
`;

const replaceBollard = gql`
  mutation replaceBollard($id: ID!) {
    replaceBollard(id: $id) {
      location {
        id
        bollard { id }
        sensor {
          id
          device { id }
        }
      }
    }
  }
`;

type Props = {
  location: any,
  onEdit: Function,
  onClose: Function,
  editing: bool,
  refetch: Function,
  onProvisioningComplete: Function
}

type State = {
  provisioningStatus: ?string,
  showSettings: boolean,
  showEvents: boolean
}

class SensorDetail extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    replace: PropTypes.func,
    can: PropTypes.func,
    stompClient: PropTypes.object
  }

  state = {
    provisioningStatus: null,
    showSettings: false,
    showEvents: false
  }

  streams = []

  resetErrorInterval = null

  componentDidUpdate(prevProps: any) {
    this.setupConnection(prevProps);
  }

  componentWillUnmount() {
    this.streams.forEach(s => this.context.stompClient.removeStompStreamListener(s));
    this.streams = [];
  }

  setupConnection = (prevProps: any) => {
    const { can } = this.context;

    this.context.stompClient.connect().then(() => {
      if (this.props.location.id !== prevProps.location.id) {
        this.setState({ provisioningStatus: null, showSettings: false }); // eslint-disable-line

        this.streams.forEach(s => this.context.stompClient.removeStompStreamListener(s));
        this.streams = [];

        const callback = (res) => {
          if (window.VERBOSE_LIVE_MSG || can(Permissions.superadmin.can_all)) {
            console.log('provisioning', res);  // eslint-disable-line
          }

          clearInterval(this.resetErrorInterval);

          if (res.id === this.props.location.sensor.id) {
            this.setState({ provisioningStatus: res.action });
            if (res.status === 'nok') {
              this.setState({ provisioningStatus: 'Provisioning Failed' });
              this.resetErrorInterval = setInterval(() => {
                this.setState({ provisioningStatus: null });
              }, 3000);
            }
            if (res.action === 'provisioning' && res.status === 'ok') {
              const { location } = this.props;
              const { sensor } = location;

              this.setState({ provisioningStatus: null });
              this.props.onProvisioningComplete(sensor.id, res.gatewayId, res.moteUid);
            }
          }
        };

        if (this.props.location.sensor.id) {
          this.context.stompClient.streamFrom([{ stream: 'feedback', topic: this.props.location.sensor.id, cache: false, callback }]).then((streams) => {
            this.streams = streams;
          }).catch(() => {
            this.setState({ provisioningStatus: 'Error' });
          });
        }
      }
    });
  }

  onCompleted = () => {
    this.setState({ provisioningStatus: 'In progress...' });
  }

  onStartProvisioning = (action: Function) => {
    action({ variables: { id: this.props.location.sensor.id } });
  }

  onResetProvisioning = (action: Function) => {
    confirmAlert({
      title: this.context.lang('sensors', 'reset-confirm-title').toString(),
      message: this.context.lang('sensors', 'reset-confirm').toString(),
      buttons: [
        {
          label: this.context.lang('global', 'yes').toString(),
          onClick: () => {
            action({ variables: { id: this.props.location.sensor.id } });
          }
        },
        { label: this.context.lang('global', 'no').toString() }
      ]
    });
  }

  onResetCompleted = () => {
    this.props.refetch();
    this.setState({ showSettings: false });
  }

  onPauseSensor = (action: Function) => {
    action({ variables: { id: this.props.location.sensor.id } });
  }

  onSetOffset = (action: Function) => {
    action({ variables: { id: this.props.location.sensor.id } });
  }

  onResetCount = (action: Function) => {
    action({ variables: { id: this.props.location.sensor.id } });
  }

  onReplaceSensor = (action: Function) => {
    confirmAlert({
      title: 'Replace the sensor board?',
      message: 'The previous board will be deleted, you will need to provision the new one once this operation is complete, continue?',
      buttons: [
        {
          label: this.context.lang('global', 'yes').toString(),
          onClick: () => {
            action({ variables: { id: this.props.location.id } });
          }
        },
        { label: this.context.lang('global', 'no').toString() }
      ]
    });
  }

  toggleSettings = () => {
    this.setState(prevState => ({ showSettings: !prevState.showSettings }));
  }

  onReplaceBollard = (action: Function) => {
    confirmAlert({
      title: 'Replace the whole bollard?',
      message: 'The previous bollard and its board will be deleted, continue?',
      buttons: [
        {
          label: this.context.lang('global', 'yes').toString(),
          onClick: () => {
            action({ variables: { id: this.props.location.id } });
          }
        },
        { label: this.context.lang('global', 'no').toString() }
      ]
    });
  }

  render() {
    const { lang, can } = this.context;
    const { provisioningStatus } = this.state;
    const { editing, location, onEdit, onClose } = this.props;
    const { sensor, bollard, gate, ame } = location;

    const isSensorEnabled = !editing && sensor && sensor.id;
    const { battery } = sensor;
    const scaledBattery = battery - 2;
    const batteryLevel = Math.max(25, Math.ceil(parseInt(Math.min(scaledBattery / 1.3, 1) * 100, 10) / 25) * 25);

    let sensorType = '';
    if (gate) {
      sensorType = 'gate';
    } else if (ame) {
      sensorType = 'ame';
    } else if (bollard) {
      sensorType = 'bollard';
    }

    return isSensorEnabled ? (
      <div className={`${styles.sidebar} ${styles.opened}`}>
        <div className="table-row" style={{ padding: '10px' }}>
          <div className={styles['sensor-name-containter']}>
            { can(Permissions.products.productsCreate) ? (
              <span className="table-title" onClick={onEdit}>
                <span className="icon-edit"><span className="path1" /><span className="path2" /></span>
                {sensor.serial.toUpperCase()}
              </span>
            ) : null }
            <span className="table-subtitle">{sensor.bind}</span>
          </div>
          { (can(Permissions.products.productsMaintenance) || can(Permissions.products.productsReplace)) && sensor.moteUid
            ? (
              <div className="circled-icon circled-icon__button" onClick={this.toggleSettings}>
                { this.state.showSettings ? <span className="icon-bollard" style={{ transform: 'scale(0.8, 0.8)' }}><span className="path1" /><span className="path2" /></span> : <span className="icon-build" style={{ transform: 'scale(0.8, 0.8)' }}><span className="path1" /><span className="path2" /></span>}
              </div>
            ) : null
          }
          <div className="circled-icon circled-icon__button" onClick={onClose}>
            <span className="icon-close" />
          </div>
        </div>
        <div className={`table-row table-row__vertical ${this.state.showSettings ? 'hide' : ''}`}>
          <div className="grid-x" style={{ width: '100%' }}>
            <div className="small-12 cell" style={{ position: 'relative' }}>
              {sensor.battery !== null ? <div className={`${styles.battery} ${styles[`battery-level-${batteryLevel}`]} icon-battery-${batteryLevel}`}><span className="path1" /><span className="path2" /></div> : null }
              <div className={`${styles['bollard-container']} ${sensorType === 'bollard' ? '' : 'hide'}`}>
                <div className={styles.ghost}>
                  <div className={styles['ghost-top']} />
                  <div className={styles['ghost-main']} />
                </div>
                <div className={styles.bollard} style={{ transform: `translateX(-50%) rotate(${sensor.tilt || 0}deg)` }}>
                  <div className={styles['bollard-top']} />
                  <div className={styles['bollard-main']} />
                </div>
                <div className={styles.ground} />
              </div>
            </div>
            <div className="small-12 cell">
              <div className="table-header table_header__inline" style={{ display: 'block' }}>
                { lang('sensors', 'last-update').s }
              </div>
              <div className="table-title table-title__list" style={{ marginBottom: '1.4rem' }}>
                { sensor.dataUpdatedAt ? (
                  <div className={styles.date}>
                    {`${moment(sensor.dataUpdatedAt).format('YYYY-MM-DD')}`}
                    <span className={styles['date-time']}>{`${moment(sensor.dataUpdatedAt).format('HH:mm:ss')}`}</span>
                    <span className={styles['date-zone']}>{`${moment(sensor.dataUpdatedAt).format('Z')}`}</span>
                  </div>
                ) : '--' }
              </div>
            </div>
            <div className={`small-6 cell ${sensorType === 'bollard' ? '' : 'hide'}`}>
              <div className="table-header table_header__inline" style={{ display: 'block' }}>
                { lang('sensors', 'height').s }
              </div>
              <div className="table-title table-title__list" style={{ marginBottom: '1.4rem' }}>
                {`${bollard && bollard.height ? bollard.height : '--'} mm`}
              </div>
            </div>
            <div className={`small-6 cell ${sensorType === 'bollard' ? '' : 'hide'}`}>
              <div className="table-header table_header__inline" style={{ display: 'block' }}>
                { lang('sensors', 'diameter').s }
              </div>
              <div className="table-title table-title__list" style={{ marginBottom: '1.4rem' }}>
                {`${bollard && bollard.diameter ? bollard.diameter : '--'} mm`}
              </div>
            </div>
            <div className={`small-6 cell ${sensorType === 'gate' ? '' : 'hide'}`}>
              <div className="table-header table_header__inline" style={{ display: 'block' }}>
                { lang('sensors', 'length').s }
              </div>
              <div className="table-title table-title__list" style={{ marginBottom: '1.4rem' }}>
                {`${gate && gate.length ? gate.length : '--'} mm`}
              </div>
            </div>
            <div className={`small-6 cell ${sensorType === 'ame' ? '' : 'hide'}`}>
              <div className="table-header table_header__inline" style={{ display: 'block' }}>
                { lang('sensors', 'areaRadius').s }
              </div>
              <div className="table-title table-title__list" style={{ marginBottom: '1.4rem' }}>
                {`${ame && ame.areaRadius ? ame.areaRadius : '--'} cm`}
              </div>
            </div>
            <div className={`small-6 cell ${sensorType === 'bollard' ? '' : 'hide'}`}>
              <div className="table-header table_header__inline" style={{ display: 'block' }}>
                { lang('sensors', 'tilt').s }
              </div>
              <div className="table-title table-title__list" style={{ marginBottom: '1.4rem' }}>
                {`${sensor.tilt !== null ? sensor.tilt : '--'} °`}
              </div>
            </div>
            <div className={`small-6 cell ${sensorType === 'bollard' ? '' : 'hide'}`}>
              <div className="table-header table_header__inline" style={{ display: 'block' }}>
                { lang('sensors', 'working-width').s }
              </div>
              <div className="table-title table-title__list" style={{ marginBottom: '1.4rem' }}>
                {`${sensor.tilt !== null && bollard ? parseInt((bollard.diameter || 0) + (bollard.height || 0) * Math.sin(sensor.tilt * Math.PI / 360), 10) : '--'} mm`}
              </div>
            </div>
            <div className={`small-6 cell ${sensorType === 'bollard' ? '' : 'hide'}`}>
              <div className="table-header table_header__inline" style={{ display: 'block' }}>
                { lang('sensors', 'bump-count').s }
              </div>
              <div className="table-title table-title__list" style={{ marginBottom: '1.4rem' }}>
                {`${sensor.bumpCount || '--'}`}
              </div>
            </div>
            <div className={`small-6 cell ${sensorType === 'bollard' ? '' : 'hide'}`}>
              <div className="table-header table_header__inline" style={{ display: 'block' }}>
                { lang('sensors', 'firmware-version').s }
              </div>
              <div className="table-title table-title__list" style={{ marginBottom: '1.4rem' }}>
                {`${sensor.firmwareVersion || '--'}`}
              </div>
            </div>
            <div className="small-12 cell">
              <Mutation mutation={startProvisioning} onCompleted={this.onCompleted}>
                {(action, { loading, error }) => {
                  return (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                      { error ? <MutationError graphQLError={error} lang={lang} langKey="sensors" /> : null }
                      {
                        sensor.moteUid
                          ? null
                          : <div>{(loading || provisioningStatus) ? <Spinner size={20} /> : <div className="outline-button" onClick={this.onStartProvisioning.bind(null, action)}>Start Provisioning</div>}</div>
                      }
                      { (provisioningStatus && sensor.device) ? <div className={styles['provisioning-status']}>{provisioningStatus}</div> : null }
                      { (provisioningStatus && sensor.device) ? <Spinner /> : null }
                    </div>
                  );
                }}
              </Mutation>
              {
                sensor.device ? (
                  <div className="small-12 cell">
                    <div className="table-header table_header__inline" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '1rem', flexDirection: 'column', userSelect: 'text' }}>
                      <div>{sensor.device.serial || sensor.device.id}</div>
                      { can(Permissions.superadmin.can_all) ? <div>MoteId: {sensor.moteUid}</div> : null }
                      { can(Permissions.superadmin.can_all) ? <div>Tx interval: {sensor.txInterval}</div> : null }
                    </div>
                  </div>
                ) : null
              }
            </div>
          </div>
        </div>
        { can(Permissions.products.productsReplace) && sensor.moteUid
          ? (
            <div>
              <div className={`table-row table-row__vertical ${this.state.showSettings && this.state.showEvents ? '' : 'hide'}`}>
                <div className="small-12 cell">
                  <div className="active-link" onClick={() => this.setState({ showEvents: false })}>
                    <i className="icon-arrow-back" />&nbsp;Back
                  </div>
                </div>
                <EventList locationId={location.id} />
              </div>
              <div className={`table-row table-row__vertical ${this.state.showSettings && !this.state.showEvents ? '' : 'hide'}`}>
                <div className={`small-12 cell ${location.totalBumps !== null ? '' : 'hide'}`}>
                  <div className="table-header table_header__inline" style={{ display: 'block' }}>
                    { lang('sensors', 'hits-total').s }
                  </div>
                  <div className="flex-center">
                    {location.totalBumps}
                  </div>
                  <div className="flex-center table-header table_header__inline active-link">
                    <div onClick={() => this.setState({ showEvents: true })}>{ lang('sensors', 'events-list').s }</div>
                  </div>
                </div>
                <div className={`small-12 cell ${location.totalBumps !== null ? '' : 'hide'}`}>
                  <span className="horizontal-separator" />
                </div>
                <div className="grid-x">
                  <div className="small-12 table-header table_header__inline" style={{ display: 'block' }}>
                    { lang('sensors', 'settings').s }
                  </div>
                  { can(Permissions.products.productsMaintenance) ? (
                    <Mutation mutation={sensor.paused ? unpauseSensor : pauseSensor}>
                      {(pauseAction, pauseMeta) => {
                        return (
                          <div className="small-6 cell" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{pauseMeta.loading ? <Spinner size={20} /> : <div className={styles.button} onClick={this.onPauseSensor.bind(null, pauseAction)}><img src={require(`../../images/${sensor.paused ? 'play' : 'pause'}.png`)} alt={sensor.paused ? 'Resume' : 'Pause'} />{sensor.paused ? 'Resume' : 'Pause'}</div>}</div>
                        );
                      }}
                    </Mutation>
                  ) : null }
                  { can(Permissions.products.productsMaintenance) && sensorType === 'bollard' ? (
                    <Mutation mutation={setSensorOffset}>
                      {(offsetAction, offsetMeta) => {
                        return (
                          <div className="small-6 cell" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{offsetMeta.loading ? <Spinner size={20} /> : <div className={styles.button} onClick={this.onSetOffset.bind(null, offsetAction)}><img src={require('../../images/set-offset.png')} alt="Set offset" />Set Offset</div>}</div>
                        );
                      }}
                    </Mutation>
                  ) : null }
                  { can(Permissions.products.productsMaintenance) && sensorType === 'bollard' ? (
                    <Mutation mutation={resetSensorBumpCount}>
                      {(resetAction, resetMeta) => {
                        return (
                          <div className="small-6 cell" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{resetMeta.loading ? <Spinner size={20} /> : <div className={styles.button} onClick={this.onResetCount.bind(null, resetAction)}><img src={require('../../images/reset-count.png')} alt="Set offset" />Reset Count</div>}</div>
                        );
                      }}
                    </Mutation>
                  ) : null }
                  { can(Permissions.products.productsMaintenance) ? (
                    <Mutation mutation={replaceSensor} onCompleted={this.onResetCompleted}>
                      {(replaceAction, replaceMeta) => {
                        return (
                          <div className="small-6 cell" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{replaceMeta.loading ? <Spinner size={20} /> : <div className={styles.button} onClick={this.onReplaceSensor.bind(null, replaceAction)}><img src={require('../../images/replace-sensor.png')} alt="Set offset" />Replace Sensor</div>}</div>
                        );
                      }}
                    </Mutation>
                  ) : null }
                  { can(Permissions.products.productsReplace) ? (
                    <Mutation mutation={replaceBollard} onCompleted={this.onResetCompleted}>
                      {(replaceAction, replaceMeta) => {
                        return (
                          <div className="small-6 cell" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{replaceMeta.loading ? <Spinner size={20} /> : <div className={styles.button} onClick={this.onReplaceBollard.bind(null, replaceAction)}><img src={require('../../images/replace-bollard.png')} alt="Set offset" />Replace Product</div>}</div>
                        );
                      }}
                    </Mutation>
                  ) : null }
                  { can(Permissions.products.productsMaintenance) ? (
                    <Mutation mutation={resetProvisioning} onCompleted={this.onResetCompleted}>
                      {(resetAction, resetMeta) => {
                        return (
                          <div className="small-6 cell" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{resetMeta.loading ? <Spinner size={20} /> : <div className={styles.button} onClick={this.onResetProvisioning.bind(null, resetAction)}><img src={require('../../images/reset-provisioning.png')} alt="Set offset" />Reset Provisioning</div>}</div>
                        );
                      }}
                    </Mutation>
                  ) : null }
                </div>
              </div>
            </div>
          ) : null
        }
      </div>
    ) : <div className={styles.sidebar} />;
  }
}

export default SensorDetail;
