import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Query } from 'react-apollo';

import queries from '../state/queries';
import { updateBreadcrumbs } from '../state/app/actions';

import Section from '../components/sections/Section';

type Props = {
  match: any
};
type State = {};

class SectionsPage extends Component<Props, State> {
  static contextTypes = {
    dispatch: PropTypes.func,
    push: PropTypes.func
  }

  onQueryComplete = (data: any) => {
    const { dispatch } = this.context;
    if (data && data.warehouse && data.warehouseSection) {
      dispatch(updateBreadcrumbs([{ title: data.warehouse.name, url: `/warehouses/${data.warehouse.id}` }, { title: data.warehouseSection.name, url: `/warehouses/${data.warehouse.id}/sections/${data.warehouseSection.id}` }]));
    }
  }

  render() {
    const { match } = this.props;
    const { sectionId, warehouseId } = match.params;

    return (
      <Query query={queries.sections.detail} variables={{ sectionId, warehouseId }} fetchPolicy="network-only" onCompleted={this.onQueryComplete}>
        {result => (result.data.warehouseSection ? <Section sectionId={sectionId} warehouseId={warehouseId} result={result} /> : null)}
      </Query>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

// flowlint-line-ignore
export default connect(mapStateToProps, (dispatch: Dispatch) => ({ dispatch }))(SectionsPage);
