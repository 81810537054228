import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import moment from 'moment';

import Spinner from '../common/spinner/Spinner';

import styles from './Section.scss';
import { getSensorStatus } from '../../state/utils';

type Props = {
  warehouseSection: any,
  selectedId: string,
  settings: any,
  onLocationSelection: Function
}

type State = {
  search: ?string,
  filters: {
    gates: boolean;
    bollardOk: boolean,
    bollardDamaged: boolean,
    bollardInMaintenance: boolean
  }
}

class SectionSensorList extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    can: PropTypes.func
  }

  state = {
    search: null,
    filters: {
      gates: true,
      bollardOk: true,
      bollardDamaged: true,
      bollardInMaintenance: true
    }
  }

  onFilterToggle = (filter: string) => {
    this.setState(prevState => ({ filters: { ...prevState.filters, [filter]: !prevState.filters[filter] } }));
  }

  onSearch = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ search: event.target.value });
  }

  onClearSearch = () => {
    this.setState({ search: null });
  }

  render() {
    const { locations } = this.props.warehouseSection;

    return (
      <div>
        <div className="tab-header">
          <div className={`tab-header-item tab-header-item__gate ${this.state.filters.gates ? 'tab-header-item__active' : ''}`} onClick={this.onFilterToggle.bind(null, 'gates')}>
            <span className="icon-gate" style={{ marginRight: '.5rem' }}><span className="path1" /><span className="path2" /></span>{ this.props.warehouseSection.gatesCount || 0 }
          </div>
          <div className={`tab-header-item tab-header-item__bollard-ok ${this.state.filters.bollardOk ? 'tab-header-item__active' : ''}`} onClick={this.onFilterToggle.bind(null, 'bollardOk')}>
            <span className="icon-bollard" style={{ marginRight: '.5rem' }}><span className="path1" /><span className="path2" /></span>{ this.props.warehouseSection.normalSensorsCount || 0 }
          </div>
          <div className={`tab-header-item tab-header-item__bollard-in-maintenance ${this.state.filters.bollardInMaintenance ? 'tab-header-item__active' : ''}`} onClick={this.onFilterToggle.bind(null, 'bollardInMaintenance')}>
            <span className="icon-build" style={{ marginRight: '.5rem' }}><span className="path1" /><span className="path2" /></span>{ this.props.warehouseSection.maintenanceSensorsCount || 0 }
          </div>
          <div className={`tab-header-item tab-header-item__bollard-damaged ${this.state.filters.bollardDamaged ? 'tab-header-item__active' : ''}`} onClick={this.onFilterToggle.bind(null, 'bollardDamaged')}>
            <span className="icon-error" style={{ marginRight: '.5rem' }}><span className="path1" /><span className="path2" /></span>{ this.props.warehouseSection.damagedSensorsCount || 0 }
          </div>
        </div>
        <div className="tab-search">
          <input type="text" name="sensor" placeholder="Search..." onChange={this.onSearch} value={this.state.search || ''} />
          <i className="icon-close" onClick={this.onClearSearch} style={(this.state.search && this.state.search.length > 0) ? {} : { display: 'none' }} />
        </div>
        {
          locations.filter((location) => {
            const { filters } = this.state;
            const { sensor, bollard, gate } = location;

            if (gate) {
              return filters.gates;
            }
            if (bollard) {
              if (sensor.tilt > 15) {
                return filters.bollardDamaged;
              }
              if (sensor.tilt <= 15 && sensor.tilt > 7) {
                return filters.bollardInMaintenance;
              }
              return filters.bollardOk;
            }
            return true;
          }).filter((location) => {
            const { search } = this.state;
            const { sensor } = location;
            if (search && search.length > 0) {
              return (sensor.serial.toLowerCase().indexOf(search.toLowerCase()) >= 0);
            }
            return true;
          }).map((location) => {
            const { sensor, bollard, gate, ame } = location;
            const { battery, device } = sensor;
            const scaledBattery = battery - 2;
            const batteryLevel = Math.max(25, Math.ceil(parseInt(Math.min(scaledBattery / 1.3, 1) * 100, 10) / 25) * 25);

            let sensorType = '';
            if (gate) {
              sensorType = 'gate';
            } else if (bollard) {
              sensorType = 'bollard';
            } else if (ame) {
              sensorType = 'ame';
            }

            const status = sensorType === 'gate' ? '' : getSensorStatus(sensor, 'ok');

            const milliseconds = moment().diff(sensor.dataUpdatedAt, 'milliseconds');

            const blip = milliseconds < 2000;
            const isDemo = (sensor.serial.indexOf('DEMO') === 0);
            // TODO: store the `kind` in the bollard and retrieve it here
            const danger = (milliseconds >= 2000 * this.props.settings['2'].txInterval) && !isDemo;
            const displayDate = isDemo ? moment().subtract(Math.round(Math.random() * 100) % 60, 'seconds') : moment(sensor.dataUpdatedAt);

            return (
              <div
                key={sensor.id}
                className={`table-row table-row__clickable ${sensor.id === this.props.selectedId ? 'table-row__active' : ''}`}
                onClick={this.props.onLocationSelection.bind(null, location, true)}
                style={{ position: 'relative' }}
              >
                <div className={`circled-icon circled-icon__outline circled-icon__${sensorType}${status === '' ? '' : `-${status}`}`} style={{ position: 'relative' }}>
                  { status === 'provisioning' ? <Spinner absolute size={40} fancy /> : null}
                  {
                    device && sensor.bumpCount && sensor.bumpCount > 0 ? (
                      <span className={styles.bump}>{sensor.bumpCount}</span>
                    ) : null
                  }
                  <span className={`icon-${sensorType}`}><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /></span>
                </div>
                <div className={styles['sensor-name-containter']}>
                  <div className="table-title table-title__list">
                    {(danger && device) ? <div className={`${styles.danger} icon-danger`}><span className="path1" /><span className="path2" /></div> : null }
                    {sensor.serial.toUpperCase()}{ sensor.paused ? <span style={{ fontSize: '0.7rem', opacity: 1.0, marginLeft: '0.4rem', color: '#ce4144' }}>PAUSED</span> : null }
                    {device && sensor.battery !== null ? <div className={`${styles.battery} ${styles[`battery-level-${batteryLevel}`]} icon-battery-${batteryLevel}`}><span className="path1" /><span className="path2" /></div> : null }
                    <div className={`${styles.blip} ${blip ? '' : styles['blip-hidden']}`} />
                    {device && sensor.firmwareVersion !== null ? <div className={styles.firmware}>fw<span>{sensor.firmwareVersion}</span></div> : null }
                  </div>
                  <div className="table-subtitle" style={{ display: 'flex', padding: '.2rem 0' }}>
                    <div>
                      <span className="icon-today" style={{ fontSize: '1rem', marginRight: '.3rem' }}><span className="path1" /><span className="path2" /></span>
                    </div>
                    { device && sensor.dataUpdatedAt
                      ? (
                        <div className={styles.date}>
                          {`${moment(displayDate).format('YYYY-MM-DD')}`}
                          <span className={styles['date-time']}>{`${moment(displayDate).format('HH:mm:ss')}`}</span>
                          <span className={styles['date-zone']}>{`${moment(displayDate).format('Z')}`}</span>
                        </div>
                      ) : '-' }
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  }
}

export default SectionSensorList;
