import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import initialState from '../state/initial-state';
import { Permissions } from '../state/authorization';
import Customers from '../components/customers/Customers';
import Customer from '../components/customers/Customer';

type Props = {
  session: typeof initialState.session,
};
type State = {};

class HomePage extends Component<Props, State> {
  static contextTypes = {
    can: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    routing: PropTypes.object
  }

  render() {
    const { session } = this.props;
    const { can, routing, push, dispatch } = this.context;
    const { p } = routing.location.query;

    if (can(Permissions.customers.customersIndex)) {
      return <Customers page={p ? parseInt(p, 10) : 1} onPage={(next) => { dispatch(push(`/?p=${next}`)); }} />;
    }

    return <Customer session={session} customerId={session.customer.id} editing={false} />;
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

// flowlint-line-ignore
export default connect(mapStateToProps, (dispatch: Dispatch) => ({ dispatch }))(HomePage);
