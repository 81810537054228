import React from 'react';
import PropTypes from 'prop-types';

const NumericInput = ({ name, value, min, max, style, className, onChange }: any) => {
  return (
    <input name={name} type="number" style={style} className={className} onChange={onChange} value={(value !== null && value !== undefined && !isNaN(value)) ? value : ''} max={max} min={min} />
  );
};

NumericInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.number,
  max: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

NumericInput.defaultProps = {
  name: '',
  value: null,
  min: null,
  max: null,
  className: '',
  style: {}
};

export default NumericInput;
