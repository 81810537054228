import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import initialState from '../state/initial-state';

import Customers from '../components/customers/Customers';
import Customer from '../components/customers/Customer';

type Props = {
  session: typeof initialState.session,
  match: any
};
type State = {};

class CustomersPage extends Component<Props, State> {
  static contextTypes = {
    routing: PropTypes.object,
    push: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  render() {
    const { session, match } = this.props;
    const { routing, push, dispatch } = this.context;
    const { customerId } = match.params;
    const showList = match.path === '/customers';
    const { p } = routing.location.query;

    return showList
      ? <Customers page={p ? parseInt(p, 10) : 1} onPage={(next) => { dispatch(push(`/?p=${next}`)); }} />
      : <Customer session={session} customerId={customerId} editing={match.path === '/customers/:customerId/edit'} />;
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

// flowlint-line-ignore
export default connect(mapStateToProps, (dispatch: Dispatch) => ({ dispatch }))(CustomersPage);
