import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import ReactFlagsSelect from 'react-flags-select';
import omitDeep from 'omit-deep-lodash';
import 'react-flags-select/css/react-flags-select.css';
import Spinner from '../common/spinner/Spinner';
import MutationError from '../common/errors/MutationError';
import { customerFields } from '../../state/queries';

type Props = {
  customer?: any,
  onCompleted: Function,
  onDeleted: Function
}

type State = {
  values: any,
  errors: any
}

const createCustomer = gql`
  mutation createCustomer($kind: CustomerKind!, $name: String!, $address: String, $postalCode: String, $city: String, $town: String, $country: String) {
    createCustomer(attributes: { kind: $kind, name: $name, address: $address, postalCode: $postalCode, city: $city, town: $town, country: $country }) {
      customer {
        ${customerFields}
      }
    }
  }
`;

const updateCustomer = gql`
  mutation updateCustomer($id: ID!, $kind: CustomerKind!, $name: String!, $address: String, $postalCode: String, $city: String, $town: String, $country: String) {
    updateCustomer(id: $id, attributes: { kind: $kind, name: $name, address: $address, postalCode: $postalCode, city: $city, town: $town, country: $country }) {
      customer {
        ${customerFields}
      }
    }
  }
`;

const customerDeleteMutation = gql`
  mutation deleteCustomer($id: ID!) {
    deleteCustomer(id: $id) { id }
  }
`;

class CustomerForm extends Component<Props, State> {
  static defaultProps = {
    customer: null
  }

  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    push: PropTypes.func
  }

  state = {
    values: { kind: 'client', country: 'IT', name: '' },
    errors: {}
  }

  componentWillMount() {
    if (this.props.customer) {
      this.setState({ values: { ...this.props.customer } });
    }
  }

  onTextChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState(prevState => ({ values: { ...prevState.values, [field]: value } }));
  }

  onCountryChange = (field: string, country: string) => {
    this.setState(prevState => ({ values: { ...prevState.values, [field]: country } }));
  }

  onClick = (action: Function, e: SyntheticMouseEvent<*> | SyntheticTouchEvent<*>) => {
    e.preventDefault();
    const variables = omitDeep({ ...this.state.values }, '__typename');

    const errors = this.validate(variables);
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    action({ variables });
  }

  onCustomerDelete = (action: Function) => {
    confirmAlert({
      title: this.context.lang('customers', 'delete-confirm-title').toString(),
      message: this.context.lang('customers', 'delete-confirm-lite').toString(),
      buttons: [
        {
          label: this.context.lang('global', 'yes').toString(),
          onClick: () => {
            confirmAlert({
              title: this.context.lang('customers', 'delete-confirm-title').toString(),
              message: this.context.lang('customers', 'delete-confirm').toString(),
              buttons: [
                {
                  label: this.context.lang('global', 'yes').toString(),
                  onClick: () => action({ variables: { id: (this.props.customer || {}).id } })
                },
                { label: this.context.lang('global', 'no').toString() }
              ]
            });
          }
        },
        { label: this.context.lang('global', 'no').toString() }
      ]
    });
  }

  onSelect = (field: string, option: { label: string, value: string }) => {
    this.setState(prevState => ({ values: { ...prevState.values, [field]: option.value } }));
  }

  isEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validate = (values: any) => {
    const errors = {};
    ['name', 'address', 'postalCode', 'city', 'town', 'country'].forEach((f) => {
      if (!values[f] || values[f].trim().length === 0) {
        errors[f] = 'empty';
      }
    });
    return errors;
  }

  render() {
    const { values, errors } = this.state;
    const { lang } = this.context;

    return (
      <div className="table-dark grid-x centered-form">
        <div className="small-12 medium-8 medium-offset-2 large-6 large-offset-3 xlarge-4 xlarge-offset-4 cell">
          <form className="grid-x">
            <div className="form-title cell small-12">
              { lang('customers', this.props.customer ? 'edit' : 'create').s }
            </div>
            <label htmlFor="name" className="cell small-12 medium-6">
              <div>{ lang('customers', 'name').s }</div>
              <input className={errors.name ? 'error' : ''} type="text" name="name" value={values.name || ''} onChange={this.onTextChange.bind(null, 'name')} />
            </label>
            <label htmlFor="address" className="cell small-12 medium-6">
              <div>{ lang('customers', 'address').s }</div>
              <input className={errors.address ? 'error' : ''} type="text" name="address" value={values.address || ''} onChange={this.onTextChange.bind(null, 'address')} />
            </label>
            <label htmlFor="postalCode" className="cell small-12 medium-6">
              <div>{ lang('customers', 'postalCode').s }</div>
              <input className={errors.postalCode ? 'error' : ''} type="text" name="postalCode" value={values.postalCode || ''} onChange={this.onTextChange.bind(null, 'postalCode')} />
            </label>
            <label htmlFor="city" className="cell small-12 medium-6">
              <div>{ lang('customers', 'city').s }</div>
              <input className={errors.city ? 'error' : ''} type="text" name="city" value={values.city || ''} onChange={this.onTextChange.bind(null, 'city')} />
            </label>
            <label htmlFor="town" className="cell small-12 medium-6">
              <div>{ lang('customers', 'town').s }</div>
              <input className={errors.town ? 'error' : ''} type="text" name="town" value={values.town || ''} onChange={this.onTextChange.bind(null, 'town')} />
            </label>
            <label htmlFor="country" className="cell small-12 medium-6">
              <div>{ lang('customers', 'country').s }</div>
              <ReactFlagsSelect searchable placeholder="Select Country" defaultCountry={values.country} onSelect={this.onCountryChange.bind(null, 'country')} />
            </label>
            <Mutation mutation={this.props.customer ? updateCustomer : createCustomer} onCompleted={this.props.onCompleted}>
              {(action, { loading, error }) => {
                return (
                  <div className="grid-x small-12 cell centered" style={{ marginTop: '2rem' }}>
                    {loading ? <Spinner /> : <input className="outline-button" type="submit" onClick={this.onClick.bind(null, action)} value={lang('global', values.id ? 'update' : 'create').s} />}
                    <MutationError graphQLError={error} errors={this.state.errors} lang={lang} langKey="customer" dark />
                  </div>
                );
              }}
            </Mutation>
            { this.props.customer
              ? (
                <div className="grid-x small-12 cell centered" style={{ marginTop: '.5rem' }}>
                  <Mutation mutation={customerDeleteMutation} onCompleted={this.props.onDeleted}>
                    {(deleteAction, result) => {
                      return result.loading ? <Spinner /> : <div className="small-12 cell" style={{ display: 'flex', justifyContent: 'center' }}><div className="action-button__subtle" onClick={this.onCustomerDelete.bind(null, deleteAction)}>{lang('customers', 'delete').s}</div></div>;
                    }}
                  </Mutation>
                </div>
              ) : null
            }
          </form>
        </div>
        <div className="icon-button__form show-for-large" onClick={this.props.onCompleted}>
          <span className="icon-close white" />
        </div>
      </div>
    );
  }
}

export default CustomerForm;
