import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import moment from 'moment';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';

import Spinner from '../common/spinner/Spinner';

import Error from '../common/errors/Error';
import SectionMap from './SectionMap';
import SensorDetail from '../sensors/SensorDetail';
import SensorForm from '../sensors/SensorForm';
import Map from '../common/map/Map';

import styles from './Section.scss';
import SectionSensorList from './SectionSensorList';
import { Permissions } from '../../state/authorization';
import NumericInput from '../common/form/NumericInput';
import MutationError from '../common/errors/MutationError';

const updateWarehouseSectionScale = gql`
  mutation updateWarehouseSectionScale($id: ID!, $scaleStartX: Float!, $scaleStartY: Float!, $scaleEndX: Float!, $scaleEndY: Float!, $scale: Float!) {
    updateWarehouseSectionScale(id: $id, attributes: { scaleStartX: $scaleStartX, scaleStartY: $scaleStartY, scaleEndX: $scaleEndX, scaleEndY: $scaleEndY, scale: $scale }) {
      warehouseSection {
        id
      }
    }
  }
`;

type Props = {
  result: any,
  sectionId: string,
  warehouseId: string
}

type State = {
  values: any,
  type: string,
  hover: any,
  editing: boolean,
  packetKeys: Array<any>,
  showListOnMobile: boolean,
  centerMapOnClick: boolean,
  scaleWarning: boolean,
  editScale: boolean,
  scale: {
    a: { x: number; y: number },
    b: { x: number; y: number },
    scale: number,
  },
  filters: {
    bollardOk: boolean,
    bollardDamaged: boolean,
    bollardInMaintenance: boolean
  }
}

class Section extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    replace: PropTypes.func,
    can: PropTypes.func,
    stompClient: PropTypes.object
  }

  state = {
    type: 'bollard',
    values: {},
    hover: {},
    packetKeys: [],
    editing: false,
    showListOnMobile: false,
    centerMapOnClick: true,
    scaleWarning: false,
    editScale: false,
    scale: {},
    filters: {
      bollardOk: true,
      bollardDamaged: true,
      bollardInMaintenance: true
    }
  }

  streams = []

  componentDidMount() {
    if (this.props.result.data.warehouseSection.scale) {
      const { scaleStartX, scaleStartY, scaleEndX, scaleEndY, scale } = this.props.result.data.warehouseSection;
      this.setState({ scaleWarning: false, scale: { a: { x: scaleStartX, y: scaleStartY }, b: { x: scaleEndX, y: scaleEndY }, scale } });
    } else {
      this.setState({ scaleWarning: true });
    }

    this.setupConnection();
  }

  componentWillUnmount() {
    this.streams.forEach(s => this.context.stompClient.removeStompStreamListener(s));
    this.streams = [];
  }

  setupConnection = () => {
    this.context.stompClient.connect().then(() => {
      this.streams.forEach(s => this.context.stompClient.removeStompStreamListener(s));
      this.streams = [];

      const callback = (res) => {
        if (res.lastConfirmedPacketKey) {
          if (this.state.packetKeys.indexOf(res.lastConfirmedPacketKey) >= 0) {
            return;
          }
          this.state.packetKeys.push(res.lastConfirmedPacketKey);
        }

        if (window.VERBOSE_LIVE_MSG) {
          console.log('snapshot', res);  // eslint-disable-line
        }

        if (res.content) {
          const { status, moteUid } = res.content;
          if (status && moteUid) {
            const sensor = this.props.result.data.warehouseSection.locations.map(l => l.sensor).filter(s => s.moteUid === moteUid)[0];
            if (sensor && sensor.id && !sensor.paused) {
              this.props.result.client.writeData({ data: { sensor: { id: sensor.id, tilt: status.tilt, battery: status.battery, bumpCount: status.bumpCount, temperature: status.temperature, firmwareVersion: status.fwVersion, dataUpdatedAt: moment().toString(), __typename: 'Sensor' } } });
              // Hack to reset the 'blip' after receiving data
              setTimeout(() => {
                this.props.result.client.writeData({ data: { sensor: { id: sensor.id, __typename: 'Sensor' } } });
                if (res.lastConfirmedPacketKey) {
                  this.setState({ packetKeys: this.state.packetKeys.filter(p => p !== res.lastConfirmedPacketKey) });   // eslint-disable-line
                }
              }, 2000);
            }
          }
        }
      };

      const devices = this.props.result.data.warehouse.devices.map(d => d.id);
      const subs = devices.map(d => ({ stream: 'snapshot', topic: d, cache: false, callback }));
      this.context.stompClient.streamFrom(subs).then((streams) => {
        this.streams = streams;
      });
    });
  }

  onAddSensor = () => {
    this.setState({ editing: true, showListOnMobile: false, values: { length: 0, height: 0, diameter: 0, section: { id: this.props.sectionId } } });
  }

  onAddSensorWithPosition = (x: Number, y: Number) => {
    this.setState({ editing: true, values: { height: 0, diameter: 0, section: { id: this.props.sectionId }, x, y } });
  }

  onCreatedSensor = (response: any) => {
    this.onClearSelection();
    const { data } = this.props.result;
    let filteredItems = [];
    if (this.state.type === 'gate') {
      filteredItems = data.warehouseSection ? data.warehouseSection.locations.filter(l => (l.id === response.createGateSensor.id)) : [];
    } else if (this.state.type === 'ame') {
      filteredItems = data.warehouseSection ? data.warehouseSection.locations.filter(l => (l.id === response.createAmeSensor.id)) : [];
    } else if (this.state.type === 'bollard') {
      filteredItems = data.warehouseSection ? data.warehouseSection.locations.filter(l => (l.id === response.createBollardSensor.id)) : [];
    }
    if (filteredItems.length > 0) {
      this.onLocationSelection(filteredItems[0], false);
    }
  }

  onMapClick = (x: Number, y: Number) => {
    if (this.state.editScale) {
      if ((this.state.scale.a && this.state.scale.b)) {
        this.setState(prevState => ({ scale: { ...prevState.scale, a: { x, y }, b: undefined } }));
      } else if (!this.state.scale.a) {
        this.setState(prevState => ({ scale: { ...prevState.scale, a: { x, y } } }));
      } else {
        this.setState(prevState => ({ scale: { ...prevState.scale, b: { x, y } } }));
      }
    } else if (this.state.editing) {
      this.setState(prevState => ({ values: { ...prevState.values, x, y } }));
    } else if (this.state.values.id) {
      this.onLocationSelection(null, false);
    }
  }

  onEdit = (location: any) => {
    let type = 'bollard';
    if (location.gate) {
      type = 'gate';
    }
    if (location.ame) {
      type = 'ame';
    }

    this.setState({ editing: true, type, values: { ...location, ...location.bollard, ...location.gate, ...location.ame, ...location.sensor }, hover: {} });
  }

  onLocationSelection = (location: any, centerMapOnClick: boolean) => {
    this.setState({ editing: false, values: { ...location }, hover: {}, centerMapOnClick });
  }

  onClearSelection = () => {
    this.setState({ editing: false, values: {}, hover: {} });
  }

  onClearPosition = () => {
    this.setState(prevState => ({ values: { ...prevState.values, x: undefined, y: undefined } }));
  }

  onTextChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState(prevState => ({ values: { ...prevState.values, [field]: value } }));
  }

  onNumberChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState(prevState => ({ values: { ...prevState.values, [field]: parseInt(value, 10) } }));
  }

  onScaleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState(prevState => ({ scale: { ...prevState.scale, scale: parseInt(value, 10) } }));
  }

  onTypeChange = (type: any) => {
    this.setState(prevState => ({ ...prevState, type: type.value }));
  }

  onDeviceSelect = (option: { label: string, value: string }) => {
    this.setState(prevState => ({ ...prevState, values: { ...prevState.values, device: { id: option.value } }, centerMapOnClick: false }));
  }

  toggleListView = (showListOnMobile: boolean) => {
    this.setState({ showListOnMobile });
  }

  onProvisioningComplete = (sensorId: string, gatewayId: string, moteUid: string) => {
    this.props.result.client.writeData({ data: { sensor: { id: sensorId, moteUid, __typename: 'Sensor', device: { id: gatewayId, __typename: 'Device' } } } });
  }

  onScaleSave = (action: any) => {
    action({
      variables: {
        id: this.props.sectionId,
        scaleStartX: this.state.scale.a.x,
        scaleStartY: this.state.scale.a.y,
        scaleEndX: this.state.scale.b.x,
        scaleEndY: this.state.scale.b.y,
        scale: this.state.scale.scale
      }
    });
  }

  onScaleEdit = () => {
    this.setState({ editScale: true });
  }

  onDismissScaleEdit = () => {
    if (this.props.result.data.warehouseSection.scale) {
      const { scaleStartX, scaleStartY, scaleEndX, scaleEndY, scale } = this.props.result.data.warehouseSection;
      this.setState({ editScale: false, scale: { a: { x: scaleStartX, y: scaleStartY }, b: { x: scaleEndX, y: scaleEndY }, scale } });
    } else {
      this.setState({ editScale: false, scale: { a: undefined, b: undefined, scale, undefined } });
    }
  }

  renderScaleUI = () => {
    const { lang } = this.context;
    const saveEnabled = this.state.scale.a && this.state.scale.b && this.state.scale;
    return (
      <div>
        {
          this.state.scaleWarning
            ? (
              <div className={styles['scale-warning']}>
                {lang('sections', 'scale-unset').s}
                <span>
                  <button type="button" className="table-header__button" onClick={() => this.setState({ scaleWarning: false, editScale: true })}>{lang('sections', 'scale-set').s}</button>
                  <div className="circled-icon circled-icon__button" onClick={() => this.setState({ scaleWarning: false })}>
                    <span className="icon-close" />
                  </div>
                </span>
              </div>
            ) : null
        }
        {
          this.state.editScale
            ? (
              <div className={styles['scale-form']}>
                <title>
                  {lang('sections', 'scale-hint').s}
                  <div className="circled-icon circled-icon__button" onClick={this.onDismissScaleEdit}>
                    <span className="icon-close" />
                  </div>
                </title>
                <main>
                  <div>
                    <span style={this.state.scale.a === undefined ? { opacity: 0.3 } : {}}>A</span>
                    <svg style={{ width: '20px', height: '20px', margin: '0 10px' }} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                    <span style={this.state.scale.b === undefined ? { opacity: 0.3 } : {}}>B</span>
                    <div className={styles['scale-input']}>
                      <NumericInput type="number" name="scale" value={this.state.scale.scale || ''} onChange={this.onScaleChange} />
                      cm
                    </div>
                  </div>
                  <div>
                    <Mutation mutation={updateWarehouseSectionScale} onCompleted={() => { this.setState({ scaleWarning: false, editScale: false }); this.props.result.refetch(); }}>
                      {(action, { loading, error }) => {
                        return (
                          <div className="grid-x small-12 cell centered" style={{ marginTop: '2rem' }}>
                            {loading ? <Spinner /> : <button type="button" className="table-header__button" onClick={saveEnabled ? this.onScaleSave.bind(null, action) : () => {}} style={saveEnabled ? {} : { opacity: 0.5 }}>{lang('sections', 'scale-set').s}</button>}
                            <MutationError graphQLError={error} errors={this.state.errors} lang={lang} langKey="devices" />
                          </div>
                        );
                      }}
                    </Mutation>
                  </div>
                </main>
              </div>
            ) : null
        }
      </div>
    );
  }

  render() {
    const { lang, can } = this.context;
    const { data, loading, error, refetch } = this.props.result;

    const selectedLocation = data && data.warehouseSection ? (data.warehouseSection.locations.filter(l => l.id === this.state.values.id)[0] || { sensor: {} }) : { sensor: {} };

    let json = {};

    try {
      const { settings } = data.warehouse;
      json = JSON.parse(settings);
    } catch (e) {
      json = {};
    }
    let selectedGroup = 'sensors';
    if (this.state.type === 'gate') {
      selectedGroup = 'gates';
    }
    if (this.state.type === 'ame') {
      selectedGroup = 'ame';
    }

    return (
      <div className="grid-x full-page">
        { loading ? <Spinner absolute /> : null }
        { error ? <Error error={error} lang={lang} /> : null }
        { data && data.warehouseSection ? (
          <div className="small-12 cell grid-x full-page">
            <div className="small-12 large-3 cell" style={{ maxHeight: '100%' }}>
              <div className="table table__minified table-mobile__upper-section">
                <div className="table-row">
                  <div className="circled-icon circled-icon__section show-for-large">
                    <span className="icon-section white"><span className="path1" /><span className="path2" /><span className="path3" /></span>
                  </div>
                  <div className={styles['warehouse-name-containter']}>
                    <span className="table-title">
                      {data.warehouseSection.name}
                    </span>
                    <span className="table-subtitle">
                      {`${data.warehouseSection.sensors.length} ${data.warehouseSection.sensors.length === 0 ? lang('sensors', 'bollard').s : lang('sections', 'bollard-count').s}`}
                    </span>
                  </div>
                  <div className="table-actions">
                    { can(Permissions.products.productsCreate) ? <span className="icon-add_circle" onClick={this.onAddSensor}><span className="path1" /><span className="path2" /></span> : null }
                  </div>
                </div>
              </div>
              <div className={`table table__complementary ${(this.state.showListOnMobile && !this.state.values.id) || (this.state.editing && (this.state.values.x && this.state.values.y)) ? '' : 'show-for-large'} table-mobile__form-section`}>
                {
                  (this.state.editing) ? (
                    <SensorForm
                      type={this.state.type}
                      values={this.state.values}
                      devices={data.warehouse.devices}
                      warehouseId={this.props.warehouseId}
                      hasScale={!!this.props.result.data.warehouseSection.scale}
                      sectionId={this.props.sectionId}
                      onTextChange={this.onTextChange}
                      onNumberChange={this.onNumberChange}
                      onCreatedSensor={this.onCreatedSensor}
                      onClose={this.onClearSelection}
                      onDeleted={this.onClearSelection}
                      onClearPosition={this.onClearPosition}
                      onDeviceSelect={this.onDeviceSelect}
                      onTypeChange={this.onTypeChange}
                      onSetScale={() => this.setState({ scaleWarning: false, editScale: true, editing: false, values: {}, hover: {} })}
                    />
                  ) : (
                    <SectionSensorList
                      warehouseSection={data.warehouseSection}
                      selectedId={this.state.values.id}
                      settings={json}
                      onLocationSelection={this.onLocationSelection}
                    />
                  )
                }
                {
                  !this.state.editing
                    ? <div className="fab fab__white hide-for-large" onClick={this.toggleListView.bind(null, false)}><span className="icon-location"><span className="path1" /><span className="path2" /><span className="path3" /></span></div>
                    : null
                }
              </div>
            </div>
            <div className={`small-12 large-9 cell ${styles.map} ${!((this.state.showListOnMobile && !this.state.values.id) || (this.state.editing && (this.state.values.x && this.state.values.y))) ? '' : 'show-for-large'}`}>
              <div className="table table-mobile__bottom-section" style={{ overflow: 'hidden' }}>
                { this.renderScaleUI() }
                {
                  data.warehouseSection.file.includes('.svg')
                    ? <SectionMap file={data.warehouseSection.file || null} onMapClick={this.onMapClick} onLocationSelection={this.onLocationSelection} sensors={data.warehouseSection.sensors} sensor={this.state.values} hover={this.state.hover} editing={this.state.editing} />
                    : (
                      <Map
                        file={data.warehouseSection.file}
                        scale={this.state.scale}
                        data={[{
                          group: 'sensors',
                          icon: 'bollard',
                          items: data.warehouseSection.locations.filter(l => l.bollard && l.sensor).map(l => ({ ...l, name: l.sensor.serial })),
                          onSelect: this.onLocationSelection,
                          onEdit: this.onEdit
                        }, {
                          group: 'gates',
                          icon: 'gate',
                          items: data.warehouseSection.locations.filter(l => l.gate && l.sensor).map(l => ({ ...l, name: l.sensor.serial })),
                          onSelect: this.onLocationSelection,
                          onEdit: this.onEdit
                        }, {
                          group: 'ame',
                          icon: 'ame',
                          items: data.warehouseSection.locations.filter(l => l.ame && l.sensor).map(l => ({ ...l, name: l.sensor.serial })),
                          onSelect: this.onLocationSelection,
                          onEdit: this.onEdit
                        }]}
                        onMapClick={this.onMapClick}
                        selectedItem={this.state.values}
                        hoveredItem={this.state.hover ? this.state.hover.id : ''}
                        selectedGroup={selectedGroup}
                        centerMapOnClick={this.state.centerMapOnClick}
                        onScaleEdit={this.onScaleEdit}
                      />
                    )
                }
                <div className="fab fab__white hide-for-large" onClick={this.toggleListView.bind(null, true)}><span className="icon-list" /></div>
                <div className={`${styles.legend} show-for-large`}>
                  <span className={styles['legend--good']}><i />{lang('legend', 'normal').s}</span>
                  <span className={styles['legend--maintenance']}><i />{lang('legend', 'maintenance').s}</span>
                  <span className={styles['legend--damaged']}><i />{lang('legend', 'damaged').s}</span>
                </div>
                <SensorDetail
                  location={selectedLocation}
                  editing={this.state.editing}
                  onEdit={() => this.onEdit(selectedLocation)}
                  onClose={this.onClearSelection}
                  refetch={refetch}
                  onProvisioningComplete={this.onProvisioningComplete}
                />
                <div className={`${styles['select-position-container']} ${this.state.editing && !(this.state.values.x && this.state.values.y) ? 'hide-for-large' : 'hide'}`}>
                  {lang('sections', 'add-position').s}
                </div>
              </div>
            </div>
          </div>
        ) : null }
      </div>
    );
  }
}

export default Section;
