import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import queries from '../../state/queries';
import Spinner from '../common/spinner/Spinner';

import styles from './DeviceDetail.scss';
import { Permissions } from '../../state/authorization';

type Props = {
  device: any,
  onEdit: Function,
  onClose: Function,
  editing: bool
}

type State = {
  showJsonConfig: boolean;
}

class DeviceDetail extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    replace: PropTypes.func,
    can: PropTypes.func
  }

  state = {
    showJsonConfig: false,
  }

  componentWillUpdate(next: any) {
    if (next.device.id !== this.props.device.id) {
      this.setState({ showJsonConfig: false }); // eslint-disable-line
    }
  }

  onDownload = (content: string) => {
    // flowlint-line-ignore
    if (!(typeof Android === 'undefined' || Android === null)) {
      Android.downloadConfig(content);
    } else {
      const download = document.createElement('a');
      download.setAttribute('target', '_blank');
      const file = new Blob([content], { type: 'text/plain' });
      download.href = URL.createObjectURL(file);
      download.download = 'stommpy-plugin.json';
      // flowlint-line-ignore
      document.body.appendChild(download);
      download.click();
      setTimeout(() => {
        // flowlint-line-ignore
        document.body.removeChild(download);
      }, 500);
    }
  }

  onDownloadArchive = (content: string) => {
    const download = document.createElement('a');
    download.setAttribute('target', '_blank');
    const file = new Blob([atob(content)], { type: 'application/zip' });
    download.href = URL.createObjectURL(file);
    download.download = 'stommpy-update.zip';
    // flowlint-line-ignore
    document.body.appendChild(download);
    download.click();
    setTimeout(() => {
      // flowlint-line-ignore
      document.body.removeChild(download);
    }, 500);
  }


  render() {
    const { lang, can } = this.context;
    const { editing, device, onEdit, onClose } = this.props;
    const isDeviceEnabled = !editing && device && device.id;
    const ssh = `ssh -p 443 fancy@ssh.${device.hostname || 'NO_HOSTNAME_AVAILABLE'}.stommpy.tun.spacebunny.io`;

    return isDeviceEnabled ? (
      <div className={`${styles.sidebar} ${styles.opened}`}>
        <div className="table-row">
          <div className={styles['device-name-containter']}>
            <span className="table-title" onClick={onEdit}>
              {device.serial.toUpperCase()}
              <span className="icon-edit"><span className="path1" /><span className="path2" /></span>
            </span>
          </div>
          <div className="circled-icon circled-icon__button" onClick={onClose}>
            <span className="icon-close" />
          </div>
        </div>
        <div className="table-row table-row__vertical">
          <div className="grid-x" style={{ width: '100%' }}>
            <div className="small-12 cell">
              { can(Permissions.superadmin.can_all)
                ? (
                  <div>
                    <div className="table-header table_header__inline" style={{ display: 'block' }}>
                      SSH
                    </div>
                    <CopyToClipboard text={ssh}>
                      <div className={styles.download}>Copy to clipboard</div>
                    </CopyToClipboard>
                    <pre className={`${styles.code} small-12 cell`}>
                      {ssh}
                    </pre>
                  </div>
                ) : null
              }
              <div className="table-header table_header__inline" style={{ display: this.state.showJsonConfig ? 'block' : 'none' }}>
                { lang('devices', 'configuration').s }
              </div>
              <div className="table-title table-title__list" style={{ position: 'relative', minHeight: '4rem', display: this.state.showJsonConfig ? '' : 'none' }}>
                <Query query={queries.devices.configs} variables={{ id: device.id }} fetchPolicy="network-only">
                  {({ loading, error, data }) => {
                    if (loading && device.id) return <Spinner absolute />;
                    if (error) return <div>--</div>;
                    if (!data) return null;

                    let configs;
                    try {
                      configs = JSON.parse(data.deviceConfigs);
                    } catch (e) {
                      configs = {};
                    }
                    const string = JSON.stringify(configs, null, 2);

                    return (
                      <pre className={`${styles.code} small-12 cell`}>
                        <div className={styles.download} onClick={this.onDownload.bind(null, string)}>{lang('devices', 'configuration-download').s}</div>
                        {string}
                      </pre>
                    );
                  }}
                </Query>
              </div>

              <div className="table-title table-title__list" style={{ position: 'relative', minHeight: '4rem' }}>
                <Query query={queries.devices.configArchive} variables={{ id: device.id }} fetchPolicy="network-only">
                  {({ loading, error, data }) => {
                    if (loading && device.id) return <Spinner absolute />;
                    if (error) return <div>--</div>;
                    if (!data) return null;

                    return (
                      <div className={styles.download} onClick={this.onDownloadArchive.bind(null, data.deviceConfigArchive)}>Download update script</div>
                    );
                  }}
                </Query>
                <br />
                { !this.state.showJsonConfig && <div className={styles.download} style={{ fontWeight: 500 }} onClick={() => { this.setState({ showJsonConfig: true }); }}>Show Json Config</div> }
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : <div className={styles.sidebar} />;
  }
}

export default DeviceDetail;
