import React, { Component } from 'react';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Spinner from '../common/spinner/Spinner';
import LinkCard from '../common/card/LinkCard';
import queries from '../../state/queries';
import { updateBreadcrumbs } from '../../state/app/actions';

type Props = {
  customerId: ?string
}

type State = {
  page: number,
  order: string
}

class Users extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
  }

  state = { page: 1, order: 'firstName_ASC' }

  onQueryComplete = (data: any) => {
    const { dispatch, lang } = this.context;
    dispatch(updateBreadcrumbs([{ title: data.customer.name, url: `/customers/${data.customer.id}` }, { title: lang('user', 'users').s }]));
  }

  render() {
    const { lang } = this.context;
    const { page, order } = this.state;

    return (
      <div className="page">
        <Query query={queries.users.list} variables={{ customerId: this.props.customerId, page, order }} fetchPolicy="network-only" onCompleted={this.onQueryComplete}>
          {({ loading, error, data }) => {
            if (loading) return <div className="page"><Spinner absolute /></div>;
            if (error) return `Error!: ${error.message}`;
            if (!data) return null;

            return (
              <div>
                <div className="action-header">
                  <div className="title" style={{ fontWeight: 600, color: 'white' }}>{ lang('user', 'users').s }</div>
                  <Link to={`/customers/${this.props.customerId || ''}/users/new`} className="inline-button">{ lang('user', 'create').s }</Link>
                </div>
                <div className="grid-x">
                  {
                    data.users.length > 0
                      ? data.users.map(u => <LinkCard className="small-12 medium-3 cell" title={`${u.firstName} ${u.lastName}`} subtitle={lang('roles', u.role).s} key={u.id} url={`/customers/${u.customer.id}/users/${u.id}`} />)
                      : <span className="error-container__inline error-container__dark" style={{ alignItems: 'flex-start' }}><h3 style={{ padding: '1rem .5rem' }}>Empty List.</h3></span>
                  }
                </div>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default Users;
