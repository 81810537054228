import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Query } from 'react-apollo';
import Spinner from '../components/common/spinner/Spinner';
import queries from '../state/queries';

type Props = {
};
type State = {
};

class StatsPage extends Component<Props, State> {
  static contextTypes = {
  }

  state = {
  }

  render() {
    return (
      <Query query={queries.stats.index} variables={{ }} fetchPolicy="no-cache">
        {({ loading, data }) => {
          const cellStyle = { display: 'flex', justifyContent: 'space-between', marginTop: '1rem' };
          return (
            <div className="table">
              <div className="grid-x">
                { loading ? <Spinner /> : null }
                { !loading && data && data.stats
                  ? (
                    <div className="small-10 medium-8 large-4 cell" style={{ display: 'flex', flexDirection: 'column', margin: '2rem', padding: '1rem' }}>
                      <span style={cellStyle}><b>Customers: </b>{ data.stats.customersCount }</span>
                      <span style={cellStyle}><b>Warehouses: </b>{ data.stats.warehousesCount }</span>
                      <span style={cellStyle}><b>Bollards: </b>{ data.stats.bollardsCount }</span>
                      <span style={cellStyle}><b>Total Sensors: </b>{ data.stats.sensorsCount }</span>
                      <span style={cellStyle}><b>Sensors (provisioned): </b>{ data.stats.connectedSensorsCount }</span>
                      <span style={cellStyle}><b>Sensors (not provisioned): </b>{ data.stats.idleSensorsCount }</span>
                      <span style={cellStyle}><b>Concentrators: </b>{ data.stats.devicesCount }</span>
                    </div>
                  )
                  : null
                }
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

// flowlint-line-ignore
export default connect(mapStateToProps, (dispatch: Dispatch) => ({ dispatch }))(StatsPage);
