import keyMirror from 'keymirror';
import _ from 'lodash';

export const UserRoles = keyMirror({
  superadmin: null,
  admin: null,
  maintainer_stommpy: null,
  maintainer: null,
  distributor: null,
  marketing: null,
  client: null
});
export type UserRolesType = $Keys<typeof UserRoles>;

export const Permissions = {
  superadmin: keyMirror({
    can_all: null,
  }),
  home: keyMirror({
    homeIndex: null,
    stats: null,
    download: null
  }),
  customers: keyMirror({
    customersIndex: null,
    customersDetail: null,
    customersCreate: null,
  }),
  users: keyMirror({
    usersIndex: null,
    usersCreate: null,
    usersDetail: null,
  }),
  warehouses: keyMirror({
    warehousesCreate: null
  }),
  products: keyMirror({
    productsCreate: null,
    productsMaintenance: null,
    productsReplace: null
  })
};

const ClientRole = [
  Permissions.home.homeIndex
];

const MarketingRole = [
  Permissions.home.homeIndex,
  Permissions.customers.customersIndex
];

const DistributorRole = [
  Permissions.home.homeIndex,
  Permissions.customers.customersIndex,
  Permissions.products.productsCreate,
  Permissions.products.productsReplace,
  Permissions.home.download
];

const MaintainerRole = [
  Permissions.home.homeIndex,
  Permissions.products.productsCreate,
  Permissions.products.productsReplace,
  Permissions.home.download
];

const StommpyMaintainerRole = [
  ...MaintainerRole,
  Permissions.customers.customersIndex,
  Permissions.home.download
];

const AdminRole = [
  ...StommpyMaintainerRole,
  Permissions.warehouses.warehousesCreate,
  ...Object.keys(Permissions.users),
  Permissions.products.productsCreate,
  Permissions.products.productsMaintenance,
  Permissions.products.productsReplace,
  Permissions.home.download,
  ...Object.keys(Permissions.customers)
];

const SuperadminRole = [
  ...AdminRole,
  ...Object.keys(Permissions.superadmin),
];

export const Roles = {
  superadmin: SuperadminRole,
  admin: AdminRole,
  maintainer_stommpy: StommpyMaintainerRole,
  maintainer: MaintainerRole,
  distributor: DistributorRole,
  marketing: MarketingRole,
  client: ClientRole
};

export const can = (role: UserRolesType, permissions: string | Array<string>) => {
  let res = false;
  if (role && Roles[role]) {
    if (typeof permissions === 'string') {
      res = Roles[role].includes(permissions);
    } else if (_.isArray(permissions)) {
      res = true;
      permissions.forEach((permission) => {
        res = res && Roles[role].includes(permission);
      });
    } else res = false;

    if (Roles[role].includes(Permissions.superadmin.can_all)) res = true;
  }

  return res;
};
