import React, { Component } from 'react';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Tooltip from 'react-tooltip-lite';

import Queries from '../../state/queries';
import { updateBreadcrumbs } from '../../state/app/actions';

import Spinner from '../common/spinner/Spinner';
import Error from '../common/errors/Error';

import styles from './Customers.scss';
import { Permissions } from '../../state/authorization';
import Pager from '../common/pager/Pager';

type Props = {
  page: number,
  onPage: Function
}

type State = {
  order: string
}

class Customers extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    can: PropTypes.func.isRequired
  }

  state = { order: 'name_ASC' }

  onQueryComplete = () => {
    const { dispatch } = this.context;
    dispatch(updateBreadcrumbs([]));
  }

  getInfoTooltip = (status: string) => {
    const { lang } = this.context;
    return (
      <Tooltip
        content={(
          <span>
            {lang('sensors', `bollard-${status}-info`).s}
          </span>
        )}
        background="#303030"
        direction="bottom"
        distance={15}
        tipContentClassName="bollard-info-container"
        className="bollard-info-target"
      >
        <span className="icon-info" style={{ fontSize: '1rem' }}><span className="path1" /><span className="path2" /></span>
      </Tooltip>
    );
  }

  render() {
    const { lang, can } = this.context;
    const { order } = this.state;
    const { page } = this.props;

    return (
      <div className="table">
        <div className="small-12 cell table-header">
          <span style={{ flex: '2' }}>{lang('customers', 'customer').s}</span>
          <span className="table_header__warehouse" style={{ flex: '1' }}>{lang('customers', 'warehouses').s}</span>
          <div className="table_header__gate" style={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <span style={{ paddingRight: '0.5rem' }}>{lang('customers', 'gate-count').s}</span>
          </div>
          <div className="table_header__ok" style={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <span style={{ paddingRight: '0.5rem' }}>{lang('customers', 'bollard-count').s}</span>
            { this.getInfoTooltip('ok') }
          </div>
          <div className="table_header__in-maintenance" style={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <span style={{ paddingRight: '0.5rem' }}>{lang('customers', 'in-maintenance').s}</span>
            { this.getInfoTooltip('in-maintenance') }
          </div>
          <div className="table_header__damaged" style={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <span style={{ paddingRight: '0.5rem' }}>{lang('customers', 'damaged').s}</span>
            { this.getInfoTooltip('damaged') }
          </div>
          <span style={{ flex: '0.5' }}>{can(Permissions.customers.customersCreate) ? <Link to="/customers/new" className="table-header__button">NEW</Link> : null}</span>
        </div>
        <Query query={Queries.customers.listWithWarehouseData} variables={{ page, order }} fetchPolicy="network-only" onCompleted={this.onQueryComplete}>
          {({ loading, error, data }) => {
            if (loading) return <Spinner absolute />;
            if (error) return <Error error={error} lang={lang} />;
            if (!data) return null;

            return (
              <div>
                {
                  data.customers.results.map((c) => {
                    return (
                      <Link to={`/customers/${c.id}`} key={c.id} className="small-12 cell table-row">
                        <div style={{ flex: '2' }} className={styles['left-section']}>
                          <div className="circled-icon circled-icon__outline circled-icon__customer"><span className="icon-people white"><span className="path1" /><span className="path2" /><span className="path3" /></span></div>
                          <div className={styles['name-containter']}>
                            <span className="table-title">{c.name}</span>
                            <span className="table-subtitle hide">{`${c.address}, ${c.city}`}</span>
                          </div>
                        </div>
                        <span style={{ flex: '1' }} className="table-detail table-detail--huge table-detail__warehouse show-for-large"><span className="icon-business hide-for-large"><span className="path1" /><span className="path2" /></span>{c.warehousesCount}</span>
                        <span style={{ flex: '1' }} className="table-detail table-detail--huge table-detail__gate show-for-large"><span className="icon-business hide-for-large"><span className="path1" /><span className="path2" /></span>{c.gatesCount}</span>
                        <span style={{ flex: '1' }} className="table-detail table-detail--huge table-detail__ok show-for-large"><span className="icon-bollard hide-for-large"><span className="path1" /><span className="path2" /></span>{c.sensorsCount}</span>
                        <span style={{ flex: '1' }} className="table-detail table-detail--huge table-detail__in-maintenance show-for-large"><span className="icon-build hide-for-large"><span className="path1" /><span className="path2" /></span>{c.maintenanceSensorsCount}</span>
                        <span style={{ flex: '1' }} className="table-detail table-detail--huge table-detail__damaged show-for-large"><span className="icon-error hide-for-large"><span className="path1" /><span className="path2" /></span>{c.damagedSensorsCount}</span>
                        <span style={{ flex: '0.5' }} className="table-detail show-for-large" />
                      </Link>
                    );
                  })
                }
                <Pager pages={data.customers.totalPages} current={page} onPage={this.props.onPage} />
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default Customers;
