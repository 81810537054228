import _ from 'lodash';
import moment from 'moment';

import constants from '../globals/constants';

type CallbackType = ?(state: any, action?: any) => any;

export type Reducer = {
  types: string | [string, string, string];
  stateKey?: string;
  baseState?: [?any, ?any, ?any];
  data?: [?CallbackType | boolean, ?CallbackType | boolean, ?CallbackType | boolean];
  callbacks?: [CallbackType, CallbackType, CallbackType];
}

export const parseErrorMessage = (error: any) => {
  let msg = 'ERROR';
  if (typeof error === 'string') msg = error;
  else if (_.isArray(error)) msg = error.join(', ');
  else if (_.isError(error)) {
    msg = error.message;
    if (error.response && typeof error.response === 'string') {
      msg = msg.concat(', ').concat(error.response);
    } else if (error.response && typeof error.response === 'object' && error.response.errors && _.isArray(error.response.errors)) {
      msg = msg.concat(', ').concat(error.response.errors.join(', '));
    }
  }

  return msg;
};

export const getApiEndpoint = (endpoint : string) => {
  return constants.api[endpoint].replace(':endpoint', window.configs.ENDPOINT);
};

export const formatElapsedTime = (elapsedTime: number, mu: string = 'seconds') => {
  const d = moment.duration(elapsedTime, mu);
  return `${d.days()}gg ${d.hours()}h ${d.minutes()}m ${d.seconds()}s`;
};

export const getSensorStatus = (sensor: any, initialStatus: any) => {
  let status = initialStatus;

  if (!sensor || !sensor.moteUid) {
    status = 'blank';
  } else if (sensor.tilt !== undefined) {
    if (sensor.tilt > 15) {
      status = 'damaged';
    } else if (sensor.tilt <= 15 && sensor.tilt > 7) {
      status = 'in-maintenance';
    } else {
      status = 'ok';
    }
  }

  return status;
};
