import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Select from 'react-select';
import omitDeep from 'omit-deep-lodash';
import { confirmAlert } from 'react-confirm-alert';

import queries, { locationData } from '../../state/queries';

import MutationError from '../common/errors/MutationError';
import Spinner from '../common/spinner/Spinner';

import styles from './Sensor.scss';
import NumericInput from '../common/form/NumericInput';

type Props = {
  type: string,
  values: any,
  devices: Array<any>,
  warehouseId: string,
  hasScale: boolean,
  sectionId: string,
  onCreatedSensor: Function,
  onClose: Function,
  onDeleted: Function,
  onTextChange: Function,
  onNumberChange: Function,
  onClearPosition: Function,
  onDeviceSelect: Function,
  onTypeChange: Function,
  onSetScale: Function
}

type State = {
  errors: any
}

const createBollardSensor = gql`
  mutation createBollardSensor($serial: String!, $x: Float!, $y: Float!, $height: Int, $diameter: Int, $section: WarehouseSectionRelationAttributes!) {
    createBollardSensor(attributes: { serial: $serial, x: $x, y: $y, height: $height, diameter: $diameter, section: $section }) {
      location {
        ${locationData}
      }
    }
  }
`;

const updateBollardSensor = gql`
  mutation updateBollardSensor($id: ID!, $serial: String!, $x: Float!, $y: Float!, $height: Int, $diameter: Int, $section: WarehouseSectionRelationAttributes!, $device: DeviceRelationAttributes) {
    updateBollardSensor(id: $id, attributes: { serial: $serial, x: $x, y: $y, height: $height, diameter: $diameter, section: $section }, device: $device) {
      location {
        ${locationData}
      }
    }
  }
`;

const createGateSensor = gql`
  mutation createGateSensor($serial: String!, $x: Float!, $y: Float!, $length: Int!, $section: WarehouseSectionRelationAttributes!) {
    createGateSensor(attributes: { serial: $serial, x: $x, y: $y, length: $length, section: $section }) {
      location {
        ${locationData}
      }
    }
  }
`;

const updateGateSensor = gql`
  mutation updateGateSensor($id: ID!, $serial: String!, $x: Float!, $y: Float!, $length: Int!, $section: WarehouseSectionRelationAttributes!, $device: DeviceRelationAttributes) {
    updateGateSensor(id: $id, attributes: { serial: $serial, x: $x, y: $y, length: $length, section: $section }, device: $device) {
      location {
        ${locationData}
      }
    }
  }
`;

const createAmeSensor = gql`
  mutation createAmeSensor($serial: String!, $x: Float!, $y: Float!, $areaRadius: Float!, $section: WarehouseSectionRelationAttributes!) {
    createAmeSensor(attributes: { serial: $serial, x: $x, y: $y, areaRadius: $areaRadius, section: $section }) {
      location {
        ${locationData}
      }
    }
  }
`;

const updateAmeSensor = gql`
  mutation updateAmeSensor($id: ID!, $serial: String!, $x: Float!, $y: Float!, $areaRadius: Float!, $section: WarehouseSectionRelationAttributes!, $device: DeviceRelationAttributes) {
    updateAmeSensor(id: $id, attributes: { serial: $serial, x: $x, y: $y, areaRadius: $areaRadius, section: $section }, device: $device) {
      location {
        ${locationData}
      }
    }
  }
`;

const sensorsDeleteMutation = gql`
  mutation deleteSensor($id: ID!) {
    deleteSensor(id: $id) { id }
  }
`;

class SensorForm extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    push: PropTypes.func
  }

  state = {
    errors: {}
  }

  onClick = (action: Function, e: SyntheticMouseEvent<*> | SyntheticTouchEvent<*>) => {
    e.preventDefault();
    const variables = omitDeep({ ...this.props.values }, '__typename');

    let varToValidate = [];
    switch (this.props.type) {
      case 'gate':
        varToValidate = ['serial', 'length'];
        break;
      case 'ame':
        varToValidate = ['serial', 'areaRadius'];
        break;
      default:
        varToValidate = ['serial', 'height', 'diameter'];
        break;
    }

    const errors = this.validate(variables, varToValidate);
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    variables.section = { id: this.props.sectionId };

    action({ variables });
  }

  onSensorDelete = (action: Function) => {
    confirmAlert({
      title: this.context.lang('sensors', 'delete-confirm-title').toString(),
      message: this.context.lang('sensors', 'delete-confirm-lite').toString(),
      buttons: [
        {
          label: this.context.lang('global', 'yes').toString(),
          onClick: () => {
            confirmAlert({
              title: this.context.lang('sensors', 'delete-confirm-title').toString(),
              message: this.context.lang('sensors', 'delete-confirm').toString(),
              buttons: [
                {
                  label: this.context.lang('global', 'yes').toString(),
                  onClick: () => action({ variables: { id: (this.props.values || {}).id } })
                },
                { label: this.context.lang('global', 'no').toString() }
              ]
            });
          }
        },
        { label: this.context.lang('global', 'no').toString() }
      ]
    });
  }

  validate = (values: any, varToValidate: string[]) => {
    const errors = {};
    varToValidate.forEach((f) => {
      if (!values[f] || `${values[f]}`.trim().length === 0) {
        errors[f] = 'empty';
      }
    });

    if (values.x === undefined || values.y === undefined) {
      errors.position = 'empty';
    }
    return errors;
  }

  render() {
    const { errors } = this.state;
    const { lang } = this.context;
    const { values } = this.props;

    const options = this.props.devices.map((d) => { return { label: d.serial, value: d.id }; });

    const types = [{ label: lang('sensors', 'types', 'bollard').s, value: 'bollard' }, { label: lang('sensors', 'types', 'gate').s, value: 'gate' }, { label: lang('sensors', 'types', 'ame').s, value: 'ame' }];
    const selected = types.filter(t => t.value === this.props.type)[0] || types[0];

    let sensorMutation = values.id ? updateBollardSensor : createBollardSensor;
    if (this.props.type === 'gate') {
      sensorMutation = values.id ? updateGateSensor : createGateSensor;
    }
    if (this.props.type === 'ame') {
      sensorMutation = values.id ? updateAmeSensor : createAmeSensor;
    }

    return (
      <form className="grid-x page cool-enter" style={{ position: 'relative' }}>
        <div className="form-title__inner cell small-12">
          { lang('sensors', values.id ? 'edit' : 'create').s }
        </div>
        <label htmlFor="type" className="cell small-12">
          <div>{lang('sensors', 'type').s}</div>
          <Select classNamePrefix="Select" options={types} value={selected} onChange={this.props.onTypeChange} clearable={false} />
        </label>
        {
          selected.value === 'ame' && !this.props.hasScale
            ? (
              <div className={styles['missing-scale']}>
                {lang('sections', 'missing-scale').s}
                <button type="button" className="table-header__button" onClick={this.props.onSetScale}>{lang('sections', 'scale-set').s}</button>
              </div>
            )
            : (
              <span style={{ width: '100%' }}>
                <label htmlFor="serial" className="cell small-12">
                  <div>{ lang('sensors', 'serial').s }</div>
                  <input className={errors.serial ? 'error' : ''} type="text" name="serial" value={values.serial || ''} onChange={this.props.onTextChange.bind(null, 'serial')} />
                </label>
                <label htmlFor="height" className={`cell small-12 ${this.props.type === 'bollard' ? '' : 'hide'}`}>
                  <div>{`${lang('sensors', 'height').s} (mm)`}</div>
                  <NumericInput className={errors.height ? 'error' : ''} type="number" name="height" value={values.height || ''} onChange={this.props.onNumberChange.bind(null, 'height')} />
                </label>
                <label htmlFor="diameter" className={`cell small-12 ${this.props.type === 'bollard' ? '' : 'hide'}`}>
                  <div>{`${lang('sensors', 'diameter').s} (mm)`}</div>
                  <NumericInput className={errors.diameter ? 'error' : ''} type="number" name="diameter" value={values.diameter || ''} onChange={this.props.onNumberChange.bind(null, 'diameter')} />
                </label>
                <label htmlFor="length" className={`cell small-12 ${this.props.type === 'gate' ? '' : 'hide'}`}>
                  <div>{`${lang('sensors', 'length').s} (mm)`}</div>
                  <NumericInput className={errors.length ? 'error' : ''} type="number" name="length" value={values.length || ''} onChange={this.props.onNumberChange.bind(null, 'length')} />
                </label>
                <label htmlFor="length" className={`cell small-12 ${this.props.type === 'ame' ? '' : 'hide'}`}>
                  <div>{`${lang('sensors', 'areaRadius').s} (cm)`}</div>
                  <NumericInput className={errors.length ? 'error' : ''} type="number" name="areaRadius" value={values.areaRadius || ''} onChange={this.props.onNumberChange.bind(null, 'areaRadius')} />
                </label>
                <label htmlFor="position" className="cell small-12">
                  <div>{ lang('sensors', 'position').s }</div>
                  <div className="fake-input">
                    {
                      values.x && values.y ? (
                        <div className={`${styles.message} ${styles.message__inline}`}>
                          <span className="icon-check-circle-outline" />
                          { lang('sensors', 'position-confirmed').toString() }
                        </div>
                      ) : (
                        <div className={`${styles.message} ${styles.message__inline} ${styles.message__error}`}>
                          <span className="icon-location-gps-off" />
                          { lang('sensors', 'add-position').toString() }
                        </div>
                      )
                    }
                    { values.x && values.y ? <span className="icon-close hide-for-large" onClick={this.props.onClearPosition} style={{ position: 'absolute', right: '.8rem', top: '.8rem', fontSize: '1.2rem' }} /> : null }
                  </div>
                </label>
                { values.device ? (
                  <label htmlFor="collector" className="cell small-12">
                    <div>{lang('sensors', 'collector').s}</div>
                    <Select classNamePrefix="Select" options={options} value={options.filter(e => e.value === values.device.id)} onChange={this.props.onDeviceSelect} clearable={false} />
                  </label>
                ) : null }
                <Mutation mutation={sensorMutation} refetchQueries={[{ query: queries.sections.detail, variables: { warehouseId: this.props.warehouseId, sectionId: this.props.sectionId } }]} onCompleted={values.id ? this.props.onClose : this.props.onCreatedSensor}>
                  {(action, { loading, error }) => {
                    return (
                      <div className="grid-x small-12 cell centered" style={{ marginTop: '2rem' }}>
                        {loading ? <Spinner /> : <input className="outline-button" type="submit" onClick={this.onClick.bind(null, action)} value={values.id ? 'Update' : 'Create'} />}
                        <MutationError graphQLError={error} errors={this.state.errors} lang={lang} langKey="sensors" />
                      </div>
                    );
                  }}
                </Mutation>
                { values.id
                  ? (
                    <div className="grid-x small-12 cell centered" style={{ marginTop: '.5rem' }}>
                      <Mutation mutation={sensorsDeleteMutation} refetchQueries={[{ query: queries.sections.detail, variables: { warehouseId: this.props.warehouseId, sectionId: this.props.sectionId } }]} onCompleted={this.props.onDeleted}>
                        {(deleteAction, result) => {
                          return result.loading ? <Spinner /> : <div className="small-12 cell" style={{ display: 'flex', justifyContent: 'center' }}><div className="action-button__subtle" onClick={this.onSensorDelete.bind(null, deleteAction)}>{lang('sensors', 'delete').s}</div></div>;
                        }}
                      </Mutation>
                    </div>
                  ) : null
                }
                <div className="circled-icon circled-icon__button circled-icon__floating" onClick={this.props.onClose}>
                  <span className="icon-close" />
                </div>
              </span>
            )
        }
      </form>
    );
  }
}

export default SensorForm;
