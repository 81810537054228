import React, { Component } from 'react';

export interface IDragData {
  x: number;
  y: number;
  dx: number;
  dy: number;
  scale: number;
  delta: number;
}

export interface IReactPanZoomStateType {
  dragging: boolean;
  scaling: boolean;
  dragData: IDragData;
  matrixData: number[];
}

export interface IReactPanZoomProps {
  height: number;
  width: number;
  className?: string;
  enablePan?: boolean;
  zoom?: number;
  pandx?: number;
  pandy?: number;
  onPan?: (x: number, y: number) => void;
  children?: any;
  onZoom?: (newScale: number) => void;
  selectedItem: any;
  centerMapOnClick?: boolean;
}

class ReactPanZoom extends Component<IReactPanZoomProps, IReactPanZoomStateType> {
  static defaultProps = {
    enablePan: true,
    onPan: () => undefined,
    pandx: 0,
    pandy: 0,
    zoom: 1,
    selectedItem: {},
    centerMapOnClick: true
  };

  getInitialState = () => {
    const { pandx, pandy, zoom } = this.props;
    const defaultDragData = {
      dx: pandx || 0,
      dy: pandy || 0,
      x: 0,
      y: 0,
      scale: 1,
      delta: 0
    };
    return {
      dragData: defaultDragData,
      dragging: false,
      scaling: false,
      matrixData: [
        zoom || 0, 0, 0, zoom || 0, pandx || 0, pandy || 0, // [zoom, skew, skew, zoom, dx, dy]
      ],
    };
  };

  state = this.getInitialState();

  componentDidUpdate(prevProps: any) {
    if (this.props.centerMapOnClick && this.props.selectedItem.id && prevProps.selectedItem.id !== this.props.selectedItem.id) {
      this.centerMap(this.props.selectedItem.x, this.props.selectedItem.y);
    }
  }

  centerMap = (x: number, y: number) => {
    if (this.panContainer.parentElement) {
      const { matrixData } = this.state;
      matrixData[4] = (this.panContainer.parentElement.getBoundingClientRect().width / 2) - x * this.props.width * this.state.matrixData[0];
      matrixData[5] = (this.panContainer.parentElement.getBoundingClientRect().height / 2) - y * this.props.height * this.state.matrixData[0];
      this.setState({
        matrixData,
      });
      if (this.props.onPan) {
        this.props.onPan(matrixData[4] || 0, matrixData[5] || 0);
      }
    }
  }

  onMouseDown = (e: any) => {
    if (!this.props.enablePan || e.nativeEvent.which === 3) {
      return;
    }
    const { matrixData } = this.state;
    const offsetX = matrixData[4];
    const offsetY = matrixData[5];
    const newDragData: IDragData = {
      dx: offsetX || 0,
      dy: offsetY || 0,
      x: e.pageX,
      y: e.pageY,
      scale: matrixData[0],
      delta: 0
    };
    this.setState({
      dragData: newDragData,
      dragging: true,
      scaling: false
    });
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();

    return false;
  };

  onMouseUp = (e: any) => {
    const { pageX, pageY } = e;
    this.setState((prevState) => {
      return {
        scaling: false,
        dragging: !(pageX === prevState.dragData.x && pageY === prevState.dragData.y) && prevState.dragging
      };
    });
    if (this.props.onPan) {
      this.props.onPan(this.state.matrixData[4] || 0, this.state.matrixData[5] || 0);
    }
  };

  onMouseMove = (e: any) => {
    if (this.state.dragging) {
      const matrixData = this.getNewMatrixData(e.pageX, e.pageY);
      this.setState({
        matrixData,
        dragging: true,
        scaling: false
      });
      if (this.panContainer) {
        this.panContainer.style.transform = `matrix(${this.state.matrixData.toString()})`;
      }
    }
  };

  onMouseEnter = () => {
    if (this.state.dragging) {
      this.setState({
        dragging: false,
        scaling: false
      });
    }
  }

  onMouseLeave = () => {
    if (this.state.dragging) {
      this.setState({
        dragging: false,
        scaling: false
      });
      if (this.props.onPan) {
        this.props.onPan(this.state.matrixData[4] || 0, this.state.matrixData[5] || 0);
      }
    }
  };

  onMapClick = (e: any) => {
    if (this.state.dragging) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      e.preventDefault();
    }
    this.setState({
      dragging: false
    });
  }

  onTouchStart = (e: any) => {
    if (!this.props.enablePan) {
      return;
    }
    const { matrixData } = this.state;
    const offsetX = matrixData[4];
    const offsetY = matrixData[5];

    const startX = e.touches.length === 1 ? e.touches[0].pageX : (e.touches[0].pageX + e.touches[1].pageX) / 2;
    const startY = e.touches.length === 1 ? e.touches[0].pageY : (e.touches[0].pageY + e.touches[1].pageY) / 2;

    let delta = 0;
    if (e.touches.length > 1) {
      const deltaX = (e.touches[0].pageX - e.touches[1].pageX) / 2;
      const deltaY = (e.touches[0].pageY - e.touches[1].pageY) / 2;
      delta = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    }

    const newDragData: IDragData = {
      dx: offsetX || 0,
      dy: offsetY || 0,
      x: startX,
      y: startY,
      scale: matrixData[0],
      delta
    };
    this.setState({
      dragData: newDragData,
      dragging: false,
      scaling: false
    });

    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
  }

  onTouchMove = (e: any) => {
    if (e.touches.length === 1 && !this.state.scaling) {
      if (e.touches[0].pageX === this.state.dragData.x && e.touches[0].pageY === this.state.dragData.y) {
        return;
      }
      const matrixData = this.getNewMatrixData(e.touches[0].pageX, e.touches[0].pageY);
      this.setState({
        matrixData,
        dragging: true
      });
      if (this.panContainer) {
        this.panContainer.style.transform = `matrix(${this.state.matrixData.toString()})`;
      }
    } else if (e.touches.length > 1 && !this.state.dragging) {
      const { scale, delta } = this.state.dragData;

      const newDeltaX = (e.touches[0].pageX - e.touches[1].pageX) / 2;
      const newDeltaY = (e.touches[0].pageY - e.touches[1].pageY) / 2;
      const newDelta = Math.sqrt(newDeltaX * newDeltaX + newDeltaY * newDeltaY);
      let newScale = newDelta * scale / delta;
      if (newScale > 2) {
        newScale = 2;
      } else if (newScale < 0.4) {
        newScale = 0.4;
      }

      const rect = this.panContainer.getBoundingClientRect();
      const startX = ((e.touches[0].clientX + e.touches[1].clientX) / 2) - rect.left;
      const startY = ((e.touches[0].clientY + e.touches[1].clientY) / 2) - rect.top;

      this.setState((prevState) => {
        const newMatrixData = [...prevState.matrixData];
        const originx = startX / prevState.matrixData[0] * newScale - startX;
        const originy = startY / prevState.matrixData[0] * newScale - startY;

        newMatrixData[0] = newScale || newMatrixData[0];
        newMatrixData[3] = newScale || newMatrixData[3];
        newMatrixData[4] = newMatrixData[4] - originx || newMatrixData[4];
        newMatrixData[5] = newMatrixData[5] - originy || newMatrixData[5];
        return {
          matrixData: newMatrixData,
          scaling: true
        };
      });

      if (this.props.onZoom) {
        this.props.onZoom(newScale);
      }
    }
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
  }

  onTouchEnd = (e: any) => {
    if (!this.state.scaling && !this.state.dragging) {
      this.eventFire(e.target, 'click', e.nativeEvent.changedTouches[0].clientX, e.nativeEvent.changedTouches[0].clientY);
    } else if (this.state.dragging && !this.state.scaling) {
      if (this.props.onPan) {
        this.props.onPan(this.state.matrixData[4] || 0, this.state.matrixData[5] || 0);
      }
    }
  }

  getNewMatrixData = (x: number, y: number): number[] => {
    const { dragData, matrixData } = this.state;
    const deltaX = dragData.x - x;
    const deltaY = dragData.y - y;
    matrixData[4] = dragData.dx - deltaX || 0;
    matrixData[5] = dragData.dy - deltaY || 0;
    return matrixData;
  };

  onZoom = (event: any) => {
    event.preventDefault();
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();

    const { deltaY, clientX, clientY } = event;
    const { matrixData } = this.state;

    // Get mouse offset.
    const rect = this.panContainer.getBoundingClientRect();
    const mousex = clientX - rect.left;
    const mousey = clientY - rect.top;

    const scaleOffset = (deltaY > 0 ? -0.05 : 0.05);
    let newScale = matrixData[0] + scaleOffset;
    if (newScale > 1.8) {
      newScale = 1.8;
    } else if (newScale < 0.5) {
      newScale = 0.5;
    }

    const originx = mousex / matrixData[0] * newScale - mousex;
    const originy = mousey / matrixData[0] * newScale - mousey;

    this.setState((prevState) => {
      const newMatrixData = [...prevState.matrixData];
      newMatrixData[0] = newScale || newMatrixData[0];
      newMatrixData[3] = newScale || newMatrixData[3];
      newMatrixData[4] = newMatrixData[4] - originx || newMatrixData[4];
      newMatrixData[5] = newMatrixData[5] - originy || newMatrixData[5];
      return {
        matrixData: newMatrixData,
      };
    });

    if (this.props.onZoom) {
      this.props.onZoom(newScale);
    }
  }

  eventFire = (el: any, etype: any, clientX: number, clientY: number) => {
    const evObj = new MouseEvent('click', { clientX, clientY });
    evObj.initEvent(etype, true, false);
    el.dispatchEvent(evObj);
  }

  // Used to set transform for pan.
  panContainer: any;

  render() {
    return (
      <div
        className={`pan-container ${this.props.className || ''}`}
        onMouseDown={this.onMouseDown}
        onContextMenu={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onMouseMove={this.onMouseMove}
        onTouchStart={this.onTouchStart}
        onTouchEnd={this.onTouchEnd}
        onTouchMove={this.onTouchMove}
        onMouseLeave={this.onMouseLeave}
        onMouseEnter={this.onMouseEnter}
        onBlur={this.onMouseLeave}
        onWheel={this.onZoom}
        onClickCapture={this.onMapClick}
        ref={(ref) => { this.panContainer = ref; }}
        style={{
          height: this.props.height,
          userSelect: 'none',
          width: this.props.width,
          transformOrigin: 'left top',
          transform: `matrix(${this.state.matrixData.toString()})`,
          overscrollBehavior: 'contain'
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default ReactPanZoom;
