import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import mobile from 'is-mobile';
import Select from 'react-select';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import Dropzone from 'react-dropzone';

import Spinner from '../common/spinner/Spinner';
import Error from '../common/errors/Error';
import WarehouseForm from './WarehouseForm';
import Sections from '../sections/Sections';
import SectionForm from '../sections/SectionForm';
import Devices from '../devices/Devices';
import DeviceForm from '../devices/DeviceForm';
import DeviceDetail from '../devices/DeviceDetail';

import Map from '../common/map/Map';

import styles from './Warehouse.scss';
import { Permissions } from '../../state/authorization';
import queries from '../../state/queries';
import Forklifts from '../forklifts/Forklifts';
import ForkliftForm from '../forklifts/ForkliftForm';
import ForkliftDetail from '../devices/ForkliftDetail';

const sendSettings = gql`
  mutation updateWarehouseSettings($id: ID!, $settings: String!) {
    updateWarehouseSettings(id: $id, settings: $settings) {
      warehouse { id }
    }
  }
`;

const sendFirmware = gql`
  mutation updateWarehouseFirmware($id: ID!, $file: Upload!) {
    updateWarehouseFirmware(id: $id, file: $file) {
      warehouse { id }
    }
  }
`;

type Props = {
  warehouseId: string,
  customerId?: string,
  onCompleted: Function,
  editing: bool,
  result: any
}

type State = {
  file: any,
  values: any,
  editingSection: boolean,
  editingDevice: boolean,
  editingForklift: boolean,
  editingSettings: boolean,
  hover: string,
  wmap: any,
  selectedTab: string,
  settings: any,
  showListOnMobile: boolean,
  centerMapOnClick: boolean,
  settingsIndex: number
}

class Warehouse extends Component<Props, State> {
  static defaultProps = {
    customerId: null
  }

  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    push: PropTypes.func,
    replace: PropTypes.func,
    can: PropTypes.func
  }

  state = {
    file: null,
    editing: this.props.editing,
    editingSection: false,
    editingDevice: false,
    editingForklift: false,
    editingSettings: false,
    values: {},
    hover: '',
    wmap: {
      x: 0.5,
      y: 0.5,
      scale: 1,
      width: 0,
      height: 0
    },
    selectedTab: 'sections',
    settings: { txInterval: 0, tiltThreshold: 0 },
    showListOnMobile: false,
    centerMapOnClick: true,
    settingsIndex: 0
  }

  targetElement = null;

  componentWillUpdate(next: any) {
    if (next.result.data && next.result.data.warehouse && !this.props.result.data.warehouse) {
      try {
        const settings = JSON.parse(next.result.data.warehouse.settings);
        this.setState({ settings }); // eslint-disable-line
      } catch (e) {
        console.log(e); // eslint-disable-line
      }
    }
  }

  onTextChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState(prevState => ({ values: { ...prevState.values, [field]: value } }));
  }

  onDrop = (acceptedFiles: Array<any>) => {
    this.setState(prevState => ({ values: { ...prevState.values, file: acceptedFiles[0] } }));
  }

  onFirmwareDrop = (acceptedFiles: Array<any>) => {
    this.setState({ file: acceptedFiles[0] });
  }

  onPanMove = (x: number, y: number) => {
    const mobileReadyX = mobile({ tablet: true }) ? x - ((x - this.state.wmap.x) / 1.5) : x;
    const mobileReadyY = mobile({ tablet: true }) ? y - ((y - this.state.wmap.y) / 1.5) : y;
    this.setState(prevState => ({ wmap: { ...prevState.wmap, x: mobileReadyX, y: mobileReadyY } }));
  }

  onPanAndZoom = (x: Number, y: Number, scale: Number) => {
    this.setState(prevState => ({ wmap: { ...prevState.wmap, x, y, scale } }));
  }

  onImageLoad = (width: Number, height: Number) => {
    if (!this.state.wmap.width || !this.state.wmap.height) {
      this.setState(prevState => ({ wmap: { ...prevState.wmap, width, height } }));
    }
  }

  onAddSection = () => {
    this.setState({ editingSection: true, hover: '', values: { warehouse: { id: this.props.warehouseId } }, selectedTab: 'sections' });
  }

  onAddDevice = () => {
    this.setState({ editingDevice: true, hover: '', values: { warehouse: { id: this.props.warehouseId } }, selectedTab: 'devices' });
  }

  onAddForklift = () => {
    this.setState({ editingForklift: true, hover: '', values: { warehouse: { id: this.props.warehouseId } }, selectedTab: 'forklifts' });
  }

  onEditSection = (values: any) => {
    this.setState({ editingSection: true, editingForklift: false, editingDevice: false, hover: '', values, selectedTab: 'sections' });
  }

  onEditDevice = (values: any) => {
    this.setState({ editingDevice: true, editingSection: false, editingForklift: false, hover: '', values, selectedTab: 'devices' });
  }

  onEditForklift = (values: any) => {
    this.setState({ editingForklift: true, editingSection: false, editingSettings: false, hover: '', values, selectedTab: 'forklifts' });
  }

  onDeviceSelect = (values: any, centerMapOnClick: boolean) => {
    this.setState({ editingDevice: false, hover: '', values, selectedTab: 'devices', editingSection: false, editingForklift: false, showListOnMobile: false, centerMapOnClick });
  }

  onForkliftSelect = (values: any, centerMapOnClick: boolean) => {
    this.setState({ editingForklift: false, hover: '', values, selectedTab: 'forklifts', editingSection: false, editingDevice: false, showListOnMobile: false, centerMapOnClick });
  }

  onMapClick = (x: Number, y: Number) => {
    if (this.state.editingSection || this.state.editingDevice || this.state.editingForklift) {
      this.setState(prevState => ({ values: { ...prevState.values, x, y }, centerMapOnClick: false }));
    } else if (this.state.values.id) {
      this.clearForm();
    }
  }

  onHoverItem = (id: string) => {
    this.setState({ hover: id });
  }

  onClearFile = () => {
    this.setState(prevState => ({ values: { ...prevState.values, file: undefined } }));
  }

  onClearPosition = () => {
    this.setState(prevState => ({ values: { ...prevState.values, x: undefined, y: undefined } }));
  }

  onSelectTab = (tab: string) => {
    this.setState({ selectedTab: tab, editingSection: false, editingDevice: false, editingForklift: false, values: {} });
  }

  onSendSettings = (action: Function) => {
    if (this.state.settings) {
      const { data } = this.props.result;
      const { warehouse } = data;
      const { id } = warehouse;
      const { settings } = this.state;

      action({ variables: { id, settings: JSON.stringify(settings) } });
    }
  }

  onSendSettingsCompleted = () => {
    this.setState({ editingSettings: false });
  }

  onSendFirmware = (action: Function) => {
    if (this.state.file) {
      const { data } = this.props.result;
      const { warehouse } = data;
      const { id } = warehouse;

      action({ variables: { id, file: this.state.file } });
    }
  }

  onSendFirmwareCompleted = () => {
    this.setState({ file: null });
  }

  onToggleSettings = () => {
    const { can } = this.context;

    if (can(Permissions.superadmin.can_all)) {
      this.setState(prevState => ({ editingSettings: !prevState.editingSettings }));
    }
  }

  onSettingChange = (field: string, code: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;

    this.setState((prevState) => {
      return {
        settings: {
          ...prevState.settings,
          [code]: {
            ...prevState.settings[code],
            [field]: parseFloat(value)
          }
        }
      };
    });
  }

  onSettingSelectChange = (field: string, code: string, result: { label: string, value: string}) => {
    this.setState((prevState) => {
      return {
        settings: {
          ...prevState.settings,
          [code]: {
            ...prevState.settings[code],
            [field]: result.value
          }
        }
      };
    });
  }

  clearForm = () => {
    this.setState({ editingSection: false, editingDevice: false, editingForklift: false, values: {} });
  }

  getSettingsForm = () => {
    const { file } = this.state;
    const sortedSettings = this.props.result.data.productSettings.sort((a, b) => a.kind > b.kind);
    const product = sortedSettings[this.state.settingsIndex];

    return (
      <div style={{ height: '100%' }}>
        <div>
          <Dropzone onDrop={this.onFirmwareDrop} disabled={file !== null} multiple={false} activeClassName="active-dropzone" className="dropzone" style={{ borderRadius: 0 }}>
            {
              file ? (
                <span className={styles.firmware}>
                  <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                    {file.name}
                  </div>
                  <Mutation mutation={sendFirmware} onCompleted={this.onSendFirmwareCompleted}>
                    {(action, { loading }) => {
                      return (
                        loading ? <Spinner /> : <div className={styles['settings-button']} onClick={this.onSendFirmware.bind(null, action)}>Send</div>
                      );
                    }}
                  </Mutation>
                </span>
              ) : (
                <div className={styles.message} style={{ cursor: 'pointer' }}>
                  Upload new firmare
                </div>
              )
            }
          </Dropzone>
        </div>
        <div className={styles['settings-buttons-comtainer']}>
          <Mutation mutation={sendSettings} onCompleted={this.onSendSettingsCompleted}>
            {(action, { loading }) => {
              return (
                loading ? <Spinner /> : <div className={styles['settings-button']} onClick={this.onSendSettings.bind(null, action)}>Save</div>
              );
            }}
          </Mutation>
        </div>
        <div className={styles['top-bar']}>
          { sortedSettings.map((p, index) => {
            return (
              <span key={p.kind} className={this.state.settingsIndex === index ? styles['top-bar-selected'] : ''} onClick={() => this.setState({ settingsIndex: index })}><img src={require(`../../images/setting-${p.kind}.svg`)} alt={p.kind} /><figcaption>{p.kind}</figcaption></span>
            );
          })}
        </div>
        <form className="grid-x page cool-enter" style={{ position: 'relative' }}>
          {
            product.settings.map((s) => {
              const txPowerValues = [
                { label: '-31.7 dBm', value: 0x11 },
                { label: '-34.2 dBm', value: 0x30 },
                { label: '-27.7 dBm', value: 0x5 },
                { label: '-24.8 dBm', value: 0x21 },
                { label: '-22.3 dBm', value: 0xB },
                { label: '-19.5 dBm', value: 0x18 },
                { label: '-16.5 dBm', value: 0x23 },
                { label: '-13.3 dBm', value: 0x33 },
                { label: '-9.8 dBm', value: 0x27 },
                { label: '-8.1 dBm', value: 0x36 },
                { label: '-6.0 dBm', value: 0x2C },
                { label: '-4.0 dBm', value: 0x3B },
                { label: '-1.8 dBm', value: 0x62 },
                { label: '-0.3 dBm', value: 0x50 },
                { label: '+2.1 dBm', value: 0x8A },
              ];
              return (
                <label htmlFor={s} className="cell small-12" key={`settings-${s}`}>
                  <div>{s}</div>
                  {
                    s === 'working_tx_power'
                      ? <Select classNamePrefix="Select" options={txPowerValues} value={this.state.settings[product.code][s] ? txPowerValues.filter(e => e.value === this.state.settings[product.code][s]) : txPowerValues[0]} onChange={this.onSettingSelectChange.bind(null, s, product.code)} clearable={false} searchable={false} />
                      : <input type="number" name={s} value={this.state.settings[product.code][s] || 0} step={s === 'tiltThreshold' ? '0.1' : '1'} onChange={this.onSettingChange.bind(null, s, product.code)} />
                  }

                </label>
              );
            })
          }
        </form>
      </div>
    );
  }

  toggleListView = (showListOnMobile: boolean) => {
    this.setState({ showListOnMobile });
  }

  render() {
    const { lang, dispatch, push, can } = this.context;
    const { loading, error, data } = this.props.result;

    if (loading) {
      return (
        <div className="grid-x full-page">
          <Spinner absolute />
        </div>
      );
    }

    if (error) {
      return (
        <div className="grid-x full-page">
          <Error error={error} lang={lang} />
        </div>
      );
    }

    if (data.warehouse && !this.state.editing) {
      const titleView = (
        <div className="table table__minified table-mobile__upper-section">
          <div className="table-row">
            <div className="circled-icon circled-icon__warehouse show-for-large">
              <span className="icon-business white" onClick={this.onToggleSettings}><span className="path1" /><span className="path2" /></span>
            </div>
            <div className={styles['warehouse-name-containter']}>
              <span className="table-title">
                {data.warehouse.name}
                {can(Permissions.warehouses.warehousesCreate) ? <Link to={`/warehouses/${data.warehouse.id}/edit`}><span className="icon-edit"><span className="path1" /><span className="path2" /></span></Link> : null}
              </span>
              <span className="table-subtitle">{`${data.warehouse.address || 'N.A.'}, ${data.warehouse.city || 'N.A.'}`}</span>
            </div>
            <div className="table-actions hide-for-large">
              <span className="icon-section-add" onClick={this.onAddSection}><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /></span>
              <span className="icon-collector-add" onClick={this.onAddDevice} />
            </div>
          </div>
        </div>
      );

      const tabView = (
        <div className={`table table__complementary ${this.state.showListOnMobile || ((this.state.editingSection || this.state.editingDevice || this.state.editingForklift) && (this.state.values.x && this.state.values.y)) ? '' : 'show-for-large'} table-mobile__form-section`}>
          <div className="tab-header">
            <div className={`tab-header-item tab-header-item__sections ${this.state.selectedTab === 'sections' ? 'tab-header-item__active' : ''}`} onClick={this.onSelectTab.bind(null, 'sections')}>
              <span className={`icon-section ${this.state.selectedTab === 'sections' ? 'white' : ''}`}><span className="path1" /><span className="path2" /><span className="path3" /></span>
            </div>
            <div className={`tab-header-item tab-header-item__devices ${this.state.selectedTab === 'devices' ? 'tab-header-item__active' : ''}`} onClick={this.onSelectTab.bind(null, 'devices')}>
              <span className={`icon-collector ${this.state.selectedTab === 'devices' ? 'white' : ''}`} />
            </div>
            <div className={`tab-header-item tab-header-item__forklifts ${this.state.selectedTab === 'forklifts' ? 'tab-header-item__active' : ''}`} onClick={this.onSelectTab.bind(null, 'forklifts')}>
              <span className={`icon-forklift ${this.state.selectedTab === 'forklifts' ? 'white' : ''}`} style={{ fontSize: '1.8rem' }}><span className="path1" /><span className="path2" /><span className="path3" /></span>
            </div>
          </div>
          <div className="tab-container">
            <div className={`tab-item ${this.state.selectedTab === 'sections' ? 'tab-item__active' : ''}`}>
              {
                (this.state.editingSection || !can(Permissions.warehouses.warehousesCreate)) ? null : (
                  <div className="tab-action show-for-large" onClick={this.onAddSection}>
                    <span className="icon-add_circle"><span className="path1" /><span className="path2" /></span>
                    { lang('sections', 'create').toString() }
                  </div>
                )
              }
              {
                this.state.editingSection && data.warehouse ? (
                  <SectionForm
                    values={this.state.values}
                    customerId={data.warehouse.customer.id}
                    warehouseId={this.props.warehouseId}
                    onCompleted={this.clearForm}
                    onDeleted={this.clearForm}
                    onDrop={this.onDrop}
                    onClearFile={this.onClearFile}
                    onClearPosition={this.onClearPosition}
                    onTextChange={this.onTextChange}
                  />
                ) : (
                  <Sections sections={data.warehouse.sections} onEdit={this.onEditSection} onHoverItem={this.onHoverItem} />
                )
              }
            </div>
            <div className={`tab-item ${this.state.selectedTab === 'devices' ? 'tab-item__active' : ''}`}>
              {
                (this.state.editingDevice || !can(Permissions.products.productsCreate)) ? null : (
                  <div className="tab-action show-for-large" onClick={this.onAddDevice}>
                    <span className="icon-add_circle"><span className="path1" /><span className="path2" /></span>
                    { lang('devices', 'create').toString() }
                  </div>
                )
              }
              {
                this.state.editingDevice && data.warehouse ? (
                  <DeviceForm
                    values={this.state.values}
                    customerId={data.warehouse.customer.id}
                    warehouseId={this.props.warehouseId}
                    onCompleted={this.clearForm}
                    onDeleted={this.clearForm}
                    onClearPosition={this.onClearPosition}
                    onTextChange={this.onTextChange}
                  />
                ) : (
                  <Query query={queries.devices.deviceConnectionStates} pollInterval={5000} skip={!data.warehouse.devices} variables={{ ids: data.warehouse.devices.map(d => d.id) }} fetchPolicy="network-only">
                    {(status) => {
                      return <Devices devices={data.warehouse.devices} states={status.data ? status.data.deviceConnectionStates : null} onEdit={this.onEditDevice} onHoverItem={this.onHoverItem} onDeviceSelect={this.onDeviceSelect} />;
                    }}
                  </Query>
                )
              }
            </div>
            <div className={`tab-item ${this.state.selectedTab === 'forklifts' ? 'tab-item__active' : ''}`}>
              {
                (this.state.editingForklift || !can(Permissions.products.productsCreate)) ? null : (
                  <div className="tab-action show-for-large" onClick={this.onAddForklift}>
                    <span className="icon-add_circle"><span className="path1" /><span className="path2" /></span>
                    { lang('forklifts', 'create').toString() }
                  </div>
                )
              }
              {
                this.state.editingForklift && data.warehouse ? (
                  <ForkliftForm
                    values={this.state.values}
                    customerId={data.warehouse.customer.id}
                    warehouseId={this.props.warehouseId}
                    onCompleted={this.clearForm}
                    onDeleted={this.clearForm}
                    onClearPosition={this.onClearPosition}
                    onTextChange={this.onTextChange}
                  />
                ) : (
                  <Forklifts forklifts={data.warehouse.forklifts} onEdit={this.onEditForklift} onHoverItem={this.onHoverItem} onForkliftSelect={this.onForkliftSelect} />
                )
              }
            </div>
            {
              !this.state.editingDevice && !this.state.editingSection && !this.state.editingForklift
                ? <div className="fab fab__white hide-for-large asd" onClick={this.toggleListView.bind(null, false)}><span className="icon-location"><span className="path1" /><span className="path2" /><span className="path3" /></span></div>
                : null
            }
          </div>
        </div>
      );

      const mapData = [
        {
          group: 'sections',
          icon: 'section',
          items: data.warehouse.sections,
          onSelect: s => dispatch(push(`/warehouses/${s.warehouse.id}/sections/${s.id}`)),
          onEdit: this.onEditSection
        }, {
          group: 'devices',
          icon: 'collector',
          items: data.warehouse.devices.map(d => ({ ...d, name: d.serial })),
          onSelect: this.onDeviceSelect,
          onEdit: this.onEditDevice
        }, {
          group: 'forklifts',
          icon: 'forklift',
          items: data.warehouse.forklifts.map(f => ({ ...f, name: f.name })),
          onSelect: this.onForkliftSelect,
          onEdit: this.onEditForklift
        }];

      const rightSection = (
        <div className={`small-12 large-9 cell ${styles.map} ${!this.state.showListOnMobile && !((this.state.editingSection || this.state.editingDevice || this.state.editingForklift) && (this.state.values.x && this.state.values.y)) ? '' : 'show-for-large'}`}>
          <div className="table table-mobile__bottom-section" style={{ overflow: 'hidden' }} ref={(e) => { this.targetElement = e; }}>
            {
              this.state.editingSettings
                ? this.getSettingsForm()
                : <Map file={data.warehouse.file} data={mapData} onMapClick={this.onMapClick} selectedItem={this.state.values} hoveredItem={this.state.hover} selectedGroup={this.state.selectedTab} centerMapOnClick={this.state.centerMapOnClick} />
            }
            <div className="fab fab__white hide-for-large" onClick={this.toggleListView.bind(null, true)}><span className="icon-list" /></div>
            {
              can(Permissions.superadmin.can_all)
                ? <div className="fab fab__white hide-for-large fab__right" onClick={this.onToggleSettings}><span className="icon-build"><span className="path1" /><span className="path2" /><span className="path3" /></span></div>
                : null
            }
            {
              this.state.selectedTab === 'devices' ? (
                <DeviceDetail device={this.state.values} editing={this.state.editingDevice || this.state.editingSection} onEdit={() => this.setState({ editingDevice: true })} onClose={this.clearForm} />
              ) : null
            }
            {
              this.state.selectedTab === 'forklifts' ? (
                <ForkliftDetail forklift={this.state.values} onClose={this.clearForm} />
              ) : null
            }
            <div className={`${styles['select-position-container']} ${(this.state.editingSection || this.state.editingDevice || this.state.editingForklift) && !(this.state.values.x && this.state.values.y) ? 'hide-for-large' : 'hide'}`}>
              {lang('warehouses', 'add-position').s}
            </div>
          </div>
        </div>
      );

      return (
        <div className="grid-x full-page">
          <div className="small-12 cell grid-x full-page">
            <div className="small-12 large-3 cell" style={{ maxHeight: '100%' }}>
              {titleView}
              {tabView}
            </div>
            {rightSection}
          </div>
        </div>
      );
    }

    const customerId = data.warehouse ? data.warehouse.customer.id : this.props.customerId;

    if (customerId) {
      return (
        <div className="small-12 cell">
          <WarehouseForm
            warehouse={data.warehouse}
            customerId={customerId}
            onCompleted={this.props.onCompleted.bind(null, data.warehouse ? `/warehouses/${data.warehouse.id}` : `/customers/${customerId}`)}
            onDeleted={this.props.onCompleted.bind(null, `/customers/${customerId}`)}
          />
        </div>
      );
    }
    return null;
  }
}

export default Warehouse;
