import React, { Component } from 'react';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import Spinner from '../components/common/spinner/Spinner';
import queries from '../state/queries';

type Props = {
};
type State = {
  mote: ?string
};

const resetProvisioning = gql`
  mutation resetSensorProvisioning($id: ID!) {
    resetSensorProvisioning(id: $id) { id }
  }
`;

class DoctorPage extends Component<Props, State> {
  static contextTypes = {
  }

  state = {
    mote: null
  }

  onTextChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ [field]: e.target.value });
  }

  onReset = (action: Function, id: string) => {
    action({ variables: { id } });
  }

  onResetComplete = () => {
    this.setState({ mote: null });
  }

  render() {
    const { mote } = this.state;

    return (
      <Query query={queries.sensors.moteSearch} variables={{ mote }} skip={!mote} fetchPolicy="no-cache">
        {({ loading, data }) => {
          return (
            <div className="table">
              <form className="page grid-x">
                <label htmlFor="email" className="cell small-12 medium-6">
                  <div>Mote UID search</div>
                  <input type="text" name="mote" value={mote || ''} onChange={this.onTextChange.bind(null, 'mote')} />
                </label>
              </form>
              <div className="grid-x">
                { loading ? <Spinner /> : null }
                { !loading && data && data.sensors && data.sensors.length > 0
                  ? (
                    <div className="small-10 medium-8 large-4 cell" style={{ display: 'flex', flexDirection: 'column', margin: '2rem', padding: '1rem', border: '1px solid #eee' }}>
                      <span><b>Serial: </b>{ data.sensors[0].serial }</span>
                      <span><b>Customer: </b>{ data.sensors[0].customer.name }</span>
                      <span><b>Warehouse: </b>{ data.sensors[0].warehouse.name }</span>
                      <span><b>Section: </b>{ data.sensors[0].section.name }</span>
                      <Mutation mutation={resetProvisioning} onCompleted={this.onResetComplete}>
                        {(action, result) => {
                          return result.loading ? <Spinner /> : <div className="outline-button__subtle" onClick={this.onReset.bind(null, action, data.sensors[0].id)}>Reset Provisioning</div>;
                        }}
                      </Mutation>
                    </div>
                  )
                  : null
                }
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

// flowlint-line-ignore
export default connect(mapStateToProps, (dispatch: Dispatch) => ({ dispatch }))(DoctorPage);
