import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Error = ({ error, lang }: any) => {
  if (!error) {
    return null;
  }

  const graphQLErrors = error ? error.graphQLErrors : [];
  const strings = graphQLErrors.map(e => e.message);

  return (
    <div className="error-container error-container__dark">
      <h3>{lang('global', 'error_title').s}</h3>
      <ul className="error-message">
        { strings.map((e, i) => <li key={`error-${i}`}>{e}</li>) }
      </ul>
      <Link to="/">{lang('global', 'back-home').s}</Link>
    </div>
  );
};

Error.propTypes = {
  error: PropTypes.object,
  lang: PropTypes.func.isRequired
};

Error.defaultProps = {
  error: null
};

export default Error;
