import React, { Component } from 'react';
import mobile from 'is-mobile';
import ReactPanZoom from './ReactPanZoom';

import Spinner from '../spinner/Spinner';
import styles from './Map.scss';
import { getSensorStatus } from '../../../state/utils';

type Props = {
  file: any,
  selectedGroup: string,
  data: any,
  onMapClick: Function,
  selectedItem: any,
  hoveredItem: any,
  centerMapOnClick: boolean,
  scale: {
    a: { x: number; y: number },
    b: { x: number; y: number },
    scale: number,
  },
  onScaleEdit: () => void
}

type State = {
  scale: number,
  width: number,
  height: number
}

class Map extends Component<Props, State> {
  state = {
    scale: 1,
    width: 0,
    height: 0
  };

  onMapClick = (e: any) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    this.props.onMapClick(x / (this.state.width * this.state.scale), y / (this.state.height * this.state.scale));
  }

  onImageLoaded = (e: any) => {
    const { naturalWidth, naturalHeight } = e.target;
    if (!this.state.width || !this.state.height) {
      this.setState({ width: naturalWidth, height: naturalHeight });
    }
  }

  getPointView = (s: any, index: any, element: any, selectedGroup: any, selectedItem: any, hoveredItem: any) => {
    const { width, height } = this.state;
    // const tabFactor = selectedGroup === element.group || mobile({ tablet: true }) ? 0 : -0.3 * (1 / scale);
    const selectFactor = selectedItem.id === s.id ? 0 : 0;
    const hoverFactor = (hoveredItem === s.id && selectFactor === 0) ? 0.2 : 0;

    const realScale = (1 / this.state.scale) + selectFactor + hoverFactor; // + tabFactor

    const nameScale = mobile({ tablet: true }) ? (1 / this.state.scale) : realScale;
    const status = element.group === 'sensors' ? getSensorStatus(s.sensor, '') : '';

    let hasArea = false;
    let radius = 0;
    let areaColor = '#59657f55';
    if (s.ame && s.ame.areaRadius && this.props.scale) {
      const { scale } = this.props;

      const a = scale && scale.a ? { x: (scale.a.x * width), y: (scale.a.y * height) } : { x: 0, y: 0 };
      const b = scale && scale.b ? { x: (scale.b.x * width), y: (scale.b.y * height) } : { x: 0, y: 0 };

      hasArea = true;
      const distance = Math.sqrt(((a.x - b.x) ** 2) + ((a.y - b.y) ** 2));
      radius = (s.ame.areaRadius * distance / scale.scale) * this.state.scale;

      const bollardGroup = this.props.data.filter(d => d.group === 'sensors' && d.items.length > 0);
      if (bollardGroup.length > 0) {
        const bollards = bollardGroup[0].items;
        bollards.forEach((bollard) => {
          const isInsideBubble = (((((bollard.x - s.x) * width) ** 2) + (((bollard.y - s.y) * height) ** 2)) < radius ** 2);
          if (isInsideBubble && getSensorStatus(bollard.sensor, 'blank') === 'damaged') {
            areaColor = '#ff000055';
          }
        });
      }
    }

    return (
      <div key={`{s.id}-${index}`} onClick={element.onSelect.bind(null, s, false)} style={{ opacity: (selectedItem.id === s.id) && (selectedItem.x !== s.x || selectedItem.y !== s.y) ? 0.5 : 1.0 }}>
        {
          hasArea
            ? (
              <span className={styles.area} style={{ width: `${radius * 2}px`, height: `${radius * 2}px`, top: `${(s.y * height) - radius}px`, left: `${(s.x * width) - radius}px`, transform: `scale(${realScale}, ${realScale})`, pointerEvents: 'none', backgroundColor: areaColor }} />
            ) : null
        }
        <div
          className={`${styles.point} ${styles[`${element.group}-point`]} ${status === '' ? '' : styles[`status-${status}`]} ${selectedItem.id === s.id ? styles.highlight : ''}`}
          style={{ top: `${(s.y * height) - 20}px`, left: `${(s.x * width) - 20}px`, transform: `scale(${realScale}, ${realScale})` }}
        >
          <span className={`icon-${element.icon} white`}><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /></span>
          { status === 'provisioning' ? <Spinner absolute fancy size={40} /> : null}
        </div>
        <div
          className={`${styles.name} ${styles[`${element.group}-name`]} ${status === '' ? '' : styles[`status-${status}`]}`}
          style={{ top: `${(s.y * height) + 20 * (1 + nameScale)}px`, left: `${s.x * width}px`, transform: `translate(-50%, -50%) scale(${nameScale}, ${nameScale})`, zIndex: selectedItem.id === s.id ? 100 : 5 }}
          onClick={e => e.preventDefault()}
        >
          {s.name || s.serial}
          <span className="icon-edit white" onClick={(e) => { e.preventDefault(); e.stopPropagation(); element.onEdit(s); }}><span className="path1" /><span className="path2" /></span>
        </div>
      </div>
    );
  }

  generatePointsByType = (element: any, selectedGroup: any, selectedItem: any, hoveredItem: any) => {
    return (
      element.items.map((s, index) => {
        return this.getPointView(s, index, element, selectedGroup, selectedItem, hoveredItem);
      })
    );
  }

  generateNewDotByType = (element: any, selectedGroup: any, selectedItem: any) => {
    const { width, height, scale } = this.state;
    return selectedGroup === element.group && selectedItem.x && selectedItem.y ? (
      <div
        key={`${element.group}-point__new`}
        className={`${styles.point} ${styles[`${element.group}-point`]} ${styles[`status-${status}`]} ${!selectedItem.id ? styles.point__new : ''}`}
        style={{ top: `${(selectedItem.y * height) - 20}px`, left: `${(selectedItem.x * width) - 20}px`, transform: `scale(${1 / scale}, ${1 / scale})` }}
        onClick={e => e.preventDefault()}
      >
        <span className={`icon-${element.icon} white`}><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /></span>
        { status === 'provisioning' ? <Spinner absolute fancy size={40} /> : null}
      </div>
    ) : null;
  }

  render() {
    const { file, selectedGroup, data, selectedItem, hoveredItem, centerMapOnClick, scale } = this.props;
    const { width, height } = this.state;

    const isImageLoaded = width > 0 && height > 0;

    const a = scale && scale.a ? { x: (scale.a.x * width), y: (scale.a.y * height) } : { x: 0, y: 0 };
    const b = scale && scale.b ? { x: (scale.b.x * width), y: (scale.b.y * height) } : { x: 0, y: 0 };
    let distance = 0;
    let angle = 0;
    let midPoint = { x: 0, y: 0 };
    if (scale && scale.a && scale.b) {
      distance = Math.sqrt(((a.x - b.x) ** 2) + ((a.y - b.y) ** 2));
      angle = Math.atan2(b.y - a.y, b.x - a.x) * 180 / Math.PI;
      midPoint = { x: a.x + (b.x - a.x) / 2, y: a.y + (b.y - a.y) / 2 };
    }

    return (
      <div style={{ width: '100%', height: '100%', overflow: 'hidden', backgroundColor: '#000000' }}>
        <ReactPanZoom onZoom={newScale => this.setState({ scale: newScale })} onPan={() => {}} style={{ position: 'relative', visibility: isImageLoaded ? 'visible' : 'hidden' }} selectedItem={selectedItem} width={width} height={height} centerMapOnClick={centerMapOnClick}>
          <img draggable="false" id="image-map" src={file} width={width} height={height} alt="map" onLoad={this.onImageLoaded} onClick={this.onMapClick} />
          {
            scale && scale.a
              ? (
                <div className={styles.scale} style={{ top: `${a.y - 15}px`, left: `${a.x - 10}px`, transform: `scale(${1 / this.state.scale}, ${1 / this.state.scale})` }}>
                  A
                </div>
              ) : null
          }
          {
            scale && scale.b
              ? (
                <div className={styles.scale} style={{ top: `${b.y - 15}px`, left: `${b.x - 10}px`, transform: `scale(${1 / this.state.scale}, ${1 / this.state.scale})` }}>
                  B
                </div>
              ) : null
          }
          {
            scale && scale.a && scale.b
              ? (
                <div style={{ position: 'absolute', zIndex: 4, pointerEvents: 'none', top: `${a.y - 6}px`, left: `${a.x}px`, background: '#333', border: '2px solid white', width: `${distance * this.state.scale}px`, height: '5px', transformOrigin: 'center left', transform: `rotate(${angle}deg) scale(${1 / this.state.scale}, ${1 / this.state.scale})` }} />
              ) : null
          }
          {
            scale && scale.scale && scale.a && scale.b
              ? (
                <div onClick={this.props.onScaleEdit} className={styles['scale-label']} style={{ top: `${midPoint.y - 16}px`, left: `${midPoint.x}px`, transform: `translateX(-50%) scale(${1 / this.state.scale}, ${1 / this.state.scale})` }}>
                  {scale.scale}cm
                  <span className="icon-edit"><span className="path1" /><span className="path2" /></span>
                </div>
              ) : null
          }
          {
            isImageLoaded ? data.map((element) => {
              return this.generateNewDotByType(element, selectedGroup, selectedItem);
            }) : null
          }
          {
            isImageLoaded ? data.map((element) => {
              return this.generatePointsByType(element, selectedGroup, selectedItem, hoveredItem);
            }) : null
          }
        </ReactPanZoom>
        { !isImageLoaded ? <Spinner absolute /> : null }
      </div>
    );
  }
}

export default Map;
