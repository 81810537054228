import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import gql from 'graphql-tag';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import { Query, Mutation } from 'react-apollo';
import Spinner from '../components/common/spinner/Spinner';
import queries from '../state/queries';
import MutationError from '../components/common/errors/MutationError';
import { Permissions } from '../state/authorization';

type Props = {
};
type State = {
  variables: any,
  fileError: boolean,
  showForm: boolean
};

const createFirmwareMutation = gql`
  mutation createFirmware($version: String, $notes: String, $target: FirmwareTarget!, $file: Upload!) {
    createFirmware(version: $version, notes: $notes, target: $target, file: $file) {
      firmware {
        id, version, target, notes, file, createdAt
      }
    }
  }
`;

const deleteFirmwareMutation = gql`
  mutation deleteFirmware($id: ID!) {
    deleteFirmware(id: $id) { id }
  }
`;

class DownloadsPage extends Component<Props, State> {
  static contextTypes = {
    can: PropTypes.func,
    lang: PropTypes.func
  }

  state = {
    variables: { target: 'bollard' },
    fileError: false,
    showForm: false
  }

  onFileChange = (f: any) => {
    const { name } = f[0];
    if (name.endsWith('.gz') || name.endsWith('.hex')) {
      this.setState((state) => {
        return { variables: { ...state.variables, file: f[0] }, fileError: false };
      });
    } else {
      this.setState({ fileError: true });
    }
  }

  onTextChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState((state) => {
      return { variables: { ...state.variables, [field]: value } };
    });
  }

  onSelect = (field: string, option: { label: string, value: string }) => {
    this.setState((state) => {
      return { variables: { ...state.variables, [field]: option.value } };
    });
  }

  onFirmwareUpload = (action: Function, e: SyntheticMouseEvent<*> | SyntheticTouchEvent<*>) => {
    e.preventDefault();
    action({ variables: { ...this.state.variables } });
  }

  onFirmwareDelete = (action: Function, firmwareId: string) => {
    confirmAlert({
      title: 'Delete firmware',
      message: 'Are you sure you want to delete this firmware?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => action({ variables: { id: firmwareId } })
        },
        { label: 'No' }
      ]
    });
  }

  render() {
    const { variables } = this.state;
    const { can, lang } = this.context;
    const targets = [{ label: 'S.M.A.R.T. Sensor', value: 'bollard' }, { label: 'S.M.A.R.T. Concentrator', value: 'concentrator' }];

    return (
      <div className="table">
        {
          can(Permissions.superadmin.can_all) && !this.state.showForm ? (
            <div className="action-button__subtle float-right" style={{ padding: '1rem' }} onClick={() => this.setState({ showForm: true })}>Upload new</div>
          ) : null
        }
        {
          can(Permissions.superadmin.can_all) && this.state.showForm ? (
            <form className="page grid-x">
              <label htmlFor="file" className="cell small-12">
                <div>File</div>
                <Dropzone activeClassName="active-dropzone" className="dropzone dropzone__light" style={{ height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} multiple={false} name="file" onDrop={this.onFileChange}>
                  {variables.file ? variables.file.name : 'No file' }
                </Dropzone>
                { this.state.fileError
                  ? <span className="dropzone-error">Unsupported file</span>
                  : null }
              </label>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="target" className="cell small-12 medium-6">
                <div>Type</div>
                <Select classNamePrefix="Select" name="target" options={targets} value={variables.target ? targets.filter(e => e.value === variables.target) : targets[0]} onChange={this.onSelect.bind(null, 'target')} clearable={false} searchable={false} />
              </label>
              <label htmlFor="version" className="cell small-12 medium-6">
                <div>Version</div>
                <input type="text" name="version" value={variables.version || ''} onChange={this.onTextChange.bind(null, 'version')} />
              </label>
              <label htmlFor="notes" className="cell small-12">
                <div>Notes</div>
                <textarea name="notes" value={variables.notes || ''} onChange={this.onTextChange.bind(null, 'notes')} />
              </label>
              <Mutation mutation={createFirmwareMutation} onCompleted={() => this.setState({ variables: {}, showForm: false })} refetchQueries={[{ query: queries.firmwares.index }]}>
                {(action, { loading, error }) => {
                  return (
                    <div className="grid-x small-12 cell centered" style={{ marginTop: '2rem', flexDirection: 'column' }}>
                      <MutationError graphQLError={error} errors={{}} lang={lang} langKey="customer" dark />
                      { loading ? <Spinner /> : <input className="outline-button" type="submit" onClick={this.onFirmwareUpload.bind(null, action)} value="upload" /> }
                      <div className="action-button__subtle" onClick={() => this.setState({ showForm: false })}>Cancel</div>
                    </div>
                  );
                }}
              </Mutation>
            </form>
          ) : null
        }

        <Query query={queries.firmwares.index} fetchPolicy="no-cache">
          {({ loading, data }) => {
            return (
              <div className="grid-x" style={{ marginTop: '1rem' }}>
                { loading ? <Spinner /> : null }
                { !loading && data && data.firmwares
                  ? (
                    data.firmwares.map((f) => {
                      return (
                        <div className="small-12 cell firmware" style={{ display: 'flex', margin: '2rem', padding: '1rem', justifyContent: 'space-between', alignItems: 'center' }} key={f.id}>
                          <div>
                            <div className="firmware--target">{lang('firmwares', f.target).s}</div>
                            <div className="firmware--version">Version {f.version}</div>
                            <div className="firmware--version">{f.notes}</div>
                          </div>
                          <div>
                            <a href={f.file} target="_blank" rel="noopener noreferrer"><i className="firmware--download icon-download" /></a>
                            {
                              can(Permissions.superadmin.can_all) ? (
                                <Mutation mutation={deleteFirmwareMutation} refetchQueries={[{ query: queries.firmwares.index }]}>
                                  {(action, deleteResult) => {
                                    return deleteResult.loading ? <Spinner size={20} /> : <span><i className="firmware--delete  icon-trash" onClick={this.onFirmwareDelete.bind(null, action, f.id)} /></span>;
                                  }}
                                </Mutation>
                              ) : null
                            }
                          </div>
                        </div>
                      );
                    })
                  )
                  : null
                }
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

// flowlint-line-ignore
export default connect(mapStateToProps, (dispatch: Dispatch) => ({ dispatch }))(DownloadsPage);
