import React, { Component } from 'react';
import PropTypes from 'prop-types';
// flowlint-line-ignore
import { UncontrolledReactSVGPanZoom, TOOL_AUTO } from 'react-svg-pan-zoom/build-es';
import { AutoSizer } from 'react-virtualized';

import Spinner from '../common/spinner/Spinner';

import styles from './Section.scss';

const SENSOR_SIZE = 4;
const TOUCH_ERROR = 0;

type Props = {
  file: ?string,
  sensor: ?any,
  hover: ?any,
  sensors: Array<any>,
  onMapClick: Function,
  onLocationSelection: Function,
  editing: bool
}

type State = {
  svgData: ?string,
  width: number,
  height: number
}

class SectionMap extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func
  }

  state = {
    svgData: null,
    width: 0,
    height: 0
  }

  svg = null

  targetElement = null;

  componentDidMount() {
    if (this.props.file) {
      fetch(this.props.file).then(res => res.text()).then((svgData) => {
        const matches = svgData.match(/viewBox="(.*?)"/);
        if (!matches) return;

        const viewBox = matches[1];
        const width = parseInt(viewBox.split(' ')[2], 10);
        const height = parseInt(viewBox.split(' ')[3], 10);

        this.setState({ svgData, width, height });
      });
    }
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.sensor && prevProps.sensor !== this.props.sensor) {
      if (this.props.sensor.id && !this.props.editing && this.svg) {
        this.svg.Viewer.setPointOnViewerCenter(this.props.sensor.x * this.state.width, this.props.sensor.y * this.state.height, 2);
      }
    }
  }

  onMapClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    let eventX = event.x;
    let eventY = event.y;
    if (!eventX || !eventY) {
      if (event.value.mode !== 'zooming' && !event.value.pinchPointDistance && event.value.startX === event.value.endX && event.value.startY === event.value.endY) {
        eventX = event.changedPoints[0].x;
        eventY = event.changedPoints[0].y;
      }
    }

    if (eventX && eventY) {
      let found = null;
      this.props.sensors.forEach((s) => {
        const x = s.x * this.state.width;
        const y = s.y * this.state.height;
        if (
          (eventX < x + SENSOR_SIZE + TOUCH_ERROR)
          && (eventX > x - SENSOR_SIZE - TOUCH_ERROR)
          && (eventY < y + SENSOR_SIZE + TOUCH_ERROR)
          && (eventY > y - SENSOR_SIZE - TOUCH_ERROR)) {
          found = s;
        }
      });

      if (found) {
        this.props.onLocationSelection(found, false);
      } else {
        this.props.onMapClick(eventX / this.state.width, eventY / this.state.height);
      }
    }
  }

  render() {
    const { svgData, width, height } = this.state;
    const { sensor, sensors, hover } = this.props;

    return svgData
      ? (
        <div style={{ width: '100%', height: '100%' }} ref={(e) => { this.targetElement = e; }}>
          <AutoSizer style={{ width: '100%', height: '100%' }}>
            {((viewport) => {
              return (viewport.width === 0 || viewport.height === 0) ? null : (
                <UncontrolledReactSVGPanZoom ref={(e) => { this.svg = e; }} width={viewport.width} height={viewport.height} tool={TOOL_AUTO} detectAutoPan={false} className={styles['inner-section-map']} background="white" onClick={this.onMapClick} onTouchEnd={this.onMapClick}>
                  <svg width={width} height={height}>
                    {/* eslint-disable */}
                    <g dangerouslySetInnerHTML={{__html: svgData}}/>
                    {/* eslint-enable */}
                    { sensors.map(s => <circle key={s.id} cx={width * s.x} cy={height * s.y} r={SENSOR_SIZE} fill="#FF290A" stroke="#C01D26" strokeWidth="1" />) }

                    { (hover && hover.x && hover.y) ? <line x1="0" x2={width} y1={height * hover.y} y2={height * hover.y} stroke="#cecece" strokeWidth="1" strokeDasharray="4 2" /> : null }
                    { (hover && hover.x && hover.y) ? <line x1={width * hover.x} x2={width * hover.x} y1={0} y2={height} stroke="#cecece" strokeWidth="1" strokeDasharray="4 2" /> : null }
                    { (hover && hover.x && hover.y) ? <circle cx={width * hover.x} cy={height * hover.y} r={SENSOR_SIZE} fill="#FF290A" stroke="#668EFC" strokeWidth="1" /> : null }

                    { (sensor && sensor.x && sensor.y) ? <line x1="0" x2={width} y1={height * sensor.y} y2={height * sensor.y} stroke="orange" strokeWidth="1" strokeDasharray="4 2" /> : null }
                    { (sensor && sensor.x && sensor.y) ? <line x1={width * sensor.x} x2={width * sensor.x} y1={0} y2={height} stroke="orange" strokeWidth="1" strokeDasharray="4 2" /> : null }
                    { (sensor && sensor.x && sensor.y) ? <circle cx={width * sensor.x} cy={height * sensor.y} r={SENSOR_SIZE * 1.2} fill="#FF290A" stroke="#668EFC" strokeWidth="2" /> : null }
                  </svg>
                </UncontrolledReactSVGPanZoom>
              );
            })}
          </AutoSizer>
        </div>
      ) : <Spinner absolute />;
  }
}

export default SectionMap;
