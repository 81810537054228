import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { i18n } from 'redux-pagan';
import { reducer as notificationsReducer } from 'react-notification-system-redux';

import appReducer from './app/reducer';
import sessionReducer from './session/reducer';

// flowlint-line-ignore
const rootReducer = history => combineReducers({
  router: connectRouter(history),
  notifications: notificationsReducer,
  i18n,
  app: appReducer,
  session: sessionReducer,
});

export default rootReducer;
