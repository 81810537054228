import React, { Component } from 'react';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';

import Spinner from '../common/spinner/Spinner';
import UserForm from './UserForm';
import queries from '../../state/queries';
import { updateBreadcrumbs } from '../../state/app/actions';

type Props = {
  userId?: ?string,
  customerId: string,
  customerName: string,
  roles: Array<string>,
  onCompleted: Function
}

type State = {}

class User extends Component<Props, State> {
  static defaultProps = {
    userId: null,
  }

  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
  }

  componentWillMount() {
    const { dispatch, lang } = this.context;
    const { customerName, customerId } = this.props;
    if (!this.props.userId) {
      // New User
      dispatch(updateBreadcrumbs([{ title: customerName, url: `/customers/${customerId}` }, { title: lang('user', 'users').s, url: `/customers/${customerId}/users` }, { title: lang('user', 'create').s }]));
    } else {
      // Edit User
      dispatch(updateBreadcrumbs([{ title: customerName, url: `/customers/${customerId}` }, { title: lang('user', 'users').s, url: `/customers/${customerId}/users` }, { title: lang('user', 'edit').s }]));
    }
  }

  render() {
    return (
      <Query query={queries.users.detail} variables={{ id: this.props.userId, customerId: this.props.customerId }} skip={!this.props.userId} fetchPolicy="network-only">
        {({ loading, error, data }) => {
          if (loading && this.props.userId) return <Spinner absolute />;
          if (error) return <div>User not found</div>;

          return <UserForm roles={this.props.roles} user={data ? data.user : null} customerId={this.props.customerId} onCompleted={this.props.onCompleted} />;
        }}
      </Query>
    );
  }
}

export default User;
