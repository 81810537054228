import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import Select from 'react-select';
import omitDeep from 'omit-deep-lodash';

import Spinner from '../common/spinner/Spinner';
import MutationError from '../common/errors/MutationError';
import queries, { userFields } from '../../state/queries';
import SelectCard from '../common/card/SelectCard';

type Props = {
  user?: any,
  customerId: string,
  roles: Array<string>,
  onCompleted: Function
}

type State = {
  values: any,
  errors: any,
  page: number,
  order: string,
  search: string
}

const createUser = gql`
  mutation createUser($email: String!, $firstName: String!, $lastName: String!, $password: String!, $role: Role!, $customer: CustomerRelationAttributes!, $clients: [CustomerRelationAttributes], $warehouses: [WarehouseRelationAttributes]) {
    createUser(attributes: { email: $email, firstName: $firstName, lastName: $lastName, password: $password, role: $role, customer: $customer, clients: $clients, warehouses: $warehouses }) {
      user {
        ${userFields}
      }
    }
  }
`;

const updateUser = gql`
  mutation updateUser($id: ID!, $email: String!, $firstName: String!, $lastName: String!, $password: String, $role: Role!, $customer: CustomerRelationAttributes!, $clients: [CustomerRelationAttributes], $warehouses: [WarehouseRelationAttributes]) {
    updateUser(id: $id, attributes: { email: $email, firstName: $firstName, lastName: $lastName, password: $password, role: $role, customer: $customer, clients: $clients, warehouses: $warehouses }) {
      user {
        ${userFields}
      }
    }
  }
`;

const userDeleteMutation = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) { id }
  }
`;

class UserForm extends Component<Props, State> {
  static defaultProps = {
    user: null
  }

  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    push: PropTypes.func
  }

  state = {
    values: { customer: {}, clients: [], warehouses: [] },
    errors: {},
    page: 1,
    order: 'name_ASC',
    search: ''
  }

  componentWillMount() {
    if (this.props.user) {
      this.setState({ values: {
        ...this.props.user,
        customer: { id: this.props.user.customer.id },
        warehouses: this.props.user.warehouses,
        clients: this.props.user.clients.map((customer: any) => ({ value: customer.id, label: customer.name }))
      }
      });
    } else {
      this.setState({ values: { role: this.props.roles[0], customer: { id: this.props.customerId }, clients: [], warehouses: [] } });
    }
  }

  onTextChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState(prevState => ({ ...prevState, values: { ...prevState.values, [field]: value } }));
  }

  onSelectChange = (field: string, option: { label: string, value: string }) => {
    this.setState(prevState => ({ ...prevState, values: { ...prevState.values, [field]: option.value } }));
  }

  onCustomerSelect = (option: { label: string, value: string }) => {
    this.setState(prevState => ({ ...prevState, values: { ...prevState.values, customer: { id: option.value } } }));
  }

  onAssocietedClientChange = (selectedClients: Array<any>) => {
    this.setState(prevState => ({
      ...prevState,
      values: {
        ...prevState.values,
        clients: [...selectedClients],
      }
    }));
  }

  onClick = (action: Function, e: SyntheticMouseEvent<*> | SyntheticTouchEvent<*>) => {
    e.preventDefault();
    const variables = omitDeep({ ...this.state.values }, '__typename');
    variables.code = variables.code || 0;
    variables.customerId = this.props.customerId;
    variables.clients = (variables.role !== 'marketing' && variables.role !== 'distributor' && variables.role !== 'maintainer') ? [{ id: this.props.customerId }] : variables.clients.map(c => ({ id: c.value }));

    const errors = this.validate(variables);
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    action({ variables });
  }

  onUserDelete = (action: Function) => {
    confirmAlert({
      title: this.context.lang('user', 'delete-confirm-title').toString(),
      message: this.context.lang('user', 'delete-confirm').toString(),
      buttons: [
        {
          label: this.context.lang('global', 'yes').toString(),
          onClick: () => action({ variables: { id: (this.props.user || {}).id } })
        },
        {
          label: this.context.lang('global', 'no').toString()
        }
      ]
    });
  }

  onDeleteComplete = () => {
    const { dispatch, push } = this.context;

    dispatch(push(`/customers/${this.props.customerId}/users`));
  }

  onCustomerSearch = (refetch: Function, search: string) => {
    refetch({ search });
    this.setState({ search });
  }

  onWarehouseSelect = (warehouseId: string) => {
    const warehouses = [...new Set(this.state.values.warehouses.map(w => w.id))];
    const idx = warehouses.indexOf(warehouseId);
    if (idx >= 0) {
      warehouses.splice(idx, 1);
    } else {
      warehouses.push(warehouseId);
    }
    this.setState(prevState => ({ ...prevState, values: { ...prevState.values, warehouses: warehouses.map((w) => { return { id: w }; }) } }));
  }

  validate = (values: any) => {
    const errors = {};
    ['email', 'firstName', 'lastName'].forEach((f) => {
      if (!values[f] || values[f].trim().length === 0) {
        errors[f] = 'empty';
      }
    });
    if (!values.id) {
      if (!values.password || values.password.trim().length === 0) {
        errors.password = 'empty';
      }
    }
    return errors;
  }

  render() {
    const { values, errors, search } = this.state;
    const { lang } = this.context;
    const roles = this.props.roles.map(r => ({ value: r, label: lang('roles', r).s }));

    return (
      <div className="table-dark grid-x centered-form">
        <div className="small-12 medium-8 medium-offset-2 large-6 large-offset-3 xlarge-4 xlarge-offset-4 cell">
          <form className="grid-x">
            <div className="form-title cell small-12">
              { lang('user', this.props.user ? 'edit' : 'create').s }
            </div>
            <label htmlFor="email" className="cell small-12 medium-6">
              <div>{ lang('user', 'email').s }</div>
              <input className={errors.email ? 'error' : ''} type="email" name="email" value={values.email || ''} onChange={this.onTextChange.bind(null, 'email')} />
            </label>
            <label htmlFor="password" className="cell small-12 medium-6">
              <div>{ lang('user', 'password').s }</div>
              <input className={errors.password ? 'error' : ''} type="password" name="password" value={values.password || ''} onChange={this.onTextChange.bind(null, 'password')} />
            </label>
            <label htmlFor="firstName" className="cell small-12 medium-6">
              <div>{ lang('user', 'firstName').s }</div>
              <input className={errors.firstName ? 'error' : ''} type="text" name="firstName" value={values.firstName || ''} onChange={this.onTextChange.bind(null, 'firstName')} />
            </label>
            <label htmlFor="lastName" className="cell small-12 medium-6">
              <div>{ lang('user', 'lastName').s }</div>
              <input className={errors.lastName ? 'error' : ''} type="text" name="lastName" value={values.lastName || ''} onChange={this.onTextChange.bind(null, 'lastName')} />
            </label>
            <label htmlFor="role" className="cell small-12 medium-6">
              <div>{ lang('user', 'role').s }</div>
              <Select classNamePrefix="Select" options={roles} value={values.role ? roles.filter(e => e.value === values.role) : roles[0]} onChange={this.onSelectChange.bind(null, 'role')} clearable={false} searchable={false} />
            </label>
            {
              values.role === 'marketing' || values.role === 'distributor' || values.role === 'maintainer' || values.role === 'client'
                ? (
                  <div className="small-12 cell">
                    {
                      values.role === 'client'
                        ? null
                        : (
                          <label htmlFor="associated-clients" className="cell small-12">
                            <div>{ lang('user', 'associated-clients').s }</div>
                            <Query query={queries.customers.list} variables={{ search }} fetchPolicy="network-only">
                              {(customerFetch) => {
                                let clientsOptions = [...values.clients];

                                if (customerFetch.error) return `Error!: ${customerFetch.error.toString()}`;
                                if (customerFetch.data && customerFetch.data.customers && customerFetch.data.customers.results) {
                                  const customers = customerFetch.data.customers.results;
                                  clientsOptions = [...clientsOptions, ...customers.map((customer: any) => ({ value: customer.id, label: customer.name }))];
                                  return <Select isMulti classNamePrefix="Select" options={clientsOptions} value={values.clients} onInputChange={this.onCustomerSearch.bind(null, customerFetch.refetch)} inputValue={search} onChange={this.onAssocietedClientChange} searchable clearable={false} />;
                                }
                                return null;
                              }}
                            </Query>
                          </label>
                        )
                      }

                    <label htmlFor="associated-clients" className="cell small-12">
                      <div>{ lang('user', 'associated-warehouses').s }</div>
                      <div className="grid-x">
                        <Query query={queries.warehouses.listFromCustomers} variables={{ customerIds: values.role === 'client' ? [this.props.customerId] : values.clients.map(c => c.value), order: 'number_ASC' }} fetchPolicy="network-only">
                          {(warehousesFetch) => {
                            if (warehousesFetch.error) return `Error!: ${warehousesFetch.error.toString()}`;
                            if (warehousesFetch.data && warehousesFetch.data.warehouses && warehousesFetch.data.warehouses.results) {
                              const selectedIds = values.warehouses.map(w => w.id);
                              return warehousesFetch.data.warehouses.results.map((w) => {
                                return <SelectCard className="small-12 medium-6 cell" title={w.name} subtitle={w.customer.name} isSelected={selectedIds.indexOf(w.id) >= 0} onSelect={this.onWarehouseSelect.bind(null, w.id)} key={w.id} />;
                              });
                            }
                            return null;
                          }}
                        </Query>
                      </div>
                    </label>
                  </div>
                ) : null
            }
            <Mutation mutation={this.props.user ? updateUser : createUser} onCompleted={this.props.onCompleted}>
              {(action, { loading, error }) => {
                return (
                  <div className="grid-x small-12 cell centered" style={{ marginTop: '2rem' }}>
                    {loading ? <Spinner /> : <input className="outline-button float-right" type="submit" onClick={this.onClick.bind(null, action)} value={values.id ? 'Update' : 'Create'} />}
                    <MutationError graphQLError={error} errors={this.state.errors} lang={lang} langKey="user" dark />
                  </div>
                );
              }}
            </Mutation>
            { this.props.user
              ? (
                <div className="grid-x small-12 cell centered" style={{ marginTop: '1rem' }}>
                  <Mutation mutation={userDeleteMutation} onCompleted={this.onDeleteComplete}>
                    {(deleteAction, result) => {
                      return result.loading ? <Spinner /> : <div className="action-button__subtle" onClick={this.onUserDelete.bind(null, deleteAction)}>{lang('user', 'delete').s}</div>;
                    }}
                  </Mutation>
                </div>
              ) : null
            }
          </form>
        </div>
      </div>
    );
  }
}

export default UserForm;
