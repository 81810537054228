import React, { Component } from 'react';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import 'react-confirm-alert/src/react-confirm-alert.css';

import queries from '../../state/queries';

import Spinner from '../common/spinner/Spinner';
import { updateBreadcrumbs } from '../../state/app/actions';
import { Permissions } from '../../state/authorization';
import CustomerForm from './CustomerForm';
import Error from '../common/errors/Error';
import styles from './Customer.scss';
import Warehouses from '../warehouses/Warehouses';

type Props = {
  customerId?: ?string,
  editing: bool
}

type State = {
  page: number,
  order: string,
  search: string
};

class Customer extends Component<Props, State> {
  static defaultProps = {
    customerId: null,
  }

  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    can: PropTypes.func,
    push: PropTypes.func
  }

  state = { page: 1, order: 'number_ASC', search: '', editing: this.props.editing }

  onQueryComplete = (data: any) => {
    const { dispatch, lang } = this.context;
    if (!data) {
      // New Customer
      dispatch(updateBreadcrumbs([{ title: lang('customers', 'create').s, url: '/customers/new' }]));
    } else if (data.customer && !this.state.editing) {
      // View customer
      dispatch(updateBreadcrumbs([{ title: data.customer.name, url: `/customers/${data.customer.id}` }]));
    } else if (data.customer) {
      // Edit Customer
      dispatch(updateBreadcrumbs([{ title: data.customer.name, url: `/customers/${data.customer.id}` }, { title: lang('customers', 'edit').s, url: `/customers/${data.customer.id}/edit` }]));
    }
  }

  onEditComplete = (url: string) => {
    const { dispatch, push } = this.context;
    dispatch(push(url));
  }

  render() {
    const { lang, can } = this.context;
    const { page, order, search } = this.state;
    const variables = { page, order, search, customerId: this.props.customerId };
    return (
      <div className="grid-x full-page">
        <Query query={queries.customers.detailWithWarehouses} variables={variables} skip={!this.props.customerId} fetchPolicy="network-only" onCompleted={this.onQueryComplete}>
          {({ loading, error, data }) => {
            if (loading) return <Spinner absolute />;
            if (error) return <Error error={error} lang={lang} />;

            let types = [];
            try {
              types = JSON.parse(data ? data.alertTypes : '{}');
            } catch (e) {
              // do nothing
            }

            if (data && !this.state.editing) {
              return (
                <div className="small-12 cell">
                  <div className="table table__minified">
                    <div className="table-row">
                      <div className={styles['left-section']}>
                        <div className="circled-icon circled-icon__customer show-for-large"><span className="icon-people white"><span className="path1" /><span className="path2" /><span className="path3" /></span></div>
                        <div className={styles['customer-name-containter']}>
                          <span className="table-title">
                            {data.customer.name}
                            {can(Permissions.customers.customersCreate) ? <Link to={`/customers/${data.customer.id}/edit`}><span className="icon-edit"><span className="path1" /><span className="path2" /></span></Link> : null}
                            <Link to={`/customers/${data.customer.id}/users`} className="hide-for-large"><span className="icon-people"><span className="path1" /><span className="path2" /><span className="path3" /></span></Link>
                          </span>
                          <span className="table-subtitle">{`${data.customer.address}, ${data.customer.city}`}</span>
                        </div>
                      </div>
                      <div style={{ flex: '1 1 auto', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                        <span style={{ flex: '1' }} className="table-detail table-detail__warehouse"><span className="table-header table_header__inline table_header__warehouse">{lang('customers', 'warehouses').s}</span><span className="icon-business hide-for-large"><span className="path1" /><span className="path2" /></span>{data.warehouses.results.length}</span>
                        <span style={{ flex: '1' }} className="table-detail table-detail__gate"><span className="table-header table_header__inline table_header__gate">{lang('customers', 'gate-count').s}</span><span className="icon-gate hide-for-large"><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /></span>{data.customer.maintenanceSensorsCount}</span>
                        <span style={{ flex: '1' }} className="table-detail table-detail__forklift"><span className="table-header table_header__inline table_header__forklift">{lang('customers', 'forklifts').s}</span><span className="icon-forklift hide-for-large"><span className="path1" /><span className="path2" /><span className="path3" /></span>{data.customer.damagedSensorsCount}</span>
                        <span style={{ flex: '1' }} className="table-detail table-detail__ok"><span className="table-header table_header__inline table_header__ok">{lang('customers', 'bollard-count').s}</span><span className="icon-bollard hide-for-large"><span className="path1" /><span className="path2" /></span>{data.customer.sensorsCount}</span>
                        <span style={{ flex: '1' }} className="table-detail table-detail__in-maintenance"><span className="table-header table_header__inline table_header__in-maintenance">{lang('customers', 'in-maintenance').s}</span><span className="icon-build hide-for-large"><span className="path1" /><span className="path2" /></span>{data.customer.maintenanceSensorsCount}</span>
                        <span style={{ flex: '1' }} className="table-detail table-detail__damaged"><span className="table-header table_header__inline table_header__damaged">{lang('customers', 'damaged').s}</span><span className="icon-error hide-for-large"><span className="path1" /><span className="path2" /></span>{data.customer.damagedSensorsCount}</span>
                      </div>
                      { can(Permissions.users.usersCreate) ? (
                        <span style={{ flex: '1' }} className="table-actions show-for-large">
                          <Link to={`/customers/${data.customer.id}/users`}><span className="icon-people"><span className="path1" /><span className="path2" /><span className="path3" /></span></Link>
                        </span>
                      ) : null }
                    </div>
                  </div>
                  <Warehouses alertTypes={types} warehouses={data.warehouses} customerId={data.customer.id} refetchQueries={[{ query: queries.customers.detailWithWarehouses, variables }]} />
                </div>
              );
            }

            return (
              <div className="small-12 cell">
                <CustomerForm
                  customer={data ? data.customer : null}
                  onCompleted={this.onEditComplete.bind(null, data ? `/customers/${data.customer.id}` : '/')}
                  onDeleted={this.onEditComplete.bind(null, '/')}
                />
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default Customer;
