import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { confirmAlert } from 'react-confirm-alert';

import styles from './DeviceDetail.scss';
import Spinner from '../common/spinner/Spinner';
import MutationError from '../common/errors/MutationError';
import { Permissions } from '../../state/authorization';

const startProvisioning = gql`
  mutation startForkliftProvisioning($id: ID!) {
    startForkliftProvisioning(id: $id) { forklift { id } }
  }
`;

// const resetProvisioning = gql`
//   mutation resetSensorProvisioning($id: ID!) {
//     resetSensorProvisioning(id: $id) { sensor { id } }
//   }
// `;

type Props = {
  forklift: any,
  onClose: Function,
  refetch: Function,
  onProvisioningComplete: Function
}

type State = {
  provisioningStatus: ?string,
}

class ForkliftDetail extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    replace: PropTypes.func,
    can: PropTypes.func,
    stompClient: PropTypes.object
  }

  state = {
    provisioningStatus: null,
  }

  streams = []

  resetErrorInterval = null

  componentDidUpdate(prevProps: any) {
    if (this.props.forklift.id !== prevProps.forklift.id) {
      this.setState({ provisioningStatus: null, showSettings: false }); // eslint-disable-line

      this.setupConnection();
    }
  }

  componentWillUnmount() {
    this.streams.forEach(s => this.context.stompClient.unsubscribe(s));
    this.streams = [];
  }

  setupConnection = () => {
    const { can } = this.context;

    this.context.stompClient.connect().then(() => {
      this.streams.forEach(s => this.context.stompClient.unsubscribe(s));
      this.streams = [];

      const callback = (res) => {
        if (window.VERBOSE_LIVE_MSG || can(Permissions.superadmin.can_all)) {
          console.log('provisioning', res);  // eslint-disable-line
        }

        clearInterval(this.resetErrorInterval);

        if (res.id === this.props.forklift.id) {
          this.setState({ provisioningStatus: res.action });
          if (res.status === 'nok') {
            this.setState({ provisioningStatus: 'Provisioning Failed' });
            this.resetErrorInterval = setInterval(() => {
              this.setState({ provisioningStatus: null });
            }, 3000);
          }
          if (res.action === 'provisioning' && res.status === 'ok') {
            const { forklift } = this.props;

            this.setState({ provisioningStatus: null });
            this.props.onProvisioningComplete(forklift.id, res.gatewayId, res.moteUid);
          }
        }
      };

      if (this.props.forklift.id) {
        this.context.stompClient.streamFrom([{ stream: 'feedback', topic: this.props.forklift.id, cache: false, callback }]).then((streams) => {
          this.streams = streams;
        }).catch(() => {
          this.setState({ provisioningStatus: 'Error' });
        });
      }
    });
  }

  onCompleted = () => {
    this.setState({ provisioningStatus: 'In progress...' });
  }

  onStartProvisioning = (action: Function) => {
    action({ variables: { id: this.props.forklift.id } });
  }

  onResetProvisioning = (action: Function) => {
    confirmAlert({
      title: this.context.lang('sensors', 'reset-confirm-title').toString(),
      message: this.context.lang('sensors', 'reset-confirm').toString(),
      buttons: [
        {
          label: this.context.lang('global', 'yes').toString(),
          onClick: () => {
            action({ variables: { id: this.props.forklift.id } });
          }
        },
        { label: this.context.lang('global', 'no').toString() }
      ]
    });
  }

  onResetCompleted = () => {
    this.props.refetch();
  }

  render() {
    const { lang } = this.context;
    const { provisioningStatus } = this.state;
    const { forklift, onClose } = this.props;

    const isDeviceEnabled = forklift && forklift.id;

    return isDeviceEnabled ? (
      <div className={`${styles.sidebar} ${styles.opened}`}>
        <div className="table-row">
          <div className="circled-icon circled-icon__button" onClick={onClose}>
            <span className="icon-close" />
          </div>
        </div>
        <div className="table-row table-row__vertical">
          <div className="grid-x" style={{ width: '100%' }}>
            <div className="small-12 cell">
              <div className="table-header table_header__inline" style={{ display: this.state.showJsonConfig ? 'block' : 'none' }}>
                { lang('devices', 'configuration').s }
              </div>
              <div className="table-title table-title__list" style={{ position: 'relative', minHeight: '4rem' }}>
                <Mutation mutation={startProvisioning} onCompleted={this.onCompleted}>
                  {(action, { loading, error }) => {
                    return (
                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        { error ? <MutationError graphQLError={error} lang={lang} langKey="sensors" /> : null }
                        {
                          forklift.moteUid
                            ? null
                            : <div>{(loading || provisioningStatus) ? <Spinner size={20} /> : <div className="outline-button" onClick={this.onStartProvisioning.bind(null, action)}>Start Provisioning</div>}</div>
                        }
                        { (provisioningStatus && forklift.device) ? <div className={styles['provisioning-status']}>{provisioningStatus}</div> : null }
                        { (provisioningStatus && forklift.device) ? <Spinner /> : null }
                      </div>
                    );
                  }}
                </Mutation>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : <div className={styles.sidebar} />;
  }
}

export default ForkliftDetail;
