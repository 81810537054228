import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './Forklifts.scss';

type Props = {
  forklifts: any,
  onEdit: Function,
  onHoverItem: Function,
  onForkliftSelect: Function
}

class Forklifts extends Component<Props> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    can: PropTypes.func
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        {
          this.props.forklifts.map((forklift) => {
            return (
              <div style={{ display: 'flex' }} key={forklift.id} onMouseEnter={this.props.onHoverItem.bind(null, forklift.id)} onMouseLeave={this.props.onHoverItem.bind(null, '')} onClick={this.props.onForkliftSelect.bind(null, forklift, true)}>
                <div style={{ flex: '1 1 100%' }} className="table-row table-row__clickable">
                  <div style={{ position: 'relative' }} className="circled-icon circled-icon__outline circled-icon__forklift">
                    <span className="icon-forklift"><span className="path1" /><span className="path2" /><span className="path3" /></span>
                  </div>
                  <div className={styles['forklift-name-containter']}>
                    <div className="table-title table-title table-title__list">
                      {forklift.name}
                      <span className="icon-edit" onClick={(e) => { this.props.onEdit(forklift); e.preventDefault(); e.stopPropagation(); }}><span className="path1" /><span className="path2" /></span>
                    </div>
                    <div className="table-subtitle">
                      {forklift.serial}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  }
}

export default Forklifts;
