import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import omitDeep from 'omit-deep-lodash';
import Spinner from '../common/spinner/Spinner';
import MutationError from '../common/errors/MutationError';
import queries, { deviceFields } from '../../state/queries';

import styles from './Device.scss';

type Props = {
  values: any,
  warehouseId: string,
  customerId: string,
  onTextChange: Function,
  onClearPosition: Function,
  onCompleted: Function,
  onDeleted: Function
}

type State = {
  errors: any
}

const createDevice = gql`
  mutation createDevice($serial: String!, $x: Float!, $y: Float!, $warehouse: WarehouseRelationAttributes!) {
    createDevice(attributes: { serial: $serial, x: $x, y: $y, warehouse: $warehouse }) {
      device {
        ${deviceFields}
      }
    }
  }
`;

const updateDevice = gql`
  mutation updateDevice($id: ID!, $serial: String!, $x: Float!, $y: Float!, $warehouse: WarehouseRelationAttributes!) {
    updateDevice(id: $id, attributes: { serial: $serial, x: $x, y: $y, warehouse: $warehouse }) {
      device {
        ${deviceFields}
      }
    }
  }
`;

const devicesDeleteMutation = gql`
  mutation deleteDevice($id: ID!) {
    deleteDevice(id: $id) { id }
  }
`;

class DeviceForm extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    push: PropTypes.func
  }

  state = {
    errors: {}
  }

  onClick = (action: Function, e: SyntheticMouseEvent<*> | SyntheticTouchEvent<*>) => {
    e.preventDefault();
    const variables = omitDeep({ ...this.props.values }, '__typename');

    const errors = this.validate(variables);
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    variables.warehouse = { id: variables.warehouse.id };

    action({ variables });
  }

  onDeviceDelete = (action: Function) => {
    confirmAlert({
      title: this.context.lang('devices', 'delete-confirm-title').toString(),
      message: this.context.lang('devices', 'delete-confirm-lite').toString(),
      buttons: [
        {
          label: this.context.lang('global', 'yes').toString(),
          onClick: () => {
            confirmAlert({
              title: this.context.lang('devices', 'delete-confirm-title').toString(),
              message: this.context.lang('devices', 'delete-confirm').toString(),
              buttons: [
                {
                  label: this.context.lang('global', 'yes').toString(),
                  onClick: () => action({ variables: { id: (this.props.values || {}).id } })
                },
                { label: this.context.lang('global', 'no').toString() }
              ]
            });
          }
        },
        { label: this.context.lang('global', 'no').toString() }
      ]
    });
  }

  validate = (values: any) => {
    const errors = {};
    ['serial'].forEach((f) => {
      if (!values[f] || values[f].trim().length === 0) {
        errors[f] = 'empty';
      }
    });

    if (values.x === undefined || values.y === undefined) {
      errors.position = 'empty';
    }
    return errors;
  }

  render() {
    const { errors } = this.state;
    const { values } = this.props;
    const { lang } = this.context;

    return (
      <form className="grid-x page cool-enter" style={{ position: 'relative' }}>
        <div className="form-title__inner cell small-12">
          { lang('devices', values.id ? 'edit' : 'create').s }
        </div>
        <label htmlFor="serial" className="cell small-12">
          <div>{ lang('devices', 'serial').s }</div>
          <input className={errors.serial ? 'error' : ''} type="text" name="serial" value={values.serial || ''} onChange={this.props.onTextChange.bind(null, 'serial')} />
        </label>
        <label htmlFor="position" className="cell small-12">
          <div>{ lang('devices', 'position').s }</div>
          <div className="fake-input">
            {
              values.x && values.y ? (
                <div className={`${styles.message} ${styles.message__inline}`}>
                  <span className="icon-location"><span className="path1" /><span className="path2" /><span className="path3" /></span>
                  { lang('devices', 'position-confirmed').toString() }
                </div>
              ) : (
                <div className={`${styles.message} ${styles.message__inline} ${styles.message__error}`}>
                  <span className="icon-add-location red"><span className="path1" /><span className="path2" /></span>
                  { lang('devices', 'add-position').toString() }
                </div>
              )
            }
            { values.x && values.y ? <span className="icon-close hide-for-large" onClick={this.props.onClearPosition} style={{ position: 'absolute', right: '.8rem', top: '.8rem', fontSize: '1.2rem' }} /> : null }
          </div>
        </label>
        <Mutation mutation={values.id ? updateDevice : createDevice} refetchQueries={[{ query: queries.warehouses.detail, variables: { warehouseId: this.props.warehouseId, customerId: this.props.customerId } }]} onCompleted={this.props.onCompleted}>
          {(action, { loading, error }) => {
            return (
              <div className="grid-x small-12 cell centered" style={{ marginTop: '2rem' }}>
                {loading ? <Spinner /> : <input className="outline-button" type="submit" onClick={this.onClick.bind(null, action)} value={values.id ? 'Update' : 'Create'} />}
                <MutationError graphQLError={error} errors={this.state.errors} lang={lang} langKey="devices" />
              </div>
            );
          }}
        </Mutation>
        { values.id
          ? (
            <div className="grid-x small-12 cell centered" style={{ marginTop: '.5rem' }}>
              <Mutation mutation={devicesDeleteMutation} refetchQueries={[{ query: queries.warehouses.detail, variables: { warehouseId: this.props.warehouseId, customerId: this.props.customerId } }]} onCompleted={this.props.onDeleted}>
                {(deleteAction, result) => {
                  return result.loading
                    ? <Spinner />
                    : (
                      <div>
                        <div className="small-12 cell" style={{ display: 'flex', justifyContent: 'center' }}>
                          <div className="action-button__subtle" onClick={this.onDeviceDelete.bind(null, deleteAction)}>{lang('devices', 'delete').s}</div>
                        </div>
                        <MutationError className="small-12 cell" graphQLError={result.error} errors={this.state.errors} lang={lang} langKey="devices" />
                      </div>
                    );
                }}
              </Mutation>
            </div>
          ) : null
        }
        <div className="circled-icon circled-icon__button circled-icon__floating" onClick={this.props.onCompleted}>
          <span className="icon-close" />
        </div>
      </form>
    );
  }
}

export default DeviceForm;
