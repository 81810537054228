import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import initialState from '../state/initial-state';
import Breadcrumbs from './common/breadcrumbs/Breadcrumbs';
import { Permissions } from '../state/authorization';

import styles from './Header.scss';

type Props = {
  session: typeof initialState.session,
  breadcrumbs: typeof initialState.app.breadcrumbs
}

type State = {
  isMenuOpen: boolean
}

class Header extends Component<Props, State> {
  static contextTypes = {
    dispatch: PropTypes.func.isRequired,
    lang: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    can: PropTypes.func.isRequired,
  }

  state = {
    isMenuOpen: false
  }

  onLogout = (e) => {
    e.preventDefault();
    this.toogleMenu();
    this.context.actions.session.logout();
  }

  toogleMenu = () => {
    this.setState(prevState => ({ isMenuOpen: !prevState.isMenuOpen }));
  }

  render() {
    const { session } = this.props;
    const { lang, can } = this.context;
    const { isMenuOpen } = this.state;

    const breadcrumbs = [...this.props.breadcrumbs];
    if (breadcrumbs.length < 2) {
      if (can(Permissions.customers.customersIndex)) {
        breadcrumbs.unshift({ title: this.context.lang('customers', 'customers-list').toString(), url: '/customers' });
      }
    }

    return (
      <div className={styles.container}>
        <Link to="/"><img className={styles.logo} src={require('../images/logo-login.png')} alt="logo" /></Link>
        <div className={styles.breadcrumbs}><Breadcrumbs relative steps={breadcrumbs} /></div>
        <div className={styles['right-section']}>
          <div className={styles['noification-icon']} style={{ display: 'none' }}>
            <span className="icon-notifications white"><span className="path1" /><span className="path2" /></span>
          </div>
          <div className={styles['menu-container']} onClick={this.toogleMenu}>
            <div className={styles['user-container']}>
              <span className={`icon-person ${styles.icon} white`}><span className="path1" /><span className="path2" /><span className="path3" /></span>
              {`${session.first_name || ''} ${session.last_name || ''}`}
            </div>
            <span className={`icon-arrow-left ${styles['menu-icon']} white`} style={{ transform: `rotate(${isMenuOpen ? '90deg' : '-90deg'})` }} />
          </div>
        </div>
        {
          isMenuOpen ? (
            <div className={styles['overflow-menu']} onClick={this.toogleMenu}>
              <div className={styles.menu}>
                <div className={styles.list}>
                  { can(Permissions.customers.customersIndex) ? <Link to="/" onClick={this.toogleMenu}>{lang('customers', 'customers-list').s}</Link> : null }
                  { can(Permissions.home.stats) ? <Link to="/stats">Stats</Link> : null }
                  { can(Permissions.home.download) ? <Link to="/downloads">Downloads</Link> : null }
                  <Link to="/" onClick={this.onLogout}>{lang('session', 'logout').s}</Link>
                </div>
                <div className={styles.arrow} />
              </div>
            </div>
          ) : null
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
    breadcrumbs: state.app.breadcrumbs
  };
}

// flowlint-line-ignore
export default withRouter(connect(mapStateToProps, (dispatch: Dispatch) => ({ dispatch }))(Header));
