import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

type Props = {}
type State = {}

class NotFoundPage extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    push: PropTypes.func
  }

  render() {
    const { lang } = this.context;

    return (
      <div className="error-container error-container__dark">
        <h3>
          {lang('global', 'page_not_found').s}
        </h3>
        <Link to="/">{lang('global', 'back-home').s}</Link>
      </div>
    );
  }
}

export default NotFoundPage;
